import {NgModule} from "@angular/core";
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./security/auth.guard";
import {HomeComponent} from "./home/home.component";
import {CustomerCareLoginComponent} from './security/containers/login/customer-care/customer-care-login.component';
import {CustomerCreateComponent} from "./customer/containers/customer-create/customer-create.component";
import {CustomerDetailComponent} from "./customer/containers/customer-detail/customer-detail.component";
import {UserCustomerListComponent} from "./customer/containers/user-customer-list/user-customer-list.component";
import {DocumentAnonymousListComponent} from "./document/containers/document-anonymous-list/document-anonymous-list.component";
import {CustomerCareHomeComponent} from './customer-care/containers/customer-care-home/customer-care-home.component';
import {DocumentsComponent} from './document/containers/documents/documents.component';
import {Role} from './security/role';
import {MyAccountGuard} from "./security/my-account.guard";
import {ForgotPasswordComponent} from "./security/containers/forgot-password/forgot-password.component";
import {ResetPasswordByTokenComponent} from "./security/containers/reset-password/reset-password-by-token/reset-password-by-token.component";
import {CarrierProfileDetailComponent} from "./admin/containers/carrier-profile-detail/carrier-profile-detail.component";
import {CustomerLinkComponent} from "./security/containers/login/customer/customer-link.component";
import {EvvHelpContactComponent} from "./common/evv-header/evv-help-contact.component";
import {CcaUploadFileComponent} from "./customer/components/cca-upload-file/cca-upload-file.component";
import {WelcomePageComponent} from "./entry-page/components/welcome-page/welcome-page.component";
import {StepSupportUrgentComponent} from "./entry-page/components/step-support-urgent/step-support-urgent.component";
import {StepSupportGlsComponent} from "./entry-page/components/step-support-gls/step-support-gls.component";
import {StepSupportComponent} from "./entry-page/components/step-support/step-support.component";
import {AnonymousLoginFormComponent} from "./entry-page/components/anonymous-login-form/anonymous-login-form.component";
import {CustomerLoginProcessComponent} from "./entry-page/containers/customer-login-process.component";


const routes: Routes = [
    {path: '', redirectTo: 'ui/home', pathMatch: 'full'},
    {path: 'login-customer', redirectTo: 'ui/login/customer', pathMatch: 'full'},
    {path: 'login-mailpiece', redirectTo: 'ui/login/mailpiece', pathMatch: 'full'},
    {path: 'login-gls', redirectTo: 'ui/login/customer', pathMatch: 'full'},
    {path: 'document-search', redirectTo: 'ui/document-search', pathMatch: 'full'},
    {
        path: 'ui',
        children: [
            {path: 'document-search', component: DocumentAnonymousListComponent}, //anonymous search

            {path: 'home', component: HomeComponent}, //redirect to my or cca depending type of user
            {path: 'help-contact', component: EvvHelpContactComponent},
            {path: 'login',
                children: [
                    {path: '', component: WelcomePageComponent},
                    {path: 'step-support', component: StepSupportComponent},
                    {path: 'step-support-gls', component: StepSupportGlsComponent},
                    {path: 'step-support-urgent', component: StepSupportUrgentComponent},
                    {path: 'customer', component: CustomerLoginProcessComponent},
                    {path: 'mailpiece', component: AnonymousLoginFormComponent},
                    {path: 'step-without-support',
                        children: [
                            {path: '', component: WelcomePageComponent},
                            {path: 'customer', component: WelcomePageComponent},
                            {path: 'mailpiece', component: WelcomePageComponent},
                            {path: 'klp', component: WelcomePageComponent}
                        ]
                    }
                ]
            },
            {path: 'my-evd/accounts/link-account',
                canActivate: [AuthGuard],
                data: {roles: [Role.IDP_KLP]},
                component: CustomerLinkComponent},
            {
                path: 'my-evd',
                canActivate: [AuthGuard], data: {roles: [Role.EVV_CUSTOMER]}, children: [
                    {path: '', component: HomeComponent},
                    {path: 'documents', component: DocumentsComponent}, // TODO: here show carrier selector (in the component, search criteria)
                    {
                        path: 'accounts',
                        children: [
                            {path: '', component: UserCustomerListComponent,
                                canActivate: [MyAccountGuard], // Redirect to ./:customerId if not KLP user
                                pathMatch: 'full'},
                            {path: ':customerId/documents', component: DocumentsComponent},
                            {path: ':customerId', component: CustomerDetailComponent}
                        ]
                    }
                ]
            },
            {path: 'forgot-password', component: ForgotPasswordComponent},
            {path: 'reset-password', component: ResetPasswordByTokenComponent},
            {path: 'cca/login', component: CustomerCareLoginComponent}, //TODO: Remove for non dev environment
            {path: 'cca/not-authorized', component: CustomerCareLoginComponent}, //TODO: show message explaining you need a CCA Admin role to enter in this page
            {
                path: "cca",
                canActivate: [AuthGuard],
                data: {roles: [Role.EVV_ADMIN, Role.EVV_CCA_ADMIN, Role.EVV_CCA], customerCare: true},
                children: [
                    {
                        path: '', component: CustomerCareHomeComponent
                    },
                    {
                        path: 'admin',
                        canActivate: [AuthGuard], data: {roles: [Role.EVV_ADMIN, Role.EVV_CCA_ADMIN, Role.EVV_CCA], customerCare: true},
                        children: [
                            {path: 'event-log', component: CustomerCareHomeComponent},
                            {path: 'carrier-profile', component: CustomerCareHomeComponent},
                            {path: 'carrier-profile/:carrierId', component: CarrierProfileDetailComponent},
                            {path: 'assist-metadata', component: CustomerCareHomeComponent},
                            {path: 'tnt-cc-user', component: CustomerCareHomeComponent}
                        ]
                    },
                    {
                        path: 'customers',
                        children: [
                            {path: 'customers-list', component: CustomerCareHomeComponent},
                            {path: 'create', component: CustomerCreateComponent},
                            {path: ':customerId/documents', component: DocumentsComponent, data: {showBack: true}},
                            {path: ':customerId', component: CustomerDetailComponent},
                            {path: ':customerId/upload', component: CcaUploadFileComponent,
                                canActivate: [AuthGuard], data: {roles: [Role.EVV_ADMIN, Role.EVV_CCA_ADMIN]}}
                        ]
                    },
                    {
                        path: 'documents',
                        children: [
                            {path: 'documents-list', component: CustomerCareHomeComponent}
                        ]
                    }



                ]
            },
            {path: '**', redirectTo: '/ui/home'}
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {useHash: false})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}


