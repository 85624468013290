/*
 * Copyright 2023 by Swiss Post, Information Technology
 */
import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {CustomerModule} from "../customer/customer.module";
import {CommonDirectivesModule} from "../common/directives/common-directives.module";
import {UserService} from "../security/user.service";
import {AuthGuard} from "../security/auth.guard";
import {MyAccountGuard} from "../security/my-account.guard";
import {WelcomePageComponent} from "./components/welcome-page/welcome-page.component";
import {StepSupportComponent} from "./components/step-support/step-support.component";
import {StepSupportGlsComponent} from "./components/step-support-gls/step-support-gls.component";
import {StepSupportUrgentComponent} from "./components/step-support-urgent/step-support-urgent.component";
import {AnonymousLoginFormComponent} from "./components/anonymous-login-form/anonymous-login-form.component";
import {GlsLoginFormComponent} from "./components/gls-login-form/gls-login-form.component";
import {PvzLoginFormComponent} from "./components/pvz-login-form/pvz-login-form.component";
import {UrgentLoginFormComponent} from "./components/urgent-login-form/urgent-login-form.component";
import {LoginWithKlpComponent} from "./components/login-with-klp/login-with-klp.component";
import {LoginWithoutSupportComponent} from "./components/login-without-support/login-without-support.component";
import {CustomerLoginProcessComponent} from "./containers/customer-login-process.component";
import {QrCodeScannerComponent} from "./components/qr-code-scanner/qr-code-scanner.component";
import {NgxScannerQrcodeModule} from "ngx-scanner-qrcode";
import {NavigationStartBackComponent} from "./components/navigation-start-back/navigation-start-back.component";
import {AnonymousDocumentImageCarouselComponent} from "./components/anonymous-document-image-carousel/anonymous-document-image-carousel.component";
import {SecurityModule} from "../security/security.module";
import {ConfService} from "./conf.service";

@NgModule({
    declarations: [
        WelcomePageComponent,
        StepSupportComponent,
        StepSupportGlsComponent,
        StepSupportUrgentComponent,
        AnonymousLoginFormComponent,
        GlsLoginFormComponent,
        PvzLoginFormComponent,
        UrgentLoginFormComponent,
        LoginWithKlpComponent,
        LoginWithoutSupportComponent,
        CustomerLoginProcessComponent,
        QrCodeScannerComponent,
        NavigationStartBackComponent,
        AnonymousDocumentImageCarouselComponent
    ],

    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        NgbModule,
        CustomerModule,
        CommonDirectivesModule,
        NgxScannerQrcodeModule,
        SecurityModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        WelcomePageComponent,
        StepSupportComponent,
        StepSupportGlsComponent,
        StepSupportUrgentComponent,
        AnonymousLoginFormComponent,
        GlsLoginFormComponent,
        PvzLoginFormComponent,
        UrgentLoginFormComponent,
        LoginWithKlpComponent,
        LoginWithoutSupportComponent,
        CustomerLoginProcessComponent,
        NavigationStartBackComponent
    ],
    providers: [
        AuthGuard,
        UserService,
        MyAccountGuard,
        ConfService
    ]
})
export class EntryPageModule {
}



