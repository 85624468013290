// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-breadcrumb {
    padding: 0.5rem 0.75rem;
    font-size: 0.9375rem;
    border-color: rgba(102, 102, 102, 0.5);
}

.btn-breadcrumb-text {
    padding: 0 0.625rem;
    line-height: 1.2;
    color: #666666;
    font-weight: 400;
}

.btn-breadcrumb > span.pi {
    width: 21px;
    position: relative;
    top: -2px;
    right: -9px;
}

.pi-3073 {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='48' height='48' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cpath id='a' d='M46.501 39.265a2.983 2.983 0 0 0-1.475-2.201l-7.173-4.146a3.006 3.006 0 0 0-3.625.484l-2.298 2.322a61.455 61.455 0 0 1-20.027-20.052l2.377-2.377a3.004 3.004 0 0 0 .478-3.621L10.616 2.5a2.976 2.976 0 0 0-2.205-1.474 2.985 2.985 0 0 0-2.514.854L2.93 4.846v-.013L1 6.763l.002.099c1.488 21.007 18.437 38.11 39.569 39.794.067.005.134.002.2.007l1.904-1.904h-.004l2.976-2.976a2.99 2.99 0 0 0 .856-2.518h-.002zm-2.27 1.104l-4.21 4.21C20.538 42.745 4.857 26.986 3.088 7.515L7.31 3.294a.994.994 0 0 1 .837-.284c.311.04.58.218.736.49l4.14 7.174a1 1 0 0 1-.158 1.207l-3.466 3.465c.134.227 1.057 1.773 1.057 1.773a63.428 63.428 0 0 0 20.032 20.058s1.576.939 1.774 1.052l3.383-3.416a1.001 1.001 0 0 1 1.207-.16l7.173 4.144c.27.156.448.424.488.734a.983.983 0 0 1-.283.839l.001-.001z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23FFF' d='M-992-320H448v1682H-992z'/%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cg fill='%23333' mask='url(%23b)'%3E%3Cpath d='M0 0h48v48H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}
`, "",{"version":3,"sources":["webpack://./src/app/app.component.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,oBAAoB;IACpB,sCAAsC;AAC1C;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,4tCAA4tC;AAChuC","sourcesContent":[".btn-breadcrumb {\n    padding: 0.5rem 0.75rem;\n    font-size: 0.9375rem;\n    border-color: rgba(102, 102, 102, 0.5);\n}\n\n.btn-breadcrumb-text {\n    padding: 0 0.625rem;\n    line-height: 1.2;\n    color: #666666;\n    font-weight: 400;\n}\n\n.btn-breadcrumb > span.pi {\n    width: 21px;\n    position: relative;\n    top: -2px;\n    right: -9px;\n}\n\n.pi-3073 {\n    background-image: url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='48' height='48' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cpath id='a' d='M46.501 39.265a2.983 2.983 0 0 0-1.475-2.201l-7.173-4.146a3.006 3.006 0 0 0-3.625.484l-2.298 2.322a61.455 61.455 0 0 1-20.027-20.052l2.377-2.377a3.004 3.004 0 0 0 .478-3.621L10.616 2.5a2.976 2.976 0 0 0-2.205-1.474 2.985 2.985 0 0 0-2.514.854L2.93 4.846v-.013L1 6.763l.002.099c1.488 21.007 18.437 38.11 39.569 39.794.067.005.134.002.2.007l1.904-1.904h-.004l2.976-2.976a2.99 2.99 0 0 0 .856-2.518h-.002zm-2.27 1.104l-4.21 4.21C20.538 42.745 4.857 26.986 3.088 7.515L7.31 3.294a.994.994 0 0 1 .837-.284c.311.04.58.218.736.49l4.14 7.174a1 1 0 0 1-.158 1.207l-3.466 3.465c.134.227 1.057 1.773 1.057 1.773a63.428 63.428 0 0 0 20.032 20.058s1.576.939 1.774 1.052l3.383-3.416a1.001 1.001 0 0 1 1.207-.16l7.173 4.144c.27.156.448.424.488.734a.983.983 0 0 1-.283.839l.001-.001z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23FFF' d='M-992-320H448v1682H-992z'/%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cg fill='%23333' mask='url(%23b)'%3E%3Cpath d='M0 0h48v48H0z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A\");\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
