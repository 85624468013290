import {
    Component,
    ElementRef,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {
    DocumentSearchCriteria,
    DocumentSearchServiceType,
    PRICE_LIMIT_ARRAY_DROPDOWN,
    ReceiptState
} from '../../models/document-search-criteria.interface';
import {Router} from "@angular/router";

import {TranslateService} from "@ngx-translate/core";

import {getCountryKeyValueArray} from '../../models/address.interface';
import {TealiumUtagService} from "../../../common/tealium-utag.service";
import {Carrier} from '../../../customer/models/carrier';
import {DateValidityValidator} from "../../../common/date-validity.validator";
import {CustomerSummary} from "../../../security/user.interface";
import {Observable} from "rxjs";
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import {UserService} from "../../../security/user.service";
import {DocumentService} from "../../services/document.service";
import {BillDocument} from "../../models/bill-document.interface";
import {HttpErrorResponse} from "@angular/common/http";

/* eslint-disable */
@Component({
    selector: 'evv-document-search',
    styleUrls: ['document-search.component.css'],
    templateUrl: 'document-search.component.html'
})
export class DocumentSearchComponent implements OnInit, OnChanges {
    @Input() showAsCustomer = false;
    @Input() showAsMasterCustomer = false;
    @Input() isCustomerCare = false;
    @Input() initialCriteria: DocumentSearchCriteria;
    @Input() savedCriteria: DocumentSearchCriteria;
    @Input() isEVDUser: boolean;
    @Output()
    search: EventEmitter<DocumentSearchCriteria> = new EventEmitter<DocumentSearchCriteria>();

    @Input() carriers: Carrier[];
    @Input() customers: CustomerSummary[];

    @ViewChild('resetButton')
    resetButton: ElementRef;

    @ViewChild('submitButton')
    submitButton: ElementRef;

    @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

    isExportMode = false;
    serviceTypes: DocumentSearchServiceType[];
    documentStatus: ReceiptState[];
    isAdvancedSearchCollapsed = false;
    documentSearchForm: FormGroup;
    countryKeyValueArr: any[];
    readonly priceLimits = PRICE_LIMIT_ARRAY_DROPDOWN;
    invoiceKeyShown = false;
    billDocuments: BillDocument[];
    selectedCustomer: CustomerSummary;

    constructor(private formBuilder: FormBuilder, private tealiumService: TealiumUtagService, private translateService: TranslateService,
                private router: Router, private userService: UserService, private documentService: DocumentService) {
        this.countryKeyValueArr = getCountryKeyValueArray(this.translateService);
    }

    ngOnInit() {
        this.documentStatus = Object.keys(ReceiptState).map(k => ReceiptState[k as any] as ReceiptState);
        this.documentSearchForm = this.formBuilder.group({
            serviceType: new FormControl(null, [Validators.required]),
            customerId: new FormControl({
                value: null,
                disabled: !this.customers || this.customers.length === 1
            }, [Validators.required]),
            carrierId: new FormControl(null),
            documentTsFrom: new FormControl(null, [Validators.required]),
            documentTsTo: new FormControl(null, [Validators.required]),
            searchNumber: new FormControl(null),
            customerState: new FormControl(null),
            consignorName: new FormControl(null),
            consignorPostCode: new FormControl(null),
            consignorCity: new FormControl(null),
            consigneeName: new FormControl(null),
            consigneePostCode: new FormControl(null),
            consigneeCity: new FormControl(null),
            consigneeCountry: new FormControl(null),
            withoutCustomer: new FormControl(null),
            vatAmount: new FormControl(null),
            dutyAmount: new FormControl(null),
            invoiceKey: new FormControl(null)
        }, {validator: DateValidityValidator('documentTsFrom', 'documentTsTo')});

        let criteria = this.savedCriteria || this.initialCriteria;
        this.documentSearchForm.reset(criteriaToForm(criteria));

        this.onCustomerChanged(criteria.customerId);
        this.onCarrierChanged(criteria.carrierId);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.customers && changes.customers.currentValue) {
            if (!this.isCustomerCare) {
                const customersOfUser = this.userService.getCurrentUser().customers;
                if (customersOfUser!= null &&  customersOfUser.length > 0) {
                    this.customers = customersOfUser;
                }
            }
        }
    }

    onCarrierChanged(carrierId) {
        if(!carrierId) {
            return;
        }
        const carr = this.carriers.find(c => c.id === carrierId);
        this.isExportMode = !!carr && carr.serviceType === "EXPORT";
        if (this.isExportMode) {
            this.serviceTypes = [DocumentSearchServiceType.ALL, DocumentSearchServiceType.EXPORT];
        } else {
            this.serviceTypes = Object.keys(DocumentSearchServiceType).map(k => DocumentSearchServiceType[k as any] as DocumentSearchServiceType);
        }

        if(!this.serviceTypes.includes(this.getServiceType())) {
            this.documentSearchForm.patchValue({'serviceType': 'ALL'});
        }
        this.invoiceKeyShown = carr.name.toUpperCase() === 'GLS';
    }

    onCustomerChanged(customerId) {
        const customer = customerId && this.customers.find(c => c.id === customerId);
        if(customer) {
            this.onCarrierChanged(customer.carrId);
            this.loadBillDocumentsBySelectedCustomer(customer);
            this.executeSearch();
        } else if (this.customers != null) {
            this.loadBillDocumentsBySelectedCustomer(this.customers[0]);
        }
    }

    onServiceTypeChanged() {
        this.documentSearchForm.get('consigneeCountry').reset();
        if (!this.isExportMode && !this.isInExportMode()) {
            return;
        }
        this.documentSearchForm.get('consignorName').reset();
        this.documentSearchForm.get('consignorPostCode').reset();
        this.documentSearchForm.get('consignorCity').reset();
    }

    isInExportMode(): boolean {
        return this.getServiceType() === DocumentSearchServiceType.EXPORT;
    }

    isInImportMode(): boolean {
        return this.getServiceType() === DocumentSearchServiceType.IMPORT;
    }

    get documentTsFromLabel() {
        if(this.isInImportMode()) {
            return 'document.search.date-from-import';
        } else if (!this.isInSearchMode()) {
            return 'document.search.date-from-export';
        } else {
            return 'document.search.date-from';
        }
    }
    get documentTsToLabel() {
        if(this.isInImportMode()) {
            return 'document.search.date-to-import';
        } else if (!this.isInSearchMode()) {
            return 'document.search.date-to-export';
        } else {
            return 'document.search.date-to';
        }
    }

    isInEveuMode(): boolean {
        return this.getServiceType() === DocumentSearchServiceType.EVEU;
    }

    isInSearchMode(): boolean {
        return this.getServiceType() === DocumentSearchServiceType.ALL;
    }

    handleSubmit() {
        this.submitButton.nativeElement.blur();
        if (!this.documentSearchForm.valid) {
            return;
        }
        let criteria = formToCriteria(this.documentSearchForm.getRawValue()) as DocumentSearchCriteria;
        if (this.invoiceKey != null && this.invoiceKey.value!= null) {
            this.getBillByInvoiceKey(this.invoiceKey.value, criteria);
        }
        this.search.emit(criteria);

    }

    navigateToUserCustomerList() {
        this.tealiumService.sendEventWithLocation('click', 'go-to', 'eVD Konten verwalten und verknuepfen',
            'main', 'unknown',this.translateService.instant("app.title") + '-' + 'verknuepfte eVD Konto Verwalten');
        this.router.navigate(['/ui', 'my-evd', 'accounts']);
    }

    private executeSearch() {
        if (!this.documentSearchForm.valid) {
            return;
        }
        this.search.emit(formToCriteria(this.documentSearchForm.getRawValue()));
    }

    handleReset() {
        this.resetButton.nativeElement.blur();
        if (this.initialCriteria.invoiceKey) {
            this.initialCriteria = {
                ...this.initialCriteria,
                carrierId: null,
                invoiceKey: null
            };
        }
        this.resetForm(this.initialCriteria);
        this.onCustomerChanged(this.initialCriteria.customerId);
        this.onCarrierChanged(this.initialCriteria.carrierId);
        this.executeSearch();
    }
    private resetForm(criteria: DocumentSearchCriteria) {
        this.formGroupDirective.resetForm(criteriaToForm(criteria));
    }

    private getServiceType(): DocumentSearchServiceType {
        return this.documentSearchForm.get('serviceType').value;
    }

    formatter = (x: {value: string}) => x.value;

    searchCountry = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(term => term === '' ? []
                : this.countryKeyValueArr.filter(co => co.value.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
        )

    get consigneeCountry() {
        return this.documentSearchForm.get('consigneeCountry') as FormControl;
    }

    get invoiceKey() {
        return this.documentSearchForm.get('invoiceKey') as FormControl;
    }

    get searchNumber() {
        return this.documentSearchForm.get('searchNumber') as FormControl;
    }


    resetForm4InvoiceSearch() {
        this.initialCriteria = {
            ...this.initialCriteria,
            carrierId: 2,
            customerId: this.selectedCustomer!=null ? this.selectedCustomer.id: this.customers != null ? this.customers[0].id : null,
            invoiceKey: this.invoiceKey.value,
        };
        this.resetForm(this.initialCriteria);

    }

    deleteInvoiceKeyIfExisted() {
        if (this.invoiceKey) {
            this.invoiceKey.setValue(null);
        }
    }

    getMonthlyBillList(customerId: number) {
        return this.documentService.getMonthlyBillDocuments(customerId);
    }

    loadBillDocumentsBySelectedCustomer(selCustomer: CustomerSummary) {
        this.getMonthlyBillList(selCustomer.id).subscribe(l=> {
            this.billDocuments = l;
            this.selectedCustomer = selCustomer;
        });
    }

    getBillByInvoiceKey(invoiceKey: string, searchCriteria: DocumentSearchCriteria) {
        const docObservable = this.documentService.getBillByInvoiceKey(invoiceKey.trim(), searchCriteria.customerId);
        docObservable.subscribe(data => {
            this.billDocuments = data;
        }, (response: HttpErrorResponse) => {
            this.billDocuments = [];
        });
    }

}

function formToCriteria(formValue) {
    return <DocumentSearchCriteria>{
        ...formValue,
        consigneeCountry: formValue.consigneeCountry &&  formValue.consigneeCountry.key ? formValue.consigneeCountry.key : null,
        documentTsFrom: struckToDate(formValue.documentTsFrom),
        documentTsTo: struckToDate(formValue.documentTsTo),
        customerState: formValue.customerState === 'ALL' ? null : formValue.customerState,
        vatAmount: formValue.vatAmount === 'ALL' ? null : formValue.vatAmount,
        dutyAmount: formValue.dutyAmount === 'ALL' ? null : formValue.dutyAmount
    };
}
function criteriaToForm(criteria: DocumentSearchCriteria) {
    return {
        ...criteria,
        documentTsFrom: dateToStruck(criteria.documentTsFrom),
        documentTsTo: dateToStruck(criteria.documentTsTo),
        customerState: criteria.customerState || 'ALL',
        vatAmount: criteria.vatAmount || 'ALL',
        dutyAmount: criteria.dutyAmount || 'ALL'
    };
}
function dateToStruck(date) {
    if (date) {
        return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
        };
    } else {
        return null;
    }
}

function struckToDate(dateStruct: {year: number, month: number, day:number}) {
    return dateStruct ? new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day) : null;
}
