import {Component, Input} from "@angular/core";
import {Router} from "@angular/router";
import {step} from "../../entry-page.model";

/* eslint-disable */
@Component({
    selector: 'evd-navigation-start-back',
    templateUrl: 'navigation-start-back.component.html'
})
export class NavigationStartBackComponent {
    @Input()
    stepName: string;

    constructor(private router: Router) {
    }

    back() {
      if (this.stepName) {
          if (step.STEP_SUPPORT === this.stepName) {
              this.router.navigate(['ui', 'login']);
          } else if (step.ANONYMOUS_LOGIN === this.stepName || step.STEP_SUPPORT_GLS === this.stepName) {
              this.router.navigate(['ui', 'login', 'step-support']);
          } else if (step.GLS_LOGIN === this.stepName || step.STEP_SUPPORT_URGENT == this.stepName) {
              this.router.navigate(['ui', 'login', 'step-support-gls']);
          } else if (step.URGENT_LOGIN === this.stepName || step.PVZ_LOGIN == this.stepName) {
              this.router.navigate(['ui', 'login', 'step-support-urgent']);
          }
      }
    }

    toWelcomePage() {
        this.router.navigate(['ui', 'login']);
    }
}

