import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {TableModule} from 'primeng/table';
import {NotificationsModule} from '../notifications/notifications.module';
// containers
import {DocumentListComponent} from './components/document-list/document-list.component';
// service
import {DocumentService} from './services/document.service';
import {DocumentSearchComponent} from './components/document-search/document-search.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NotificationDialogComponent} from '../common/notification-dialog/notification-dialog.component';
import {TableUtilModule} from '../common/table/table-util.module';
import {LoadingSpinnerDialogModule} from '../common/loading-spinner-dialog/loading-spinner-dialog.module';
import {DocumentDownloadDropdownComponent} from './components/document-download-document-dropdown/document-download-dropdown.component';
import {DocumentAnonymousListComponent} from './containers/document-anonymous-list/document-anonymous-list.component';
import {DocumentAnonymousSearchComponent} from './components/document-anonymous-search/document-anonymous-search.component';
import {DocumentsComponent} from './containers/documents/documents.component';
import {ReceiptActionsComponent} from "./components/receipt-actions/receipt-actions.component";
import {ReceiptDetailsModalComponent} from "./components/receipt-details-modal/receipt-details-modal.component";
import {EvdAccountWidgetComponent} from "./components/evd-account-widget/evd-account-widget.component";
import {AddressFormatterComponent} from "./components/address-formatter/address-formatter.component";
import {CommonDirectivesModule} from "../common/directives/common-directives.module";
import {CommonPipesModule} from "../common/pipes/common-pipes.module";
import {BillDocumentListComponent} from "./components/bill-document-list/bill-document-list.component";


@NgModule({
    declarations: [
        DocumentsComponent,
        DocumentListComponent,
        DocumentAnonymousListComponent,
        DocumentSearchComponent,
        DocumentAnonymousSearchComponent,
        NotificationDialogComponent,
        DocumentDownloadDropdownComponent,
        ReceiptDetailsModalComponent,
        ReceiptActionsComponent,
        EvdAccountWidgetComponent,
        AddressFormatterComponent,
        BillDocumentListComponent
    ],
    imports: [
        FormsModule,
        NotificationsModule,
        CommonModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        TableModule,
        NgbModule,
        TableUtilModule,
        LoadingSpinnerDialogModule,
        CommonDirectivesModule,
        CommonPipesModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    exports: [
        DocumentListComponent
    ],
    providers: [
        DocumentService,
        DatePipe
    ]
})
export class DocumentModule {
}
