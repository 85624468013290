import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Component} from "@angular/core";

@Component({
    selector: 'ask-to-link-dialog',
    templateUrl: 'ask-to-link-dialog.component.html',
    styleUrls: ['./ask-to-link-dialog.component.scss']
})
export class AskToLinkDialogComponent {
    constructor(public activeModal: NgbActiveModal) {
    }
}
