import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {PlatformLocation} from "@angular/common";

import {TranslateService} from "@ngx-translate/core";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {SortEvent} from 'primeng/api';

import {switchMap} from "rxjs/operators";
import {of, Subscription} from "rxjs";

import {Document} from '../../models/document.interface';
import {DocumentService} from '../../services/document.service';
import {
    DocumentSearchCriteria,
    DocumentSearchServiceType,
    ReceiptState
} from '../../models/document-search-criteria.interface';
import {NotificationDialogComponent} from '../../../common/notification-dialog/notification-dialog.component';
import {UserService} from '../../../security/user.service';
import {Customer} from '../../../customer/models/customer.interface';
import {Carrier} from '../../../customer/models/carrier';
import {TealiumUtagService} from "../../../common/tealium-utag.service";
import {NotificationsService} from "../../../notifications/services/notifications.service";
import {CustomerSummary} from "../../../security/user.interface";
import {Order} from "../../../common/search.model";
import {DocumentDetail} from "../../models/document-detail.interface";
import {BillDocument} from "../../models/bill-document.interface";
import {CustomerService} from "../../../customer/services/customer.service";

@Component({
    selector: 'evv-document-list',
    styleUrls: ['document-list.component.css'],
    templateUrl: 'document-list.component.html'
})
/* eslint-disable */
export class DocumentListComponent implements OnInit {
    documents: Document[] = [];
    availableDocuments: { [key: number]: DocumentDetail[] } = {};
    noDocumentAvailable = false;

    downloadDocumentsList: { [id: string]: boolean; } = null;
    checkAllToDownload = false;

    currentSearchCriteria: DocumentSearchCriteria = null;
    initialSearchCriteria: DocumentSearchCriteria = null;

    totalRecords: number;

    @Input() customer: Customer;
    @Input() allCustomers: CustomerSummary[];
    @Input() carriers: Carrier[];
    @Input() currentCarrier: Carrier;
    @Input() sendingNumber: string;
    @Input() isEVDUser: boolean;
    @Output() currentCarrierChange = new EventEmitter<Carrier>();
    listIsExport = false;


    @Output()
    loadStart: EventEmitter<null> = new EventEmitter<null>();

    @Output()
    loadStop: EventEmitter<null> = new EventEmitter<null>();

    index = 0;
    rows = 10;
    sort = "receiptDocTS";
    order: Order = Order.ASC;
    billDocuments: BillDocument[];
    private availableDocumentsSubscription: Subscription = null;

    constructor(private documentService: DocumentService,
                private modalService: NgbModal,
                private userService: UserService,
                private platformLocation: PlatformLocation,
                private tealiumService: TealiumUtagService,
                private translateService: TranslateService,
                private notificationsService: NotificationsService,
                private customerService: CustomerService) {
    }

    ngOnInit(): void {
        /* If the last search criteria used match with the available customers or carriers, use it.
           Otherwise initialize a new search criteria
         */

        if (this.allCustomers != null && this.allCustomers.length > 0) {
            const search = this.documentService.lastCustomerSearchCriteria;
            if (search && this.allCustomers.find(c => c.id === search.criteria.customerId)) {
                this.currentSearchCriteria = search.criteria;
                this.index = search.index;
                this.rows = search.rows;
                this.sort = search.sort;
                this.order = search.order;
            }
        } else {
            const search = this.documentService.lastGlobalSearchCriteria;
            if (search && this.carriers.find(c => c.id === search.criteria.carrierId)) {
                this.currentSearchCriteria = search.criteria;
                this.index = search.index;
                this.rows = search.rows;
                this.sort = search.sort;
                this.order = search.order;
            }
        }

        const documentTsFrom = new Date(Date.now());
        documentTsFrom.setDate(documentTsFrom.getDate() - 30);
        const documentTsTo = new Date(Date.now());
        const customerId = this.allCustomers && this.allCustomers.length >= 1 ? this.allCustomers[0].id : null;
        const carrierId = (this.currentCarrier && this.currentCarrier.id)
            || (!this.allCustomers && this.carriers && this.carriers.length > 0 ? this.carriers[0].id : null);
        this.initialSearchCriteria = {
            customerId,
            carrierId,
            documentTsFrom,
            documentTsTo,
            serviceType: DocumentSearchServiceType.ALL,
            consigneeCity: null,
            consigneeCountry: null,
            consigneeName: null,
            consigneePostCode: null,
            consignorCity: null,
            consignorName: null,
            consignorPostCode: null,
            customerState: null,
            dutyAmount: null,
            searchNumber: null,
            vatAmount: null,
            withoutCustomer: null,
            invoiceKey: null
        };
        if (!this.currentSearchCriteria) {
            this.currentSearchCriteria = this.initialSearchCriteria;
        }
        if(this.sendingNumber) {
            // if we receive the sending number in the queryparams, overwrite the default
            this.currentSearchCriteria = {
                ...this.currentSearchCriteria,
                searchNumber: this.sendingNumber
            };
        }

        this.getDocuments();
    }

    get isKLPLoggedIn() {
        return this.userService.isUserIdpLoggedIn();
    }

    get isCustomerCare(): boolean {
        return this.userService.isUserCustomerCare();
    }

    changeRows(rows: number) {
        this.rows = rows;
        this.index = 0;
        this.getDocuments();
    }

    changePage(pageNumber: number) {
        this.index = pageNumber -1;
        this.getDocuments();
    }

    onSort($event: SortEvent) {
        const order = $event.order > 0 ? Order.ASC : Order.DESC;
        if (this.sort !== $event.field || this.order !== order) {
            this.sort = $event.field;
            this.order = order;
            this.index = 0;
            this.getDocuments();
        }
    }

    toggleAllCheckboxes() {
        // tslint:disable-next-line:forin
        for (const id in this.availableDocuments) {
            if (this.availableDocuments[id] && this.availableDocuments[id].length > 0) {
                this.downloadDocumentsList[id] = this.checkAllToDownload;
            }
        }
    }
    /* eslint-disable */
    downloadDocuments() {
        const documentIds = Object.keys(this.downloadDocumentsList).filter(id => this.downloadDocumentsList[id]).map(id => +id);
        if (documentIds.length === 0) {
            const modal = this.modalService.open(NotificationDialogComponent, {size: 'lg'});
            modal.componentInstance.title = "documents.no-documents-selected.title";
            modal.componentInstance.description = "documents.no-documents-selected.description";
            return;
        }
        this.tealiumService.link({
            event_category_primaryCategory: 'download',
            event_attributes_loc: 'main',
            event_eventInfo_cause: 'click',
            event_eventInfo_effect: 'download',
            event_attributes_tgtURL : 'unknown',
            event_eventInfo_eventLabel: this.translateService.instant('document.download.button')
        });
        this.loadStart.emit();
        this.documentService.downloadDocumentsZip(documentIds, this.currentSearchCriteria.customerId)
            .subscribe(zip => {
                this.loadStop.emit();
                DocumentService.saveFile(zip);
                this.translateService.get('document.download.success').subscribe(t => {
                    this.notificationsService.showSuccessNotification(t);
                });
            }, () => {
                this.loadStop.emit();
                this.translateService.get('document.download.error').subscribe(t => {
                    this.notificationsService.showErrorNotification(t);
                });
            }, () => {
                this.loadStop.emit();
                this.checkAllToDownload = false;
                for (const id in this.downloadDocumentsList) {
                    if (this.downloadDocumentsList[id]) {
                        this.downloadDocumentsList[id] = false;
                    }
                }
            });
    }

    handleSearch(searchCriteria: DocumentSearchCriteria) {
        if (searchCriteria.carrierId != (this.currentCarrier && this.currentCarrier.id)) {
            this.currentCarrier = searchCriteria.carrierId && this.carriers.find(c => c.id === searchCriteria.carrierId);
            this.currentCarrierChange.emit(this.currentCarrier);
        }

        this.currentSearchCriteria = searchCriteria;
        this.tealiumService.sendSingleStep("Document search after login", 'Single step - Sucheanfrage', this.currentSearchCriteria);
        this.getDocuments();
    }

    formatParcelNumbers(receiptForeignParcelNr: string, receiptChParcelNr: string) {
        if (receiptForeignParcelNr && receiptChParcelNr) {
            return receiptForeignParcelNr + "/" + receiptChParcelNr;
        } else if (!receiptForeignParcelNr && receiptChParcelNr) {
            return "-/" + receiptChParcelNr;
        } else if (receiptForeignParcelNr) {
            return receiptForeignParcelNr + "/-";
        } else {
            return "";
        }
    }

    private getDocuments() {
        const currentCriteria = this.currentSearchCriteria;
        if (currentCriteria == null) {
            return;
        }
        if (currentCriteria.invoiceKey != null) {
            delete currentCriteria.invoiceKey;
        }

        this.loadStart.emit();

        const docObservable = currentCriteria.documentNumber ?
            this.documentService.getDocumentsForTableByDocNumber(this.index, this.rows, this.sort, this.order, currentCriteria)
            : this.documentService
                .getDocumentsForTable(this.index, this.rows, this.sort, this.order, currentCriteria)
                .pipe(switchMap(data => {
                    if (data.totalElements === 0 && data.pageable.pageNumber === 0 && !currentCriteria.documentNumber && !!currentCriteria.searchNumber) {
                        currentCriteria.documentNumber = currentCriteria.searchNumber;
                        return this.documentService.getDocumentsForTableByDocNumber(this.index, this.rows, this.sort, this.order, currentCriteria);
                    }
                    return of(data);
                }));

        docObservable
            .subscribe(data => {
                    this.downloadDocumentsList = {};
                    this.documents = data.content;
                    this.index = data.pageable.pageNumber;
                    this.rows = data.pageable.pageSize;
                    this.totalRecords = data.totalElements;
                    this.documents.forEach(document => {
                        this.downloadDocumentsList[document.receiptId] = false;
                    });
                    const receiptIds = this.documents.map(d => d.receiptId)
                        .filter((id, index, self) => index === self.indexOf(id)); // distinct

                    this.checkAllToDownload = false;
                    if(this.availableDocumentsSubscription) {
                        this.availableDocumentsSubscription.unsubscribe(); // ensure previous calls are not processed anymore
                    }
                    this.availableDocumentsSubscription = this.documentService.getAvailableDocuments(receiptIds, currentCriteria.customerId)
                        .subscribe(v => {
                            this.loadStop.emit();
                            this.availableDocuments = v;
                            this.noDocumentAvailable = receiptIds.findIndex(k => v[k] && v[k].length > 0) === -1;
                        }, (resp: HttpErrorResponse) => {
                            this.loadStop.emit();
                            this.notificationsService.handleHttpError(resp);
                        });

                    if (this.currentCarrier) {
                        this.listIsExport = this.currentCarrier.serviceType === "EXPORT";
                    } else if (currentCriteria.customerId) {
                        const customer = this.allCustomers.find(cust => cust.id === currentCriteria.customerId);
                        if (customer != null) {
                            const customerCarrier = this.carriers.find(c => c.id === customer.carrId);
                            this.listIsExport = customerCarrier.serviceType === "EXPORT";
                        } else {
                            this.customerService.getCustomer(currentCriteria.customerId).subscribe(c => {
                                this.listIsExport = c.carrier.serviceType === "EXPORT"
                            });
                        }
                    }
                }, (response: HttpErrorResponse) => {
                    this.loadStop.emit();
                    this.notificationsService.handleHttpError(response);
                },
                () => {
                    this.loadStop.emit();
                    this.tealiumService.sendSingleStep("Document search after login", 'Single step - Sucheanfrage submit', null);
                });
    }

    removeIncotermSuffix(incoterm: string): string {
        const idx = (incoterm || "").indexOf("#");
        return idx >= 0 ? incoterm.substr(0, idx) : incoterm;
    }

    isDocumentsAvailable(receiptId: string){
        return this.availableDocuments[receiptId] && this.availableDocuments[receiptId].length > 0;
    }

    getDocumentStatus (document: Document) {
        return document.customerState ? document.customerState : document.receiptState? document.receiptState: ReceiptState.NEW;
    }

    getBillByInvoiceKey(invoiceKey: string, searchCriteria: DocumentSearchCriteria) {
        const docObservable = this.documentService.getBillByInvoiceKey(invoiceKey.trim(), searchCriteria.customerId);
        docObservable.subscribe(data => {
             this.billDocuments = data;
        });
    }
}
