import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../../user.service";
import {TranslateService} from "@ngx-translate/core";
import {TealiumUtagService} from "../../../../common/tealium-utag.service";
import {NotificationsService} from "../../../../notifications/services/notifications.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'evv-reset-password-by-token',
    templateUrl: 'reset-password-by-token.component.html'
})
/* eslint-disable */
export class ResetPasswordByTokenComponent implements OnInit, OnDestroy {
    token: string;
    customerNumber: string;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private userService: UserService,
                private translateService: TranslateService,
                private tealiumService: TealiumUtagService,
                private notificationsService: NotificationsService
    ) {}

    private subscription: Subscription = null;

    ngOnInit(): void {
        this.route.url.subscribe(() => {
            const paramMap = this.route.snapshot.queryParamMap;
            this.token = paramMap.get('token');
            this.customerNumber = paramMap.get('customerNumber');
            if (!this.token) {
                this.router.navigate(['../../'], {relativeTo: this.route});
            }
            this.router.navigate(['.'], {relativeTo: this.route, queryParams: {}});

            if (this.subscription) {
                this.subscription.unsubscribe();
            }
            /*this.subscription = this.translateService.stream('customer.reset-password').subscribe(t => {
                window.document.querySelector('swisspost-internet-breadcrumbs')['customItems'] = [{
                    text: t, url: `ui/reset-pwd/?token=${this.token}`
                }];
                });*/
            });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }


    handleSave(event: any) {
        //todo aggiungere isCustomerCare to decide if oldpassword is required
        // todo check if some error i.e. old password does not exited
        // this.submitted=true;
        // this.isSuccessful= true;
        this.userService.resetPassword(this.token, event.password).subscribe(() => {
            this.translateService.get('customer.change-password.success').subscribe(t => this.notificationsService.showSuccessNotification(t));
            this.router.navigate(['../../'], {relativeTo: this.route});
        }, () => {
            this.translateService.get('customer.change-password.error').subscribe(t => this.notificationsService.showErrorNotification(t));
        });
    }
}




