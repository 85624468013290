import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: 'notification-dialog',
    templateUrl: 'notification-dialog.component.html',
    styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent {
    @Input()
    title: string = null;
    @Input()
    description: string = null;
    @Output()
    approve = new EventEmitter();

    constructor(public activeModal: NgbActiveModal) {
    }

    okClicked(okString) {
        this.approve.emit(true);
        this.approve.complete();
        this.activeModal.close();
    }

    cancelClicked(cancelString) {
        this.approve.emit(false);
        this.approve.complete();
        this.activeModal.close();
    }
}
