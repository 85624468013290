import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {DocumentAnonymousSearchCriteria} from '../../models/document-anonymous-search-criteria.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {TealiumUtagService} from "../../../common/tealium-utag.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'evv-document-anonymous-search',
    styleUrls: ['document-anonymous-search.component.css'],
    templateUrl: 'document-anonymous-search.component.html'
})
export class DocumentAnonymousSearchComponent implements OnInit {
    @Input()
    searchCriteria: DocumentAnonymousSearchCriteria;

    @Output()
    search: EventEmitter<DocumentAnonymousSearchCriteria> = new EventEmitter<DocumentAnonymousSearchCriteria>();

    @Output()
    searchReset: EventEmitter<DocumentAnonymousSearchCriteria> = new EventEmitter<DocumentAnonymousSearchCriteria>();

    searchForm: FormGroup;
    @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

    @ViewChild('resetButton', {static: true})
    resetButton: ElementRef;

    @ViewChild('submitButton', {static: true})
    submitButton: ElementRef;

    /* eslint-disable */
    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private tealiumService: TealiumUtagService,
                private translateService: TranslateService) {
        this.searchForm = this.formBuilder.group({
            foreignParcelNumber: new FormControl(null, {validators: Validators.required}),
            consignorPostCode: new FormControl(null, {validators: Validators.required})
        });
    }

    get foreignParcelNumber() {
        return this.searchForm.get('foreignParcelNumber');
    }

    get consignorPostCode() {
        return this.searchForm.get('consignorPostCode');
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            // workarround
            const lang = params['lang'];
            if (lang != null) {
                this.translateService.currentLang = lang;
            }
            const parcelNr = params['parcelNr'];
            const zipCode = params['zip'];
            if (parcelNr != null && zipCode != null) {
                this.searchForm.patchValue({foreignParcelNumber: parcelNr.trim(), consignorPostCode: zipCode.trim()});
                this.handleSubmit();
            }

            this.router.navigate(['.'], {relativeTo: this.route, queryParams: {}});
        });
    }

    handleSubmit() {
        this.submitButton.nativeElement.blur();
        if (!this.searchForm.valid) {
            return;
        }
        let parcelNr = this.searchForm.get('foreignParcelNumber').value as string;
        let plz = this.searchForm.get('consignorPostCode').value as string;
        if (parcelNr != null) {
            parcelNr = parcelNr.trim();
        }
        if (plz != null) {
            plz = plz.trim();
        }
        this.searchForm.patchValue({foreignParcelNumber: parcelNr, consignorPostCode: plz});
        this.search.emit(<DocumentAnonymousSearchCriteria>this.searchForm.value);
    }

    handleReset() {
        this.formGroupDirective.resetForm();
        this.resetButton.nativeElement.blur();
        this.searchReset.emit();
    }

    goBack() {
        this.router.navigate(['.']);
    }

    @HostListener("click", ["$event"])
    public onListenerTriggered(event: any): void {
        if (event.target.id === 'search') {
            this.tealiumService.link({
                event_category_primaryCategory: 'primary button',
                event_attributes_loc: 'main',
                event_eventInfo_cause: 'click',
                event_eventInfo_effect: 'go-to',
                event_eventInfo_eventLabel: this.translateService.instant("customer.button.search")
            });
        }
    }

    back() {
        this.router.navigate(['ui', 'login', 'mailpiece']);
    }

    toWelcomePage() {
        this.router.navigate(['ui', 'login']);
    }
}

