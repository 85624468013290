
import {CustomerDownloadOptions} from "./customer-download-options.interface";

/**
 * Only summary information on the carrier, returned in user's info
 */
export interface Carrier {
    id: number;
    name: string;
    serviceType: ServiceType;
    maxReceiptPerEmail: number;
}

export enum ServiceType {
    IMPORT = "IMPORT",
    EXPORT = "EXPORT",
    EVEU = "EVEU",
    IM_EX = "IM_EX",
    EV_IM = "EV_IM",
    EV_EX = "EV_EX",
    ALL = "ALL",
    BOTH = "BOTH"
}

export enum ServiceTypeUpload {
    IMPORT = "IMPORT",
    EXPORT = "EXPORT",
    EVEU = "EVEU"
}

/**
 * Complete Carrier Entity object
 */
export interface CarrierEntity extends Carrier {
    maxLoginErrorCount: number;
    loginCheckPeriod: number;
    maxReceiptPerEmail: number;
    maxDeliveryTrialCount: number;
    senderEmailAddress: string;
    resetPasswordTemplate: string;
    oneReceiptTemplate: string;
    collectionTemplate: string;
    receiptAnonymousTemplate: string;
    numbers: string[];
    substitutionEmailAddress: string;
    version: number;
    carrierId: number;
    downloadOptions: CustomerDownloadOptions;
    hrImportIgnoredCustomerNumbers: string;
    sameReceiptBySendingNumberPeriodString: string;
    sftpGlsNumbers: string;
    pddpCustNumbers: string;
    eveuIncoterms: string
}

