// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.td-right {
    text-align: right;
    padding-right: 0.8rem;
}

.dropdown-customer > .dropdown-menu {
    right: 0;
    left: unset !important;
    min-width: 25rem !important;
}

.dropdown-customer > .dropdown-toggle::after {
    display: none;
}

td.dropdown-customer {
    overflow: visible !important;
    padding-top: 7px;
}

.btn-dropdown {
    border: none !important;
    padding: 0.8125rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/customer/containers/user-customer-list/user-customer-list.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,QAAQ;IACR,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,4BAA4B;IAC5B,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,6BAA6B;AACjC","sourcesContent":[".td-right {\n    text-align: right;\n    padding-right: 0.8rem;\n}\n\n.dropdown-customer > .dropdown-menu {\n    right: 0;\n    left: unset !important;\n    min-width: 25rem !important;\n}\n\n.dropdown-customer > .dropdown-toggle::after {\n    display: none;\n}\n\ntd.dropdown-customer {\n    overflow: visible !important;\n    padding-top: 7px;\n}\n\n.btn-dropdown {\n    border: none !important;\n    padding: 0.8125rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
