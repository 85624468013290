import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
    selector: '[swisspostDropDown]'
})
export class DropDownDirective implements OnInit {

    @Input('swisspostDropDown')
    triggerElement : Element;

    open = false;

    constructor(private el: ElementRef) {
        /* eslint-disable */
        el.nativeElement.style.display = 'none';
    }

    ngOnInit(): void {
        this.triggerElement.addEventListener('click', (event: CustomEvent) => {
            event.preventDefault();
            event.stopPropagation();
            this.open = !this.open;
            this.el.nativeElement.style.display = this.open ? 'block' : 'none';

            if(this.open) {
                document.dispatchEvent(new CustomEvent('sp-header-drop-down-opened', {detail: this}));
                hideOnClickOutside(this.el.nativeElement, this);
            }
        }, false);
    }


}
// visibility check https://stackoverflow.com/a/3028037

function hideOnClickOutside(element: HTMLElement, directive: DropDownDirective) {
    const outsideClickListener = event => {
        if (!element.contains(event.target)) { // or use: event.target.closest(selector) === null
            if (isVisible(element)) {
                removeClickListener();
            }
        }
    };

    const openedListener = (ev) => {
        if(ev.detail !== directive) {
            removeClickListener();
        }
    };

    const handleEscKey = (ev: KeyboardEvent) => {
        if (ev.keyCode === 27) { //ESC
            removeClickListener();
        }
    };

    const removeClickListener = () => {
        directive.open = false;
        element.style.display = 'none';
        document.removeEventListener('click', outsideClickListener);
        document.removeEventListener('sp-header-drop-down-opened', openedListener);
        document.removeEventListener('keyup', handleEscKey);
    };



    document.addEventListener('click', outsideClickListener);
    document.addEventListener('sp-header-drop-down-opened', openedListener);
    document.addEventListener('keyup', handleEscKey);
}
const isVisible = (elem: HTMLElement) => !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length ); // source (2018-03-11): https://github.com/jquery/jquery/blob/master/src/css/hiddenVisibleSelectors.js


// polyfill
// https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent

function polyfillCustomElement() {
    if ( typeof window['CustomEvent'] === "function" ) return false;

    function CustomEvent (event, params) {
        params = params || {bubbles: false, cancelable: false, detail: undefined};
        let evt = document.createEvent( 'CustomEvent' );
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }
    CustomEvent.prototype = window['Event'].prototype;
    //TODO window['CustomEvent'] = CustomEvent;
}

polyfillCustomElement();

interface Window {
    event: Event;
    CustomEvent(event: any, params:any): Event;
}
interface Event {
    prototype : Event;
}
declare var CustomEvent: {
    new (event : string, detail : any) : CustomEvent;
}
