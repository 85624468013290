import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Component, Input} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'wrong-login-dialog',
    templateUrl: 'wrong-login-dialog.component.html',
    styleUrls: ['./wrong-login-dialog.component.scss']
})
export class WrongLoginDialogComponent {
    @Input()
    isFromKlp?: boolean = false;
    @Input()
    domain?: string;
    error: string = null;
    username: string = null;

    constructor(public translateService: TranslateService, public activeModal: NgbActiveModal) {
    }

    getContactServiceMail() {
        /* eslint-disable */
       return "mailto:" + this.translateService.instant("wrong-login-dialog.customercare.email.address");
    }
    /* eslint-disable */
    getTitle() {
        if (this.isFromKlp) {
            return this.translateService.instant("wrong-login-dialog.title.linkToEvd.error");
        } else {
            return this.translateService.instant("wrong-login-dialog.title.login.error");
        }
    }
    get errorMessage() {
        if (this.domain == null){
            return this.translateService.instant("wrong-login-dialog.input.error.customer-number");
        }
        if (this.domain.toLowerCase() === 'gls') {
            return this.translateService.instant("wrong-login-dialog.input.error.customer-number");
        } else if (this.domain.toLowerCase() === 'pvz') {
            return this.translateService.instant("wrong-login-dialog.input.error.franking-license");
        } else if (this.domain.toLowerCase() === 'urgent') {
            return this.translateService.instant("wrong-login-dialog.input.error.reference-number")
        }

    }
}
