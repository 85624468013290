/*
 * Copyright 2022 by Swiss Post, Information Technology
 */

import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RouteDataService {
    constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    }

    /**
     * Gets the deepest activatedRoute on NavigationEnd
     */
    public get routeChange() {
        return this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => {
                let route = this.activatedRoute.firstChild;
                let child = route;
                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                        route = child;
                    } else {
                        child = null;
                    }
                }
                return route;
            })
        );
    }

    /**
     * Gets data attached to a route defined in app.routing.module
     */
    public get data() {
        return this.routeChange.pipe(mergeMap((route) => route.data));
    }
}
