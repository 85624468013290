import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CarrierEntity} from "../../customer/models/carrier";


const CARRIER_PROFILE_DATA_API = 'api/data/carriers';
@Injectable()
export class AdminService {
    constructor(private http: HttpClient) {
    }

    getCarrierProfile(carrierId: string | number) {
        return this.http.get<CarrierEntity>(`${CARRIER_PROFILE_DATA_API}/${carrierId}`);
    }

    updateCarrier(carrierId:  number, carrier: CarrierEntity): Observable<void> {
        return this.http.patch<void>(`${CARRIER_PROFILE_DATA_API}/${carrierId}`, carrier);
    }

}

