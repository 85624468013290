/*
 * ------------------------------------------------------------------------------------------------
 * Copyright 2014 by Swiss Post, Information Technology Services
 * ------------------------------------------------------------------------------------------------
 */

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ControlStatusDirective, ControlStatusGroupDirective} from "./control-status.directive";
import {DropDownDirective} from "./drop-down.directive";

@NgModule({
    declarations: [
        ControlStatusDirective, ControlStatusGroupDirective, DropDownDirective
    ],
    exports: [
        ControlStatusDirective, ControlStatusGroupDirective, DropDownDirective
    ],
    imports: [
        CommonModule
    ]
})
export class CommonDirectivesModule {
}
