import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CustomerSearch} from '../../models/customer-search.interface';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective} from '@angular/forms';
import {Carrier} from '../../models/carrier';

@Component({
    selector: 'evv-customer-search',
    styleUrls: ['customer-search.component.css'],
    templateUrl: 'customer-search.component.html'
})
export class CustomerSearchComponent implements OnInit {
    /**
     * The initial value for searchCriteria. Only used in initialization
     */
    @Input() searchCriteria: CustomerSearch;
    @Input() initialCriteria: CustomerSearch;
    @Input()
    carriers: Carrier[];
    @Input() currentCarrier: Carrier;
    @Output()
    search: EventEmitter<CustomerSearch> = new EventEmitter<CustomerSearch>();
    @ViewChild('resetButton')
    resetButton: ElementRef;
    @ViewChild('submitButton')
    submitButton: ElementRef;

    searchFormGroup: FormGroup;
    @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

    constructor(private formBuilder: FormBuilder) {
        this.searchFormGroup = this.formBuilder.group({
            carrier: new FormControl(null),
            customerNumber: new FormControl(null),
            customerName: new FormControl(null),
            sendingNumber: new FormControl(null)
        });
    }

    ngOnInit(): void {
        const criteria = this.searchCriteria || this.initialCriteria;
        this.searchFormGroup.reset(criteriaToForm(criteria));
    }
    /* eslint-disable */
    handleSubmit() {
        this.submitButton.nativeElement.blur();
        this.search.emit(<CustomerSearch>this.searchFormGroup.value);
    }

    handleReset() {
        this.resetButton.nativeElement.blur();
        this.formGroupDirective.resetForm(this.initialCriteria);
        this.search.emit(<CustomerSearch>this.searchFormGroup.value);
    }
}

function criteriaToForm(criteria: CustomerSearch) {
    return {
        ...criteria,
        carrier: criteria.carrier
    };
}
