// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bold_text {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/components/event-detail/event-detail-modal.component.scss","webpack://./../../../EHR-nightly-deploy%20manual/ehr/ehr-web-client/src/app/admin/components/event-detail/event-detail-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,qBAAA;EACA,qBAAA;ACCJ","sourcesContent":[".bold_text {\n    font-weight: bold;\n    display: inline-block;\n    margin-bottom: 0.5rem;\n}\n\n",".bold_text {\n  font-weight: bold;\n  display: inline-block;\n  margin-bottom: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
