import {AfterViewInit, Component, Input} from '@angular/core';

@Component({
    selector: 'evv-footer',
    templateUrl: './evv-footer.component.html',
    styleUrls: ['./evv-footer.component.scss']
})
export class EvvFooterComponent implements AfterViewInit {
    @Input()
    language = 'de';

    ngAfterViewInit() {
        //console.log("entro");
        const mainContent = document.getElementById("mainContent");
        const headerHeight = mainContent.getBoundingClientRect().top;

        const footerHeight = document.getElementsByTagName("evv-footer")[0].getBoundingClientRect().height;
        const containerPaddingTop = window.getComputedStyle(mainContent).paddingTop;
        const containerPaddingBottom = window.getComputedStyle(mainContent).paddingBottom;
        mainContent.style.minHeight = `calc(100vh - ${footerHeight}px - ${headerHeight}px + ${containerPaddingTop} + ${containerPaddingBottom})`;
    }
    get currentYear(): number {
        return new Date(Date.now()).getFullYear();
    }
    bottomResources: Translation = {
        'de': {
            'copyright': {label: 'Die Schweizerische Post AG'},
            'gtc': {label: 'Allgemeine Geschäftsbedingungen', url: 'https://www.post.ch/de/pages/footer/allgemeine-geschaeftsbedingungen-agb'},
            'dpd': {label: 'Datenschutz und Rechtliches', url: 'https://www.post.ch/de/pages/footer/datenschutz-und-rechtliches'},
            'pubdetails': {label: 'Impressum', url: 'https://www.post.ch/de/pages/footer/impressum'}
        },
        'fr': {
            'copyright': {label: 'La Poste Suisse SA'},
            'gtc': {label: 'Conditions générales', url: 'https://www.post.ch/fr/pages/footer/conditions-generales-cg'},
            'dpd': {label: 'Protection des données et informations légales', url: 'https://www.post.ch/fr/pages/footer/protection-des-donnees-et-informations-legales'},
            'pubdetails': {label: 'Impressum', url: 'https://www.post.ch/fr/pages/footer/impressum'}
        },
        'it': {
            'copyright': {label: 'La Posta Svizzera SA'},
            'gtc': {label: 'Condizioni generali', url: 'https://www.post.ch/it/pages/footer/condizioni-generali-cg'},
            'dpd': {label: 'Protezione dei dati e avvertenze legali', url: 'https://www.post.ch/it/pages/footer/protezione-dei-dati-e-avvertenze-legali'},
            'pubdetails': {label: 'Colophon', url: 'https://www.post.ch/it/pages/footer/colophon'}
        },
        'en': {
            'copyright': {label: 'Swiss Post Ltd'},
            'gtc': {label: 'General Terms and Conditions', url: 'https://www.post.ch/en/pages/footer/general-terms-and-conditions-gtc'},
            'dpd': {label: 'Data protection and disclaimer', url: 'https://www.post.ch/en/pages/footer/data-protection-and-disclaimer'},
            'pubdetails': {label: 'Publication details', url: 'https://www.post.ch/en/pages/footer/publication-details'}
        }
    };
}

export interface Translation {
    [lang: string]: LocalizedItems;
}

export interface LocalizedItems {
    [key: string]: LocalizedItem;
}
export interface LocalizedItem {
    label: string;
    url?: string;
    openInNewPage?: boolean;
}

