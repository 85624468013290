import {Component, OnDestroy} from "@angular/core";
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {UserService} from "../../user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {NotificationsService} from "../../../notifications/services/notifications.service";
import {Observable, Subscription} from "rxjs";
import {debounceTime, map} from "rxjs/operators";

const USERNAME_PATTERN="^[A-Za-z0-9]*$";


@Component({
    selector: 'evd-forgot-password',
    templateUrl: 'forgot-password.component.html'
})
/* eslint-disable */
export class ForgotPasswordComponent implements OnDestroy {
    forgotPasswordForm: FormGroup;

    private readonly subscription: Subscription;

    constructor(private formBuilder: FormBuilder,
                private userService: UserService,
                private router: Router,
                private route: ActivatedRoute,
                private translateService: TranslateService,
                private notificationsService: NotificationsService) {
        this.forgotPasswordForm = this.formBuilder.group({
            username: new FormControl(null, [Validators.required, Validators.pattern(USERNAME_PATTERN)], this.validateCustomerNumber.bind(this)),
            email: new FormControl(null,  [Validators.required, Validators.email])});
        /*this.subscription = this.translateService.stream('login.forgot-password.title').subscribe(t => {
            window.document.querySelector('swisspost-internet-breadcrumbs')['customItems'] = [{text: t, url: "ui/forgot-password"}];
        });*/
    }

    ngOnDestroy(): void {
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    handleSubmit() {
        if (this.forgotPasswordForm.valid) {
            const forgotPasswordRequest = this.forgotPasswordForm.getRawValue();
            this.userService.sendForgotPassword(forgotPasswordRequest.username, forgotPasswordRequest.email).subscribe(() => {
                this.translateService.get('login.forgot-password.send-success').subscribe(t => {
                    this.notificationsService.showSuccessNotification(t);
                });

                this.router.navigate(['../../'], {relativeTo: this.route});
            }, () => {
                this.translateService.get('login.forgot-password.send-error').subscribe(t => {
                    this.notificationsService.showErrorNotification(t);
                });
            });
        }
    }

    goToLogin() {
        this.router.navigate(['../', 'login'], {relativeTo: this.route});
    }

    validateCustomerNumber({value}: AbstractControl): Observable<ValidationErrors | null> {
        return this.userService.isForgotPasswordCustomerExisted(value)
            .pipe(debounceTime(500), map((customerExists: boolean) => {
                if (!customerExists) {
                    return {
                        notExists: true
                    };
                }
                return null;
            }));
    }

    get email() {
        return this.forgotPasswordForm.get('email') as FormControl;
    }
}
