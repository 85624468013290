// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doc-row {
  padding: 1.1rem;
  border-top: 2px solid rgba(0, 0, 0, 0.2) !important;
  text-decoration: none;
  cursor: pointer;
}

.doc-row:hover {
  background-color: #f4f3f1;
  color: black;
}

.doc-row > .pi {
  top: -3px;
  position: relative;
  float: left;
}

.bold_text {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/document/components/receipt-details-modal/receipt-details-modal.component.scss","webpack://./../../../EHR-nightly-deploy%20manual/ehr/ehr-web-client/src/app/document/components/receipt-details-modal/receipt-details-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,mDAAA;EACA,qBAAA;EACA,eAAA;ACCF;;ADEA;EACE,yBAAA;EACA,YAAA;ACCF;;ADEA;EACE,SAAA;EACA,kBAAA;EACA,WAAA;ACCF;;ADCA;EACI,iBAAA;EACA,qBAAA;EACA,qBAAA;ACEJ","sourcesContent":[".doc-row {\n  padding: 1.1rem;\n  border-top: 2px solid rgba(0, 0, 0, 0.2) !important;\n  text-decoration: none;\n  cursor: pointer;\n}\n\n.doc-row:hover {\n  background-color: #f4f3f1;\n  color: black;\n}\n\n.doc-row > .pi {\n  top: -3px;\n  position: relative;\n  float: left;\n}\n.bold_text {\n    font-weight: bold;\n    display: inline-block;\n    margin-bottom: 0.5rem;\n}\n",".doc-row {\n  padding: 1.1rem;\n  border-top: 2px solid rgba(0, 0, 0, 0.2) !important;\n  text-decoration: none;\n  cursor: pointer;\n}\n\n.doc-row:hover {\n  background-color: #f4f3f1;\n  color: black;\n}\n\n.doc-row > .pi {\n  top: -3px;\n  position: relative;\n  float: left;\n}\n\n.bold_text {\n  font-weight: bold;\n  display: inline-block;\n  margin-bottom: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
