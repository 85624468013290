import {Component} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {of, Subscription} from "rxjs";
import {switchMap} from "rxjs/operators";
import {Document as EVDDocument} from '../../models/document.interface';
import {DocumentService, PagedDocuments} from '../../services/document.service';
import {LoadingSpinnerDialogService} from '../../../common/loading-spinner-dialog/loading-spinner-dialog.service';
import {UserService} from '../../../security/user.service';
import {Customer} from '../../../customer/models/customer.interface';
import {DocumentAnonymousSearchCriteria} from '../../models/document-anonymous-search-criteria.interface';
import {TealiumUtagService} from "../../../common/tealium-utag.service";
import {NotificationDialogComponent} from "../../../common/notification-dialog/notification-dialog.component";
import {NotificationsService} from "../../../notifications/services/notifications.service";
import {Order} from "../../../common/search.model";
import {DocumentDetail} from "../../models/document-detail.interface";
import {ReceiptState} from "../../models/document-search-criteria.interface";
import {TableLazyLoadEvent} from "primeng/table";

@Component({
    selector: 'evv-document-anonymous-list',
    styleUrls: ['document-anonymous-list.component.css'],
    templateUrl: 'document-anonymous-list.component.html'
})
/* eslint-disable */
export class DocumentAnonymousListComponent {
    documents: EVDDocument[];
    availableDocuments: { [key: number]: DocumentDetail[] } = {};
    downloadDocumentsList: { [id: string]: boolean; } = null;
    noDocumentAvailable = false;
    checkAllToDownload = false;

    totalRecords: number;

    customer: Customer;
    carrierId: string;

    index = 0;
    rows = 10;

    searchCriteria: DocumentAnonymousSearchCriteria;

    listIsExport = false;
    sort: string = null;
    order: Order = null;
    private availableDocumentSubscription: Subscription;

    constructor(private documentService: DocumentService,
                private loadingDialog: LoadingSpinnerDialogService,
                private userService: UserService,
                private translateService: TranslateService,
                private tealiumService: TealiumUtagService,
                private modalService: NgbModal,
                private notificationsService: NotificationsService) {
    }

    get isCustomerCare(): boolean {
        return this.userService.isUserCustomerCare();
    }

    changeRows(rows: number) {
        this.rows = rows;
        this.index = 0;
        this.getDocuments(this.index, this.rows);
    }

    changePage(pageNumber: number) {
        this.index = pageNumber -1;
        this.getDocuments(this.index, this.rows);
    }

    loadDocumentsLazy(event: TableLazyLoadEvent) {
        this.getDocuments(event.first ?
            (event.first >= event.rows) ? (event.first / event.rows) + 1 : event.first : 0,
            event.rows,
            null,
            event.sortOrder === 1 ? Order.ASC : Order.DESC
        );
    }

    handleSearch(searchCriteria: DocumentAnonymousSearchCriteria) {
        const isTableHidden = !this.searchCriteria;
        this.searchCriteria = {...searchCriteria}; // create a copy to prevent changes to be done in the form
        this.tealiumService.sendSingleStep("Anonymous document search", 'Single step - Sucheanfrage', this.searchCriteria);
        if (!isTableHidden) {
            this.getDocuments(0, this.rows);
        }
    }

    handleSearchReset() {
        this.documents = null;
    }

    formatParcelNumbers(receiptForeignParcelNr: string, receiptChParcelNr: string) {
        if (receiptForeignParcelNr && receiptChParcelNr) {
            return receiptForeignParcelNr + "/" + receiptChParcelNr;
        } else if (!receiptForeignParcelNr && receiptChParcelNr) {
            return "-/" + receiptChParcelNr;
        } else if (receiptForeignParcelNr) {
            return receiptForeignParcelNr + "/-";
        } else {
            return "";
        }
    }

    toggleAllCheckboxes() {
        // tslint:disable-next-line:forin
        for (const id in this.availableDocuments) {
            if (this.availableDocuments[id] && this.availableDocuments[id].length > 0) {
                this.downloadDocumentsList[id] = this.checkAllToDownload;
            }
        }
    }

    downloadDocuments() {
        const documentIds = Object.keys(this.downloadDocumentsList).filter(id => this.downloadDocumentsList[id]).map(id => +id);
        if (documentIds.length === 0) {
            const modal = this.modalService.open(NotificationDialogComponent, {size: 'lg'});
            modal.componentInstance.title = "documents.no-documents-selected.title";
            modal.componentInstance.description = "documents.no-documents-selected.description";
            return;
        }
        this.tealiumService.link({
            event_category_primaryCategory: 'download',
            event_attributes_loc: 'main',
            event_eventInfo_cause: 'click',
            event_eventInfo_effect: 'download',
            event_attributes_tgtURL : 'unknown',
            event_eventInfo_eventLabel: this.translateService.instant('document.download.button')
        });
        this.loadingDialog.show();
        this.documentService.downloadDocumentsZip(documentIds, null, this.searchCriteria)
            .subscribe(zip => {
                DocumentService.saveFile(zip);
                this.translateService.get('document.download.success').subscribe(t => {
                    this.notificationsService.showSuccessNotification(t);
                });
            }, () => {
                this.translateService.get('document.download.error').subscribe(t => {
                    this.notificationsService.showErrorNotification(t);
                });
            }, () => {
                this.loadingDialog.hide();
                this.checkAllToDownload = false;
                for (const id in this.downloadDocumentsList) {
                    if (this.downloadDocumentsList[id]) {
                        this.downloadDocumentsList[id] = false;
                    }
                }
            });
    }

    private getDocuments(index: number, rows: number, sort: string = null, order: Order = null) {
        this.loadingDialog.show();
        if (sort) {
            this.sort = sort;
        } else {
            sort = this.sort;
        }

        if (order) {
            this.order = order;
        } else {
            order = this.order;
        }
        const currentCriteria = this.searchCriteria;
        currentCriteria.accessCode = currentCriteria.consignorPostCode;

        this.documentService
            .getAnonymousDocumentsForTableByRandomCode(index, rows, currentCriteria, sort, order)
            .pipe(
                switchMap((data: PagedDocuments) => {
                    if (data.totalElements === 0 && data.pageable.pageNumber === 0) {
                        currentCriteria.documentNumber = currentCriteria.foreignParcelNumber;
                        if (currentCriteria.foreignParcelNumber) {
                            delete currentCriteria.foreignParcelNumber;
                        }
                        return this.documentService.getAnonymousDocumentsForTableByDocNrRanCd(index, rows, currentCriteria, sort, order);
                    } else {
                        if (currentCriteria.consignorPostCode) {
                            delete currentCriteria.consignorPostCode;
                        }
                    }
                    return of(data);
                })
            ).pipe(
                switchMap((data : PagedDocuments) => {
                    if (data.totalElements === 0 && data.pageable.pageNumber === 0) {
                        currentCriteria.foreignParcelNumber = currentCriteria.documentNumber;
                        currentCriteria.consignorPostCode = currentCriteria.accessCode;
                        if (currentCriteria.documentNumber) {
                            delete currentCriteria.documentNumber;
                        }
                        if (currentCriteria.accessCode) {
                            delete currentCriteria.accessCode;
                        }
                        return this.documentService.getAnonymousDocumentsForTable(index, rows, currentCriteria, sort, order);
                    } else {
                        if (currentCriteria.consignorPostCode) {
                            delete currentCriteria.consignorPostCode;
                        }
                    }
                    return of(data);
                })
            ).pipe(
                switchMap((data: PagedDocuments) => {
                    if (data.totalElements === 0 && data.pageable.pageNumber === 0) {
                        currentCriteria.documentNumber = currentCriteria.foreignParcelNumber;
                        if (currentCriteria.foreignParcelNumber) {
                            delete currentCriteria.foreignParcelNumber;
                        }
                        if (currentCriteria.accessCode) {
                            delete currentCriteria.accessCode;
                        }
                        return this.documentService.getAnonymousDocumentsForTableByDocNumber(index, rows, currentCriteria, sort, order);
                    }
                    return of(data);
                })
            )
            .subscribe((data: PagedDocuments) => {
                this.downloadDocumentsList = {};
                this.documents = data.content;
                this.index = data.pageable.pageNumber;
                this.rows = data.pageable.pageSize;
                this.totalRecords = data.totalElements;
                const receiptIds = this.documents.map(d => d.receiptId);

                if(this.availableDocumentSubscription) {
                    this.availableDocumentSubscription.unsubscribe(); // ensure previous calls are not processed anymore
                }

                this.availableDocumentSubscription = this.documentService.getAvailableDocuments(receiptIds, null, currentCriteria)
                    .subscribe(v => {
                        this.loadingDialog.hide();
                        this.availableDocuments = v;
                        this.noDocumentAvailable = receiptIds.findIndex(k => v[k] && v[k].length>0) === -1;
                    }, (resp: HttpErrorResponse) => {
                        this.loadingDialog.hide();
                        this.notificationsService.handleHttpError(resp);
                    });

            }, (response: HttpErrorResponse) => {
                this.loadingDialog.hide();
                this.notificationsService.handleHttpError(response);
            }, () => {
                this.loadingDialog.hide();
                this.tealiumService.sendSingleStep("Anonymous document search", 'Single step - Sucheanfrage submit', null);
            });
    }

    removeIncotermSuffix(incoterm: string): string {
        const idx = (incoterm || "").indexOf("#");
        return idx >= 0 ? incoterm.substr(0, idx) : incoterm;
    }

    isDocumentsAvailable(receiptId: string){
        return this.availableDocuments[receiptId] && this.availableDocuments[receiptId].length > 0;
    }

    getDocumentStatus (document: EVDDocument) {
        return document.customerState ? document.customerState : document.receiptState? document.receiptState: ReceiptState.NEW;
    }
}
