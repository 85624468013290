import {NgModule} from "@angular/core";
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {EvvHeaderComponent} from "./evv-header.component";
import {CommonDirectivesModule} from "../directives/common-directives.module";
import {RouterModule} from "@angular/router";
import {EvvHelpContactComponent} from "./evv-help-contact.component";


@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgbModule,
        RouterModule,
        CommonDirectivesModule
    ],
    declarations: [
        EvvHeaderComponent,
        EvvHelpContactComponent
    ],
    exports: [
        EvvHeaderComponent,
        EvvHelpContactComponent
    ]
})
export class EVVHeaderModule {

}
