import {Component, Input, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {UserService} from "../../../user.service";
import {Router} from "@angular/router";
import {PasswordRepeatValidator} from "../../../../common/password-repeat.validator";
import {switchMap} from "rxjs/operators";

const PASSWORD_PATTERN = "^(?=.*[A-Za-z])(?=.*[0-9])[A-Za-z0-9]{8,}$";
@Component({
    selector: 'set-new-password',
    templateUrl: 'set-new-password.component.html'
})
/* eslint-disable */
export class SetNewPasswordComponent {
    setNewPasswordForm: FormGroup;
    @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

    @Input()
    targetUrl: string = null;

    constructor(private formBuilder: FormBuilder,
                private userService: UserService,
                private router: Router) {
        this.setNewPasswordForm = this.formBuilder.group({
            username: new FormControl(null, Validators.required),
            oldPassword: new FormControl({value: null, disabled: true}, Validators.required),
            newPassword: new FormControl(null, [Validators.required, Validators.pattern(PASSWORD_PATTERN)]),
            repeatNewPassword: new FormControl(null, [Validators.required, Validators.pattern(PASSWORD_PATTERN)])
        }, {validator: PasswordRepeatValidator('newPassword', 'repeatNewPassword')});
    }

    @Input()
    set customerData(data: any) {
        if (!data) {
            return;
        }

        this.setNewPasswordForm.patchValue({username: data.username, oldPassword: data.password});
    }

    handleSubmit() {
        const userInfo = this.setNewPasswordForm.getRawValue();
        this.userService.updatePassword(userInfo.username, userInfo.oldPassword, userInfo.newPassword)
            .pipe(
                switchMap(() => this.userService.login(userInfo.username, userInfo.newPassword))
            ).subscribe(() => {
            this.router.navigate([this.targetUrl ? this.targetUrl : '/']);
        });
    }


    handleCancel() {
        this.formGroupDirective.resetForm();
    }

    switchPasswordVisibility(password: HTMLInputElement) {
        password.type = password.type === 'password' ? 'text' : 'password';
    }

    showPasswordIcon(password: HTMLInputElement) {
        return password.type === 'password' ? 'pi-2114' : 'pi-2115';
    }
}
