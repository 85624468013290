/*
 * ------------------------------------------------------------------------------------------------
 * Copyright 2014 by Swiss Post, Information Technology Services
 * ------------------------------------------------------------------------------------------------
 */

export interface Metadata {
    id: number,
    documentCategory: DocumentCategory,
    status: ImportRequestStatus,
    warning: string,
    creationTs: string,
    mutationTs: string,
    carrierId: number,
    foreignParcelNumber: string
}

export enum DocumentCategory {
    DP = "DP",
    HR = "HR",
    OS ="OS",
    PR = "PR",
    PVZHR = "PVZHR",
    ZB = "ZB"
}

export enum ImportRequestStatus {
    DUPLICATE = "DUPLICATE",
    ERROR = "ERROR",
    IMPORTED = "IMPORTED",
    NEW = "NEW"
}
