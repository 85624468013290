import {Component, EventEmitter, Output} from "@angular/core";

@Component({
    selector: 'evv-evd-login',
    templateUrl: 'evd-login.component.html'
})
export class EvdLoginComponent {

    languages: any;
    @Output()
    changePassword: EventEmitter<any> = new EventEmitter<any>();

    navigateToChangePassword() {
        this.changePassword.emit('changePassword');
    }
}
