import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {LoadingSpinnerDialogService} from '../../../common/loading-spinner-dialog/loading-spinner-dialog.service';
import {CustomerService} from '../../../customer/services/customer.service';
import {UserService} from '../../../security/user.service';
import {Customer} from '../../../customer/models/customer.interface';
import {TranslateService} from '@ngx-translate/core';
import {CLIENT_CONFIG} from "../../../common/constants";
import {ClientConfig} from "../../../common/client-config.model";
import {TealiumUtagService} from "../../../common/tealium-utag.service";
import {CustomerSummary} from "../../../security/user.interface";
import {map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {Carrier} from "../../../customer/models/carrier";

@Component({
    templateUrl: 'documents.component.html'
})
/* eslint-disable */
export class DocumentsComponent implements OnInit {
    customer: Customer;
    allCustomers: CustomerSummary[];
    carriers: Carrier[];
    sendingNumber: string;
    showBack: boolean;

    onLoadStop() {
        this.loadingDialog.hide();
    }

    onLoadStart() {
        this.loadingDialog.show();
    }

    constructor(private customerService: CustomerService,
                private activatedRoute: ActivatedRoute,
                private loadingDialog: LoadingSpinnerDialogService,
                private userService: UserService,
                private translateService: TranslateService,
                private tealiumService: TealiumUtagService,
                private router: Router,
                @Inject(CLIENT_CONFIG) private clientConfig: ClientConfig) {
    }

    get currentUser() {
        return this.userService.getCurrentUser();
    }

    get isEVDUser() {
        return this.currentUser.customers.length === 1 && !this.isKLPLoggedIn && !this.isCustomerCare;
    }

    get isKLPLoggedIn() {
        return this.userService.isUserIdpLoggedIn();
    }

    get isCustomerCare(): boolean {
        return this.userService.isUserCustomerCare();
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe(data => this.showBack = !!data.showBack);
        this.activatedRoute.params.pipe(switchMap((params: Params) => {

                /* This component support the following cases:
                    1. customer specific search for cca
                        + customerId as param
                        + allCustomers = the single customer
                        + allCarriers = the carrier of the customer
                    2. customer specific search for customer (IDP or not)
                        + customerId as param
                        + allCustomers = the single customer
                        + allCarriers = the carrier of the customer
                    3. global search for IDP customer
                        + customerId null
                        + allCustomers = the user's customers
                        + allCarriers = the user's carriers
                 */

                const customerId = params['customerId'] ? +params['customerId'] : null;

                if(customerId) { // cases 1. and 2.
                    return this.customerService.getCustomer(customerId).pipe(map(customer => {
                        const allCarriers = this.userService.getCurrentUser().carriers.filter(c => c.id === customer.carrId);
                        return {
                            customer,
                            allCustomers: [customer],
                            allCarriers
                        };
                    }));
                } else {
                    return of({
                        customer: null as Customer,
                        allCustomers: this.userService.getCurrentUser().customers,
                        allCarriers: this.userService.getCurrentUser().carriers
                    });
                }
            })
        ).subscribe(({customer, allCustomers, allCarriers}) => {
            this.customer = customer;
            this.allCustomers = allCustomers;
            this.carriers = allCarriers;
        });

        this.activatedRoute.queryParams.subscribe(
            params => {
                this.sendingNumber = (params['sendingNumber'] && params['sendingNumber'].trim()) || null;
            }
        );
        /*if (this.isKLPLoggedIn) {
            window.document.querySelector('swisspost-internet-breadcrumbs')['customItems'] = null;
        }*/
    }

    goBack() {
        // CCA ADMIN
        this.router.navigate(['ui', 'cca']);
    }
}
