// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-link {
    padding: 0.5rem !important;
}

.td-right {
    text-align: right;
    padding-right: 0.8rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/customer/components/customer-list/customer-list.component.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;IACjB,qBAAqB;AACzB","sourcesContent":[".btn-link {\n    padding: 0.5rem !important;\n}\n\n.td-right {\n    text-align: right;\n    padding-right: 0.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
