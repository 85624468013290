import {Component, Input} from '@angular/core';

@Component({
    selector: 'evd-address-formatter',
    templateUrl: 'address-formatter.component.html'
})
export class AddressFormatterComponent {
    @Input()
    address: any;
}
