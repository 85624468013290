/* eslint-disable */
import {DefaultUrlSerializer, UrlSerializer, UrlTree} from "@angular/router";

export class CustomUrlSerializer implements UrlSerializer {
    public ds = new DefaultUrlSerializer();
    parse(url: any): UrlTree {
        return this.ds.parse(url);
    }

    serialize(tree: UrlTree): any {
        const serializedUrl = this.ds.serialize(tree);
        // use your regex to replace as per your requirement.
        return serializedUrl.replace(/%3F/gi, '?')
            .replace(/%3D/gi, '=')
            .replace(/%26/gi, '&')
            .replace(/%20/gi, '');
    }
}
