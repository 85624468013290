import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {TableModule} from 'primeng/table';

import {LoadingSpinnerDialogModule} from '../common/loading-spinner-dialog/loading-spinner-dialog.module';
import {TableUtilModule} from '../common/table/table-util.module';
import {NotificationsModule} from '../notifications/notifications.module';
import {CustomerFormComponent} from './components/customer-form/customer-form.component';
import {CustomerSearchComponent} from './components/customer-search/customer-search.component';
import {CustomerCreateComponent} from './containers/customer-create/customer-create.component';
import {CustomerDetailComponent} from './containers/customer-detail/customer-detail.component';
import {CustomerListComponent} from './components/customer-list/customer-list.component';
import {UserCustomerListComponent} from './containers/user-customer-list/user-customer-list.component';
import {CustomerService} from './services/customer.service';
import {PasswordFormComponent} from "./components/password-form/password-form.component";
import {ChangePasswordModalComponent} from "./containers/change-password-modal/change-password-modal.component";
import {CommonDirectivesModule} from "../common/directives/common-directives.module";
import {CustomerInfoComponent} from "./components/customer-info/customer-info.component";
import {ContactInfoComponent} from "./components/contact-info/contact-info.component";
import {DocumentDeliverySettingsComponent} from "./components/document-delivery-settings/document-delivery-settings.component";
import {CcaCustomerInfoComponent} from "./components/cca-customer-info/cca-customer-info.component";
import {CcaResetPasswordComponent} from "./components/cca-reset-password/cca-reset-password.component";
import {EvdLoginComponent} from "./components/evd-login/evd-login.component";
import {CommonPipesModule} from "../common/pipes/common-pipes.module";
import {LinkCustomerModalComponent} from "./components/link-customer-modal/link-customer-modal.component";
import {CcaUploadFileComponent} from "./components/cca-upload-file/cca-upload-file.component";
import {CcaFileUploaderService} from "./services/cca-file-uploader.service";
import {CcaUploadFileConfirmationComponent} from "./components/cca-upload-file-confirmation/cca-upload-file-confirmation.component";


@NgModule({
    declarations: [
        CustomerListComponent,
        UserCustomerListComponent,
        CustomerDetailComponent,
        CustomerFormComponent,
        CustomerSearchComponent,
        CustomerCreateComponent,
        PasswordFormComponent,
        ChangePasswordModalComponent,
        CustomerInfoComponent,
        ContactInfoComponent,
        DocumentDeliverySettingsComponent,
        CcaCustomerInfoComponent,
        CcaResetPasswordComponent,
        EvdLoginComponent,
        LinkCustomerModalComponent,
        CcaUploadFileComponent,
        CcaUploadFileConfirmationComponent
    ],
    exports: [
        CustomerListComponent,
        PasswordFormComponent

    ],
    imports: [
        FormsModule,
        NotificationsModule,
        CommonModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        TableModule,
        NgbModule,
        TableUtilModule,
        LoadingSpinnerDialogModule,
        CommonDirectivesModule,
        CommonPipesModule
    ],
    providers: [
        CustomerService,
        CcaFileUploaderService
    ]
})
export class CustomerModule {
}
