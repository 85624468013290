import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CountryOrderByPipe} from "./country-order-by.pipe";

@NgModule({
    declarations: [
       CountryOrderByPipe
    ],
    exports: [
        CountryOrderByPipe
    ],
    imports: [
        CommonModule
    ]
})
export class CommonPipesModule {
}
