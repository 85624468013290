import {NgModule} from "@angular/core";
import {LoadingSpinnerDialogService} from "./loading-spinner-dialog.service";
import {LoadingSpinnerDialogComponent} from "./loading-spinner-dialog.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    declarations: [
        LoadingSpinnerDialogComponent
    ],
    providers: [
        NgbModal,
        LoadingSpinnerDialogService
    ]
})
export class LoadingSpinnerDialogModule {
}
