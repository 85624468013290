// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h4 {
  margin-bottom: 2rem;
}

.modal-body {
  padding-top: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/customer/containers/change-password-modal/change-password-modal.component.scss","webpack://./../../../EHR-nightly-deploy%20manual/ehr/ehr-web-client/src/app/customer/containers/change-password-modal/change-password-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;ACCF;;ADEA;EACE,cAAA;ACCF","sourcesContent":["h4 {\n  margin-bottom: 2rem;\n}\n\n.modal-body {\n  padding-top: 0;\n}\n","h4 {\n  margin-bottom: 2rem;\n}\n\n.modal-body {\n  padding-top: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
