import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LoadingSpinnerDialogService} from '../../../common/loading-spinner-dialog/loading-spinner-dialog.service';
import {Carrier} from "../../../customer/models/carrier";
import {UserService} from "../../../security/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {CLIENT_CONFIG} from "../../../common/constants";
import {ClientConfig} from "../../../common/client-config.model";

/* eslint-disable */
@Component({
    selector: 'evd-login-without-support',
    templateUrl: 'login-without-support.component.html',
    styleUrls: ['login-without-support.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class LoginWithoutSupportComponent implements OnInit {
    carriers: Carrier[];
    currentCarrier: Carrier = null;
    isAdmin: boolean;
    isTnt: boolean;
    domain$: Observable<string>;

    activeTab:  string;

    onLoadStop() {
        this.loadingDialog.hide();
    }

    onLoadStart() {
        this.loadingDialog.show();
    }

    constructor(
        private loadingDialog: LoadingSpinnerDialogService,
        private userService: UserService,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private router: Router,
        @Inject(CLIENT_CONFIG) public clientConfig: ClientConfig) {
    }
    /* eslint-disable */
    ngOnInit(): void {
        this.domain$ = this.route.queryParams.pipe(
            map(params => params['domain'])
        );

        this.route.url.pipe(
            switchMap(url => this.domain$.pipe(map(domain => ({url, domain}))))
        ).subscribe(({url, domain}) => {
            if (url.length > 0) {
                const urlChildSegment = url[url.length-1].path.toLowerCase();
                if (urlChildSegment === 'mailpiece') {
                    this.activeTab = 'tab-anonymous-login';
                } else if (urlChildSegment === 'customer' && domain != null) {
                    if (domain.trim().toLowerCase() === 'gls') {
                        this.activeTab = 'tab-gls-login';
                    } else if (domain.trim().toLowerCase() === 'pvz') {
                        this.activeTab = 'tab-pvz-login';
                    } else if (domain.trim().toLowerCase() === 'urgent') {
                        this.activeTab = 'tab-urgent-login';
                    }
                } else if (urlChildSegment === 'klp') {
                    this.activeTab = 'tab-klp-login';
                }
            } else {
                this.activeTab = 'tab-gls-login';
            }
        });
    }

    tabChanged(nextId) {
       switch (nextId) {
            case 'tab-anonymous-login':
                this.router.navigate(['ui', 'login', 'step-without-support', 'mailpiece'], { queryParams:{mailpieceNr: '', inTab: true}});
                break;
            case 'tab-gls-login':
                this.router.navigate(['ui', 'login', 'step-without-support', 'customer'], { queryParams:{customerNr: '', domain: 'gls', inTab: true}});
                break;
            case 'tab-urgent-login':
                this.router.navigate(['ui', 'login', 'step-without-support', 'customer'], { queryParams:{customerNr: '', domain: 'urgent', inTab: true}});
                break;
            case 'tab-pvz-login':
                this.router.navigate(['ui', 'login', 'step-without-support', 'customer'], { queryParams:{customerNr: '', domain: 'pvz', inTab: true}});
                break;
           case 'tab-klp-login':
               this.router.navigate(['ui', 'login', 'step-without-support', 'klp']);
               break;
        }
    }

    get klpEvdLoginUrl() {
        const url = new URL(this.clientConfig.klpEvdLoginUrl);
        const lang = this.translateService.currentLang;
        url.searchParams.set('lang', lang);
        return url.toString();
    }

    getListItemBackground(tab: string) {
        if (this.activeTab === tab) {
            return 'listItemBackgroundActiv';
        }
        return 'listItemBackground'
    }
}


