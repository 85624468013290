import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {map} from "rxjs/operators";

import {PagedData} from '../../common/spring-data/paged-data';
import {CustomerSearch} from '../models/customer-search.interface';
import {Customer} from '../models/customer.interface';
import {UserService} from "../../security/user.service";
import {Order, Search} from "../../common/search.model";

const CUSTOMER_DATA_API = 'api/data/customers';
const CUSTOMER_API = 'api/customers';

@Injectable()
export class CustomerService {
    private _lastSearch: Search<CustomerSearch> = null;

    constructor(private http: HttpClient, private userService: UserService) {
    }

    getCustomers(index: number, rows: number) {
        return this.http.get(`${CUSTOMER_DATA_API}?page=${index}&size=${rows}`);
    }

    getUserCustomers() {
        return this.http.get(`${CUSTOMER_DATA_API}/search/findAllUserCustomers`);
    }

    getCustomersCCAForTable(index: number, rows: number,
                            sort: string = null, order: Order = null,
                            criteria: CustomerSearch): Observable<PagedData> {

        this._lastSearch = {
            criteria,
            index,
            rows,
            sort,
            order
        };

        let query = "findAllBySearchCriteria";
        let params = new HttpParams()
            .append("page", String(index))
            .append("size", String(rows));

        if(criteria.carrier) {
            params = params.append("carrierId", criteria.carrier.id.toString());
        }
        const normalizeField = (v: string) => v.replace(/\*/g, '%').trim();

        if(criteria.customerName) {
            params = params.append('name', normalizeField(criteria.customerName));
        }
        if(criteria.customerNumber) {
            params = params.append('number', normalizeField(criteria.customerNumber));
        }
        if(criteria.documentNumber) {
            params = params.append('documentNr', normalizeField(criteria.sendingNumber));
            query = 'findByDocument';
            if (sort && order) {
                params = params.append('sort', 'customerReceipts.customer.' + sort + ',' + order);
            }
        } else if(criteria.sendingNumber) {
            params = params.append('sendingNr', normalizeField(criteria.sendingNumber));
            query = 'findByShipment';
            if (sort && order) {
                params = params.append('sort', 'customerReceipts.customer.' + sort + ',' + order);
            }
        } else if (sort && order) {
            params = params.append('sort', sort + ',' + order);
        }

        return this.http.get<PagedData>(`${CUSTOMER_DATA_API}/search/${query}`, {params});
    }

    public get lastCustomerSearch() {
        return this._lastSearch;
    }

    getCustomer(id: number): Observable<Customer> {
        return this.http.get<Customer>(`${CUSTOMER_DATA_API}/${id}`).pipe(
            map(c => ({...c, id: c.id || c.customerId})) // workaround to make Customer extend CustomerSummary
        );
    }

    // TODO: temp
    getCustomerCarrier(id: string): Observable<any> {
        return this.http.get(`${CUSTOMER_DATA_API}/${id}/carrier`);
    }

    createCustomer(customer: any) {
        return this.http.post(`${CUSTOMER_API}/`, customer);
    }
    /* eslint-disable */
    updateCustomer(id: number, customer: any): Observable<void> {
        // TODO: check with Andrea how handle this
        // ServerSide
        if (!customer.password) {
            delete customer.password;
        }
        const customerCare = this.userService.isUserCustomerCare();
        return this.http.post<void>(`${CUSTOMER_API}/${id}/${customerCare ? 'full' : 'summary'}`, customer);
    }

    updateCustomerState(id: number, state: string) {
        return this.http.post<void>(`${CUSTOMER_API}/${id}/state`, JSON.stringify(state),
            {headers: new HttpHeaders().set('Content-Type', 'application/json')});
    }

    sendResetPasswordEmail(id: number) {
        return this.http.post<void>(`${CUSTOMER_API}/${id}/send-reset-email`,
            {headers: new HttpHeaders().set('Content-Type', 'application/json')});
    }

}
