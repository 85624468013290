import {Pipe, PipeTransform} from '@angular/core';
import {Error} from '../models/error.interface';

@Pipe({
    name: 'field'
})
export class FieldFilter implements PipeTransform {

    transform(errors: Error[], property: string) {
        if (!errors) {
            return null;
        }
        return errors.filter(error => error.property === property);
    }

}
