import {Component, Input} from '@angular/core';
import {DocumentService} from '../../services/document.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {User} from "../../../security/user.interface";
import {Router} from "@angular/router";
import {UserService} from "../../../security/user.service";

@Component({
    selector: 'evd-account-widget',
    templateUrl: 'evd-account-widget.component.html',
    styleUrls: ['evd-account-widget.component.scss']
})
export class EvdAccountWidgetComponent {
    constructor(private documentService: DocumentService,
                private modalService: NgbModal,
                private router: Router,
                private userService: UserService) {
    }

    @Input()
    currentUser: User;
    /* eslint-disable */
    navigateToUserProfile() {
        this.router.navigate(['/ui', 'my-evd', 'accounts', this.currentUser.customers[0].id]);
    }

    logout() {
        this.userService.logoutFromServer();
    }
}
