/*
 * ------------------------------------------------------------------------------------------------
 * Copyright 2014 by Swiss Post, Information Technology Services
 * ------------------------------------------------------------------------------------------------
 */

import {Directive, Host, Optional, Self, SkipSelf, HostListener} from "@angular/core";
import {AbstractControlDirective, ControlContainer, FormGroupDirective, NgControl, FormControlName, SelectControlValueAccessor} from "@angular/forms";

/*
 * Add bootstrap's is-invalid class to invalid fields, by using the same selector used by angular code for classes
 * like ng-invalid.
 *
 * Solution from https://stackoverflow.com/questions/46810764/angular-4-bootstrap-4-consolidating-validation-classes
 * Use base code from angular 7.2 https://github.com/angular/angular/blob/7.2.x/packages/forms/src/directives/ng_control_status.ts
 */

export class EvdAbstractControlStatus {

    constructor(protected cd: AbstractControlDirective,
                private form: FormGroupDirective) {}

    get ngTouched(): boolean { return this.cd.control ? this.cd.control.touched : false; }
    get ngDirty(): boolean { return this.cd.control ? this.cd.control.dirty : false; }
    get ngInvalid(): boolean { return this.cd.control ? this.cd.control.invalid : false; }
    get ngDisabled(): boolean { return this.cd.control ? this.cd.control.disabled : false; }

    get isInvalid() {
        return this.ngInvalid && (this.ngDirty || this.ngTouched || (this.form && this.form.submitted)) && !this.ngDisabled;
    }

    get hasFloatingLabel() {
        if(this.cd instanceof FormControlName) {
            const cd = this.cd;
            if (this.cd.control && this.cd.control.disabled && this.cd.value) {
                return true;
            }
            if (cd.valueAccessor instanceof SelectControlValueAccessor) {
                return this.cd.valid;
            }
        }

        return !!this.cd.value;
    }
}

const controlStatusHost = {
    '[class.is-invalid]': 'isInvalid',
    '[class.hasFloatingLabel]': 'hasFloatingLabel'
};
/* eslint-disable */
@Directive({
    selector: '[formControlName],[ngModel],[formControl]',
    host: controlStatusHost
})

export class ControlStatusDirective extends EvdAbstractControlStatus {
    constructor(
        @Optional() @Host() @SkipSelf() form: FormGroupDirective = null,
        @Self() control: NgControl
    ) {
        super(control, form);
    }

    @HostListener('focus', ['$event.target'])
    onFocus(element) {
        element.classList.add('hasFloatingLabel');
    }

    @HostListener('blur', ['$event.target'])
    onBlur(element) {
        if (!this.cd.value) {
            element.classList.remove('hasFloatingLabel');
        }
    }

}

@Directive({
    selector: '[formGroupName],[formArrayName],[ngModelGroup],[formGroup],form:not([ngNoForm]),[ngForm]',
    host: controlStatusHost
})

export class ControlStatusGroupDirective extends EvdAbstractControlStatus{
    constructor(
        @Optional() @Host() @SkipSelf() form: FormGroupDirective,
        @Self() control: ControlContainer
    ) {
        super(control, form);
    }
}
