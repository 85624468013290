import {Component, OnDestroy, OnInit} from '@angular/core';
import {Customer} from '../../models/customer.interface';
import {CustomerService} from '../../services/customer.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Error} from '../../../notifications/models/error.interface';
import {HttpErrorResponse} from "@angular/common/http";
import {NotificationsService} from "../../../notifications/services/notifications.service";
import {LoadingSpinnerDialogService} from '../../../common/loading-spinner-dialog/loading-spinner-dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from "../../../security/user.service";
import {Carrier} from "../../models/carrier";
import {Subscription} from "rxjs";
import {TealiumUtagService} from "../../../common/tealium-utag.service";

@Component({
    selector: 'evv-customer-create',
    templateUrl: 'customer-create.component.html'
})
/* eslint-disable */
export class CustomerCreateComponent implements OnInit, OnDestroy {
    errors: Error[];
    carriers: Carrier[];
    private readonly subscription: Subscription;

    constructor(private router: Router,
                private customerService: CustomerService,
                private notificationsService: NotificationsService,
                private loadingDialog: LoadingSpinnerDialogService,
                private activatedRoute: ActivatedRoute,
                private translateService: TranslateService,
                private userService: UserService,
                private route: ActivatedRoute,
                private tealiumService: TealiumUtagService
    ) {

        //window.document.querySelector('swisspost-internet-breadcrumbs')['customItems'] = null;
        /*this.subscription = this.translateService.stream('app.customer-care').subscribe(t => {
            window.document.querySelector('swisspost-internet-breadcrumbs')['customItems'] = [{text: t, url: 'ui/cca'}, {
                text: this.translateService.instant('customer.create'),
                url: `ui/cca/customers/create`
            }];
        });*/
    }

    ngOnInit(): void {
        this.carriers = this.userService.getCurrentUser().carriers.map(c => c.name === 'PV' ? {...c, name: 'PVZ'}: c);
    }

    ngOnDestroy(): void {
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }
    /* eslint-disable */
    handleSave(customer: Customer) {
        this.loadingDialog.show();
        this.tealiumService.sendEventWithLocation('click', 'save', 'neues eVD Konto speichern',
            'main', 'unknown', this.translateService.instant("app.title") + '-'  + 'neues eVD Konto speichern');
        this.customerService.createCustomer(customer).subscribe(
            (data) => {
                this.loadingDialog.hide();
                this.router.navigate(['../', data], {relativeTo: this.route});
            }, (response: HttpErrorResponse) => {
                this.loadingDialog.hide();
                this.errors = this.notificationsService.handleHttpError(response);
            },
            () => {
                this.loadingDialog.hide();
            }
        );
    }
    get isKLPLoggedIn() {
        return this.userService.isUserIdpLoggedIn();
    }
}
