import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {CustomerRole, CustomerState} from "../../models/customer.interface";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Carrier} from "../../models/carrier";
import {getCountryKeyValueArray} from "../../../document/models/address.interface";
import {Observable} from "rxjs";
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";

/* eslint-disable */
@Component({
    selector: 'evv-cca-customer-info',
    templateUrl: 'cca-customer-info.component.html',
    styleUrls: ['cca-customer-info.component.css']
})
export class CcaCustomerInfoComponent implements OnInit, OnChanges{

    languages: any;

    @Input()
    parentForm: FormGroup;
    @Input()
    carriers: Carrier[];
    @Input()
    isNewCustomer: boolean;
    @Input()
    roles: CustomerRole[];
    @Input()
    isUserCCAdmin: boolean;
    @Output()
    toggleState: EventEmitter<any> = new EventEmitter<any>();
    states: any;
    countryKeyValueArr: any[];
    constructor(private translateService: TranslateService) {
        this.states = Object.keys(CustomerState).map(k => CustomerState[k as any] as CustomerState);
        this.countryKeyValueArr = getCountryKeyValueArray(this.translateService);
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            if (this.isNewCustomer) {
                this.parentForm.get('number').enable();
                this.parentForm.get('additionalCustomerNumber').enable();
            }
            if (this.isNewCustomer || this.isUserCCAdmin) {
                this.parentForm.get('roleType').enable();
            }
        }
    }
    toggleCustomerState(event) {
        if (event.target.checked === false) {
          this.toggleState.emit(false);
        } else {
          this.toggleState.emit(true);
        }
    }

    get customerState () {
        return this.parentForm.get('state') as FormControl;
    }

    get label4SwitchState() {
        if (this.customerState.value === true) {
            return this.translateService.instant('customer-profile-status.active');
        } else {
            return this.translateService.instant('customer-profile-status.blocked');
        }
    }

    updateRoles() {
        const roleTypeFctrl = this.parentForm.get('roleType');
        roleTypeFctrl.markAsPristine({onlySelf:true});
        roleTypeFctrl.markAsUntouched({onlySelf:true});
        if (this.selectedCarrier && this.selectedCarrier.name === 'GLS' ||
                this.selectedCarrier && this.selectedCarrier.name === 'PVZ') {
            this.roles = Object.keys(CustomerRole).map(k =>  CustomerRole[k as any] as CustomerRole);
            this.parentForm.get('additionalCustomerNumber').setValidators(Validators.required);
            this.parentForm.get('additionalCustomerNumber').updateValueAndValidity();
        } else {
            this.roles = Object.keys(CustomerRole).filter(k => k !== CustomerRole.PARTNER).map(k => CustomerRole[k as any] as CustomerRole);
            if (this.roles.length === 1) {
                roleTypeFctrl.patchValue(this.roles[0]);
            }
            this.parentForm.get('additionalCustomerNumber').clearValidators();
            this.parentForm.get('additionalCustomerNumber').updateValueAndValidity();
        }
    }

    get selectedCarrier() {
        if (this.isNewCustomer && this.parentForm.get('carrier')) {
            return this.parentForm.get('carrier').value;
        }
        return null;
    }

    formatter = (x: {value: string}) => x.value;

    searchCountry = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map(term => term === '' ? []
                : this.countryKeyValueArr.filter(co => co.value.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
        )

}

