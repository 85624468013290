import {Component, OnDestroy, OnInit} from '@angular/core';
import {Customer, CustomerState} from '../../models/customer.interface';
import {CustomerService} from '../../services/customer.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Error} from '../../../notifications/models/error.interface';
import {HttpErrorResponse} from "@angular/common/http";
import {NotificationsService} from "../../../notifications/services/notifications.service";
import {LoadingSpinnerDialogService} from '../../../common/loading-spinner-dialog/loading-spinner-dialog.service';
import {UserService} from '../../../security/user.service';
import {TranslateService} from '@ngx-translate/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ChangePasswordModalComponent} from "../change-password-modal/change-password-modal.component";
import {filter, map, switchMap} from "rxjs/operators";
import {Carrier} from "../../models/carrier";
import {Subscription} from "rxjs";
import {CustomerDeliveryType} from "../../models/customer-shipment-options.interface";
import {TealiumUtagService} from "../../../common/tealium-utag.service";

@Component({
    selector: 'evv-customer-detail',
    styleUrls: ['customer-detail.component.css'],
    templateUrl: 'customer-detail.component.html'
})
export class CustomerDetailComponent implements OnInit, OnDestroy {
    customer: Customer;
    errors: Error[];
    carriers: Carrier[];
    private subscription: Subscription = null;

    constructor(private activatedRoute: ActivatedRoute,
                private userService: UserService,
                private router: Router,
                private customerService: CustomerService,
                private notificationsService: NotificationsService,
                private loadingDialog: LoadingSpinnerDialogService,
                private translateService: TranslateService,
                private modalService: NgbModal,
                private tealiumService: TealiumUtagService) {
    }

    get isCustomerCare(): boolean {
        return this.userService.isUserCustomerCare();
    }

    get isKLPLoggedIn() {
        return this.userService.isUserIdpLoggedIn();
    }
    /* eslint-disable */
    ngOnInit() {
        this.activatedRoute.params.pipe(
            map(params => params['customerId']),
            filter(customerId => 'new' !== customerId),
            switchMap(id => this.customerService.getCustomer(id)),
            map((customer:Customer) => {
                const allCarriers = this.userService.getCurrentUser().carriers.filter(c => c.id === customer.carrId);
                return {
                    customer,
                    allCarriers
                };
            })
        ).subscribe(
            ({customer, allCarriers}) => {
                this.customer = customer;
                this.carriers = allCarriers;
                //this.updateBreadcrumb(customer);
            }
        );
    }

    private updateBreadcrumb(customer) {
        const breadcrumbItems = [];

        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.isKLPLoggedIn) {
            this.subscription = this.translateService.stream('app.title').subscribe(t => {
                if (!this.isCustomerCare) {
                    breadcrumbItems.unshift({
                        text: this.translateService.instant('customer.my-customers'),
                        url: `ui/my-evd/accounts`
                    });
                }
                breadcrumbItems.push({
                    text: this.isKLPLoggedIn ? customer.name : this.translateService.instant('customer.profile'),
                    url: `ui/cca/customers/${customer.customerId}`
                });
                window.document.querySelector('swisspost-internet-breadcrumbs')['customItems'] = breadcrumbItems;
            });
        }
    }

    ngOnDestroy(): void {
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    handleSave(customer: Customer) {
        this.tealiumService.sendEventWithLocation('click', 'save', 'eVD Konto Einstellungen Speichern',
            'main', 'unknown', this.translateService.instant("app.title") + '-'  + 'eVD Konto Einstellungen Speichern');
        this.loadingDialog.show();
        if (this.customer) {
            this.customerService.updateCustomer(this.customer.customerId, customer).subscribe(
                async () => {
                    this.loadingDialog.hide();
                    if (this.isKLPLoggedIn && !this.isCustomerCare) {
                        this.router.navigate(['ui', 'my-evd', 'accounts']);
                    } else {
                        this.router.navigate(['../']);
                    }
                }, (response: HttpErrorResponse) => {
                    this.loadingDialog.hide();
                    this.errors = this.notificationsService.handleHttpError(response);
                }, () => this.loadingDialog.hide()
            );
        }
    }

    openChangePasswordModal() {
        const modalInstance = this.modalService.open(ChangePasswordModalComponent, {size: 'lg'});
        modalInstance.componentInstance.customer = this.customer;
    }

    triggerChangeUserState(event) {
        let customerState = CustomerState.ACTIVE;
        if (!event) {
            customerState = CustomerState.BLOCKED;

        }
        this.customerService.updateCustomerState(this.customer.customerId, customerState)
            .pipe(switchMap(() =>
                this.translateService.get(
                    "change.customer-state.success.onto." + (customerState === CustomerState.ACTIVE ? "active" : "blocked"))))
            .subscribe(
                t => this.notificationsService.showSuccessNotification(t),
                (response: HttpErrorResponse) => this.errors = this.notificationsService.handleHttpError(response)
        );
    }

    triggerSendPasswordEmail() {
        this.customerService.sendResetPasswordEmail(this.customer.customerId)
            .pipe(switchMap(() => this.translateService.get('send.reset-password.success')))
            .subscribe(t => {
                    this.notificationsService.showSuccessNotification(t);
            }, (response: HttpErrorResponse) => this.errors = this.notificationsService.handleHttpError(response)
        );
    }

    isdeliverModeFtp () {
        return this.customer.deliveryType? this.customer.deliveryType === CustomerDeliveryType.FTP : false;
    }

}
