import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {DocumentService} from '../../services/document.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DocumentAnonymousSearchCriteria} from "../../models/document-anonymous-search-criteria.interface";
import {Document} from "../../models/document.interface";
import {ReceiptDetailsModalComponent} from "../receipt-details-modal/receipt-details-modal.component";
import {TealiumUtagService} from "../../../common/tealium-utag.service";
import {DocumentDetail} from "../../models/document-detail.interface";

@Component({
    selector: 'evv-receipt-actions',
    templateUrl: 'receipt-actions.component.html',
    styleUrls: ['receipt-actions.component.scss']
})
export class ReceiptActionsComponent {
    constructor(private documentService: DocumentService,
                private modalService: NgbModal,
                private tealiumService: TealiumUtagService) {
    }

    @Input()
    document: Document;
    @Input()
    availableDocuments: DocumentDetail[];
    @Input()
    customerId: number;
    @Input()
    anonymousCriteria: DocumentAnonymousSearchCriteria;
    @Input()
    showDownload: boolean;
    @Output()
    loadStart: EventEmitter<null> = new EventEmitter<null>();
    @Output()
    loadStop: EventEmitter<null> = new EventEmitter<null>();
    /* eslint-disable */
    openReceiptDetails(receiptId: number) {
        this.loadStart.emit();
        this.documentService.getReceiptDetails(receiptId, this.customerId, this.anonymousCriteria).subscribe(details => {
            this.loadStop.emit();
            const modalInstance = this.modalService.open(ReceiptDetailsModalComponent, {size: 'lg'});
            modalInstance.componentInstance.receiptDetails = details;
            modalInstance.componentInstance.documentId = this.document.receiptId;
            modalInstance.componentInstance.customerId = this.customerId ? this.customerId.toString() : null;
            modalInstance.componentInstance.anonymousCriteria = this.anonymousCriteria;
        });
    }

    @HostListener("click", ["$event"])
    public onListenerTriggered(event: any): void {
        if (event.target.id.indexOf ('receiptDetails_') > -1) {
            let receiptId = event.target.id.substring(event.target.id.indexOf ('_') + 1, event.target.id.length);
            this.tealiumService.link({
                event_category_primaryCategory: 'detailansicht',
                event_attributes_loc: 'main',
                event_eventInfo_cause: 'click',
                event_eventInfo_effect: 'go-to',
                event_attributes_tgtURL : 'unknown',
                event_eventInfo_eventLabel: 'receipt Details with Id: ' + receiptId
            });
        }

    }
}
