import {enableProdMode, LOCALE_ID} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import {setActiveLang} from './app/common/setLang';
import {defineCustomElements} from '@swisspost/internet-header';

import {defineCustomElement as definePostIcon} from '@swisspost/design-system-components/dist/components/post-icon';
/* eslint-disable */
void definePostIcon();
void defineCustomElements();

setActiveLang();

if (environment.production) {
    enableProdMode();
}

const providers = [
    {provide: LOCALE_ID, useValue: sessionStorage.getItem('evv.app.language')}
];

platformBrowserDynamic([providers]).bootstrapModule(AppModule);
