import {Injectable} from '@angular/core';
import {UserService} from "../security/user.service";
import {TranslateService} from "@ngx-translate/core";
import {ClientConfig} from "./client-config.model";
import {SearchCriteria} from "../document/models/document-anonymous-search-criteria.interface";

//import { ContextService } from '../services/context.service';
/* eslint-disable */
@Injectable()
export class TealiumUtagService {
    script_src = '';
    private platformName: string;
    private isDisabled = true;

    // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)

    constructor(protected translateService: TranslateService, protected userService: UserService)  {
        (<any>window).utag_cfg_ovrd = {noview: true};
        (<any>window).utag_data = {};
        (<any>window).digitalData = {};
    }

    // Generic script loader with callback
    getScript(src: string, callback: Function) {
        const d = document;
        const o = { callback: callback || function () { } };
        let s, t;

        if (typeof src === "undefined") {
            return;
        }

        s = d.createElement("script"); s.language = "javascript"; s.type = "text/javascript"; s.async = 1; s.charset = "utf-8"; s.src = src;
        if (typeof o.callback === "function") {
            if (d.addEventListener) {
                s.addEventListener("load", function () { o.callback(); }, false);
            } else {
                // old IE support
                // tslint:disable-next-line:max-line-length
                s.onreadystatechange = function () {
                    if (this.readyState === "complete" || this.readyState === "loaded") {
                        this.onreadystatechange = null; o.callback();
                    }
                };
            }
        }
        t = d.getElementsByTagName("script")[0];
        t.parentNode.insertBefore(s, t);
    }

    // Config settings used to build the path to the utag.js file
    setConfig(config: ClientConfig) {
        if(config && config.instance !== 'klp') {
            return;
        }

        this.isDisabled = false;

        const tealiumConfig = config.tealium;
        // tslint:disable-next-line:max-line-length
        this.script_src = 'https://tags.tiqcdn.com/utag/' + tealiumConfig.account + '/' + tealiumConfig.profile + '/' + tealiumConfig.environment + '/utag.js';
        this.platformName = tealiumConfig.platformName;
    }

    // Data layer is optional set of key/value pairs
    track(tealium_event: string, data?: any) {
        if (this.isDisabled) {
            //console.log("Tealium config not set.");
            return;
        }
        if ((<any>window).utag === undefined) {
            this.getScript(this.script_src, function () {
                (<any>window).utag.track(tealium_event, data);
            });
        } else {
            (<any>window).utag.track(tealium_event, data);
        }
    }

    view(data?: any) {
        if (!this.isDisabled) {
            this.track("view", data);
        }
    }

    link(data?: any) {
        if (!this.isDisabled) {
            this.track("link", data);
        }
    }

    setDigitalData(data: { lang: string, unifiedURL: string }) {
        if (this.isDisabled) {
            return;
        }
        const digitalData =  {
            platform_platformInfo_platformName: 'od_ehr',
            page_pageInfo_language: data.lang,
            page_pageInfo_pageID: window.location.pathname,
            page_pageInfo_pageName: window.document.title,
            page_pageInfo_unifiedPageName: window.document.title,
            page_pageInfo_unifiedURL: data.unifiedURL
        };

        (<any>window).digitalData = digitalData;
        this.view(digitalData);
    }

    /**
     * Sends Tealium events
     *
     * @param cause: the technical reason for the event (click, drag, load, hover, pinch, rotate, scroll, swipe, type)
     * @param effect: the effect who is triggered by the event (add, remove, change, cancel, create, delete, edit...)
     * @param label: unique and readable event description, for example the text of the button
     */
    public sendEvent(cause, effect, label) {
        if (this.isDisabled) {
            return;
        }
        if ((window as any).utag) {
            (window as any).utag.link({
                event_category_primaryCategory: 'evd',
                event_attributes_loc: 'evd',
                event_eventInfo_cause: cause,
                event_eventInfo_effect: effect,
                event_eventInfo_eventLabel: label
            });
        } else {
            //this.logger.logConsoleDebug('Tealium event: ' + cause + effect + label, this.constructor.name);
        }
    }

    /**
     * Sends Tealium events
     *
     * @param cause: the technical reason for the event (click, drag, load, hover, pinch, rotate, scroll, swipe, type)
     * @param effect: the effect who is triggered by the event (add, remove, change, cancel, create, delete, edit...)
     * @param label: unique and readable event description, for example the text of the button
     * @param location: the location of the event
     * @param tgtUrl: target URL
     * @param attribute: attribute
     */
    public sendEventWithLocation(cause, effect, label, location, tgtUrl, attribute) {
        if (this.isDisabled) {
            return;
        }
        this.link({
            event_category_primaryCategory: 'evd',
            event_attributes_loc: location,
            event_eventInfo_cause: cause,
            event_eventInfo_effect: effect,
            event_eventInfo_eventLabel: label,
            event_attributes_tgtURL: tgtUrl,
            event_attributes_subAttr1 : attribute
        });
    }

    public sendProcessSafely(usecase:string, step:string) {
        if (this.isDisabled) {
            return;
        }
        try {
            const data = {
                platform: 'evd',
                platform_platformInfo_platformName: 'od_ehr',
                page_pageInfo_pageID: window.location.pathname,
                page_pageInfo_pageName: window.document.title,
                page_category_primarySegment: this.userType,
                page_category_primaryCategory: 'post',
                page_category_subCategory1: 'services',
                page_category_pageType: window.document.title,
                page_attributes_URL: window.location.href.replace('#', ''),
                page_attributes_virtualPageView: '1',
                conversion_funnel_funnelName: usecase,
                conversion_funnel_funnelStep: step,
                event_category_primaryCategory: 'evd',
                event_attributes_loc: 'loc_' + usecase,
                page_pageInfo_language: this.translateService.currentLang,
                user_profile_profileInfo_type: this.userType,
                user_profile_profileInfo_loginStatus: this.loggedInStatus
            };
           this.view(data);
        } catch (e) {
            console.error('tealium sendProcess failed usecase: ' + usecase + ' step: ' + step + ' error: ' + e, this.constructor.name);
        }
    }

    public sendSingleStep(variant: string, stepLabel: string, searchCriteria: SearchCriteria) {
        try {
            let searchAttributes = searchCriteria != null ? Object.values(searchCriteria) : [];

            const data = {
                platform: 'evd',
                platform_platformInfo_platformName: 'od_ehr',
                page_pageInfo_pageID: window.location.pathname,
                page_pageInfo_pageName: stepLabel,
                page_pageInfo_unifiedPageName : stepLabel,
                page_category_primarySegment: this.userType,
                page_category_primaryCategory: 'post',
                page_category_subCategory1: 'services',
                page_category_pageType: window.document.title,
                page_attributes_URL: window.location.href.replace('#', ''),
                page_attributes_virtualPageView: '1',
                conversion_funnel_funnelName: 'Elektronische Verzollungsdokuments empfangen' + variant,
                conversion_funnel_funnelStep: stepLabel,
                conversion_funnel_funnelType: 'single-step-form',
                conversion_funnel_funnelAttributes: searchAttributes,
                page_pageInfo_language: this.translateService.currentLang,
                user_profile_profileInfo_loginStatus: this.loggedInStatus,
                user_profile_profileInfo_type: this.userType
            };
            this.view(data)
        } catch (e) {
            console.error('Tealium sendSingleStep failed step: ' + stepLabel + ' error: ' + e, this.constructor.name);
        }
    }

    private get userType(): string {
        const user = this.userService.getCurrentUser();
        if (user !== null && user !== undefined) {
            if (this.userService.isUserCustomer()) {
                return 'customer';
            } else if (this.userService.isUserCustomerCare()) {
                return 'customer care user Admin';
            } else {
                return 'guest'
            }
        }
        return '';
    }

    private get loggedInStatus(): string {
        return this.userService.isLoggedIn() ? 'logged_in' : '';
    }
}
