import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CarrierEntity, ServiceType} from "../../../customer/models/carrier";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-carrier-profile-form',
    templateUrl: './carrier-profile-form.component.html'
})
/* eslint-disable */
export class CarrierProfileFormComponent implements OnInit {

    carrierProfileForm: FormGroup;
    serviceTypes: any;
    @Output()
    save: EventEmitter<CarrierEntity> = new EventEmitter<CarrierEntity>();

    @Input()
    private carrier: CarrierEntity;

    constructor(private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute) {

    }

    ngOnInit() {
        this.serviceTypes = Object.keys(ServiceType).map(k => ServiceType[k as any]) ;
        this.carrierProfileForm = this.formBuilder.group({
            name: new FormControl({value: null, disabled: true}),
            numbers: this.formBuilder.array([]),
            senderEmailAddress: new FormControl('', [Validators.required, Validators.email]),
            maxReceiptPerEmail: new FormControl('', Validators.required),
            serviceType: new FormControl('', Validators.required),
            hrImportIgnoredCustomerNumbers: new FormControl(''),
            sameReceiptBySendingNumberPeriodString: new FormControl(''),
            sftpGlsNumbers: new FormControl(''),
            pddpCustNumbers: new FormControl(''),
            eveuIncoterms: new FormControl('')
        });
        this.resetForm();
    }

    handleReset() {
       this.resetForm();
       this.router.navigate(['../'], {relativeTo: this.route});
    }

    resetForm() {
        const numbers = this.carrier.numbers || [];
        this.carrierProfileForm.reset({
            name: this.carrier.name,
            senderEmailAddress: this.carrier.senderEmailAddress,
            maxReceiptPerEmail: this.carrier.maxReceiptPerEmail,
            serviceType: this.carrier.serviceType,
            hrImportIgnoredCustomerNumbers: this.carrier.hrImportIgnoredCustomerNumbers,
            sameReceiptBySendingNumberPeriodString: this.carrier.sameReceiptBySendingNumberPeriodString ? getMonthFromPeriodString(this.carrier.sameReceiptBySendingNumberPeriodString) : null,
            sftpGlsNumbers: this.carrier.sftpGlsNumbers,
            pddpCustNumbers: this.carrier.pddpCustNumbers,
            eveuIncoterms:this.carrier.eveuIncoterms
        });

        this.carrierProfileForm.removeControl('numbers');
        this.carrierProfileForm.addControl('numbers', this.formBuilder.array(numbers.map(n => new FormControl(n))));
    }

    removeNumbers(index: number) {
        const numbers4Carrier = this.carrierProfileForm.get('numbers') as FormArray;
        numbers4Carrier.removeAt(index);
    }

    addNumbers(number4Carrier: string = null) {
        const numbers4Carrier = this.carrierProfileForm.get('numbers') as FormArray;
        numbers4Carrier.push(new FormControl(number4Carrier));
    }


    handleSubmit() {
        if (!this.carrierProfileForm.valid) {
            this.touchFormControls(this.carrierProfileForm);
            return;
        }
        let carrierEntity = <CarrierEntity>this.carrierProfileForm.getRawValue();

        if (this.sameReceiptBySendingNumberPeriodString && this.sameReceiptBySendingNumberPeriodString.value) {
            carrierEntity.sameReceiptBySendingNumberPeriodString = getPeriodString(this.sameReceiptBySendingNumberPeriodString.value);
        }
        this.save.emit(carrierEntity);
    }

    getNumbersControls(): AbstractControl[] {
        return (<FormArray>this.carrierProfileForm.controls['numbers']).controls;
    }

    touchFormControls(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                markControlAsTouched(control);
            } else if (control instanceof FormArray) {
                control.controls.forEach(markControlAsTouched);
            } else if (control instanceof FormGroup) {
                this.touchFormControls(control);
            }
        });
    }

    get sameReceiptBySendingNumberPeriodString() {
        return this.carrierProfileForm.get('sameReceiptBySendingNumberPeriodString') as FormControl;
    }

    get isGLS() {
        return this.carrier.name === 'GLS';
    }

    get isPVZ() {
        return this.carrier.name === 'PVZ';
    }
}

function markControlAsTouched(control: AbstractControl) {
    control.markAsTouched({onlySelf: true});
    control.markAsDirty({onlySelf: true});
}

function getMonthFromPeriodString(periodString: string) {
   const match = periodString.match(/^[P,p]([0-9]+)[M,m]$/);
   if (match) {
       return periodString.split(/[P,p]/)[1].split(/[M,m]/)[0];
   }
   return null;
}

function getPeriodString(month: string) {
    if (month && (/^[0-9]+$/).test(month)) {
        return 'P' + month + 'M';
    }
    return null;
}
