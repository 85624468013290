import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {TableModule} from 'primeng/table';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TableUtilModule} from '../common/table/table-util.module';
import {LoadingSpinnerDialogModule} from '../common/loading-spinner-dialog/loading-spinner-dialog.module';
import {CustomerCareHomeComponent} from './containers/customer-care-home/customer-care-home.component';
import {CustomerModule} from '../customer/customer.module';
import {DocumentModule} from '../document/document.module';
import {NotificationsModule} from "../notifications/notifications.module";
import {AdminModule} from "../admin/admin.module";


@NgModule({
    declarations: [
        CustomerCareHomeComponent
    ],
    imports: [
        FormsModule,
        NotificationsModule,
        CommonModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        CustomerModule,
        DocumentModule,
        TableModule,
        NgbModule,
        TableUtilModule,
        LoadingSpinnerDialogModule,
        AdminModule
    ],
    providers: [
        DatePipe
    ]
})
export class CustomerCareModule {
}
