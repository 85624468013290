import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: 'paginator',
    templateUrl: 'paginator.component.html',
    styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
    @Output()
    buttonClick = new EventEmitter<null>();
    @Input()
    rows: number;
    @Input()
    totalRecords: number;
    @Input()
    index: number;
    @Output()
    changePage = new EventEmitter<number>();
    @Output()
    changeRows = new EventEmitter<number>();

    private _leftbuttonText: string = null;

    sizePerPage = 10;

    get leftButtonText(): string {
        return this._leftbuttonText;
    }

    @Input()
    set leftButtonText(leftButtonText: string) {
        this._leftbuttonText = leftButtonText;
    }

    /* eslint-disable */
    setRows(event: any) {
        this.sizePerPage = event.target.value;
        this.changeRows.emit(event.target.value);

    }

    emitButtonClicked() {
        this.buttonClick.emit();
    }

    changePageClicked(page: number) {
        this.changePage.emit(page);
    }
}
