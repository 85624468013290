// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#customer-care-home-nav.tabs-wrapper {
  background-color: transparent !important;
}

#customer-care-home-nav > ul.tabs > li.nav-item,
#customer-care-home-nav > ul.tabs > li.nav-item > a {
  height: 64px !important;
  font-size: 17px !important;
  font-weight: 200 !important;
  border: none !important;
  border-bottom: 4px solid transparent !important;
  text-decoration: none !important;
}

#customer-care-home-nav > ul.tabs > li.nav-item > a {
  padding: 1.3rem 1.8rem !important;
}

#customer-care-home-nav > ul.tabs > li.nav-item > a.nav-link.active {
  color: #000 !important;
  border-color: #FFCC00 !important;
  font-weight: 200 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/customer-care/containers/customer-care-home/customer-care-home.component.scss","webpack://./../../../EHR-nightly-deploy%20manual/ehr/ehr-web-client/src/app/customer-care/containers/customer-care-home/customer-care-home.component.scss"],"names":[],"mappings":"AAAA;EACI,wCAAA;ACCJ;;ADCA;;EAEI,uBAAA;EACA,0BAAA;EACA,2BAAA;EACA,uBAAA;EACA,+CAAA;EACA,gCAAA;ACEJ;;ADCA;EACI,iCAAA;ACEJ;;ADCA;EACI,sBAAA;EACA,gCAAA;EACA,2BAAA;ACEJ","sourcesContent":["#customer-care-home-nav.tabs-wrapper {\n    background-color: transparent !important;\n}\n#customer-care-home-nav > ul.tabs > li.nav-item,\n#customer-care-home-nav > ul.tabs > li.nav-item > a {\n    height: 64px !important;\n    font-size: 17px !important;\n    font-weight: 200 !important;\n    border: none  !important;;\n    border-bottom: 4px solid transparent  !important;\n    text-decoration: none !important;\n}\n\n#customer-care-home-nav > ul.tabs > li.nav-item > a {\n    padding: 1.3rem 1.8rem !important;\n}\n\n#customer-care-home-nav > ul.tabs > li.nav-item > a.nav-link.active {\n    color: #000 !important;\n    border-color: #FFCC00 !important;\n    font-weight: 200 !important;\n}\n","#customer-care-home-nav.tabs-wrapper {\n  background-color: transparent !important;\n}\n\n#customer-care-home-nav > ul.tabs > li.nav-item,\n#customer-care-home-nav > ul.tabs > li.nav-item > a {\n  height: 64px !important;\n  font-size: 17px !important;\n  font-weight: 200 !important;\n  border: none !important;\n  border-bottom: 4px solid transparent !important;\n  text-decoration: none !important;\n}\n\n#customer-care-home-nav > ul.tabs > li.nav-item > a {\n  padding: 1.3rem 1.8rem !important;\n}\n\n#customer-care-home-nav > ul.tabs > li.nav-item > a.nav-link.active {\n  color: #000 !important;\n  border-color: #FFCC00 !important;\n  font-weight: 200 !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
