import {Component, Input, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../user.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {WrongLoginDialogComponent} from '../../../../common/wrong-login-dialog/wrong-login-dialog.component';
import {AuthenticationFailedError} from "../../../models/authentication-failed-error";
import {HttpErrorResponse} from "@angular/common/http";

const USERNAME_PATTERN="^[A-Za-z0-9]*$";

@Component({
    selector: 'evd-customer-login',
    templateUrl: 'customer-login.component.html',
    styleUrls: ['customer-login.component.scss']
})
/* eslint-disable */
export class CustomerLoginComponent {
    customerData: any = null;

    @Input() targetUrl: string;
    @Input() klpEvdLoginUrl: string;
    isSetNewPasswordFormShown = false;
    loginForm: FormGroup;
    @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
    readonly carriers: [{id: 1, name: "TNT"}, {id: 2, name: "GLS"}, {id: 3, name: "PVZ"}];

    constructor(private userService: UserService,
                private router: Router,
                private translateService: TranslateService,
                private formBuilder: FormBuilder,
                private modalService: NgbModal
    ) {
        this.loginForm = this.formBuilder.group({
            username: new FormControl(null, [Validators.required, Validators.pattern(USERNAME_PATTERN)]),
            password: new FormControl(null, Validators.required)
        });
       // window.document.querySelector('swisspost-internet-breadcrumbs')['customItems'] = null;
    }

    handleCancel() {
        this.formGroupDirective.resetForm();
    }

    handleSubmit(userAuth: any) {
        /*if (!this.loginForm.valid) {
            return;
        }
          const userAuth = this.loginForm.value;*/
        this.handleCustomerLogin(userAuth);
    }

    private handleCustomerLogin(userAuth) {
        this.userService.login(userAuth.username.trim(), userAuth.password.trim()).subscribe(
            () => {
                this.router.navigate([this.targetUrl ? this.targetUrl : '/']);
            },
            (response: HttpErrorResponse) => {
                const authenticationFailedError: AuthenticationFailedError = JSON.parse(response.error) as AuthenticationFailedError;
                if (authenticationFailedError && authenticationFailedError.errorCode === 'EXPIRED') {
                    this.isSetNewPasswordFormShown = true;
                    this.customerData = {username: userAuth.username.trim(), password: userAuth.password.trim()};
                    return;
                }
                this.modalService.open(WrongLoginDialogComponent, {size: 'lg'});
            }
        );
    }

    goToPasswordForgot() {
        this.router.navigate(['ui', 'forgot-password']);
    }


    switchPasswordVisibility(password: HTMLInputElement) {
        password.type = password.type === 'password' ? 'text' : 'password';
    }

    showPasswordIcon(password: HTMLInputElement) {
        return password.type === 'password' ? 'pi-2114' : 'pi-2115';
    }
}
