import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {PasswordRepeatValidator} from "../../../common/password-repeat.validator";
import {CLIENT_CONFIG} from "../../../common/constants";
import {ClientConfig} from "../../../common/client-config.model";

const PASSWORD_PATTERN = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$";
@Component({
    selector: 'evv-password-form',
    styleUrls: ['password-form.component.css'],
    templateUrl: 'password-form.component.html'
})
/* eslint-disable */
export class PasswordFormComponent implements OnInit {
    @Input()
    isCustomerCare?: boolean;
    @Input()
    isResetByToken?: boolean;
    @Input()
    customerNumber?: string;
    @Output()
    save = new EventEmitter();
    @Output()
    cancel = new EventEmitter();
    passwordForm: FormGroup;
    @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

    private readonly isDev: boolean;

    constructor(private formBuilder: FormBuilder, @Inject(CLIENT_CONFIG) config: ClientConfig) {
        this.isDev = config.environment === "dev";
    }

    ngOnInit(): void {
        const passwordValidators = this.isDev ? [Validators.required] : [Validators.required, Validators.pattern(PASSWORD_PATTERN)];
        if(this.isCustomerCare || this.isResetByToken) {
            this.passwordForm = this.formBuilder.group({
                username: new FormControl( this.customerNumber),
                password: new FormControl('', passwordValidators),
                passwordRepeat: new FormControl('', passwordValidators)
            }, {validator: PasswordRepeatValidator('password', 'passwordRepeat')});
        } else {
            this.passwordForm = this.formBuilder.group({
                username: new FormControl( this.customerNumber),
                passwordOld: new FormControl('', [Validators.required]),
                password: new FormControl('', passwordValidators),
                passwordRepeat: new FormControl('', passwordValidators)
            }, {validator: PasswordRepeatValidator('password', 'passwordRepeat')});
        }
    }

    handleSubmit() {
        if(!this.passwordForm.valid) {
            return;
        }
        this.save.emit(this.passwordForm.getRawValue());
    }

    handleReset() {
        this.formGroupDirective.resetForm();
        this.cancel.emit();
    }

    switchPasswordVisibility(password: HTMLInputElement) {
        password.type = password.type === 'password' ? 'text' : 'password';
    }

    showPasswordIcon(password: HTMLInputElement) {
        return password.type === 'password' ? 'pi-2114' : 'pi-2115';
    }
}

