import {Component, Inject} from '@angular/core';
import {UserService} from '../security/user.service';
import {Role} from '../security/role';
import {ActivatedRoute, Router} from '@angular/router';
import {CLIENT_CONFIG} from "../common/constants";
import {ClientConfig} from "../common/client-config.model";

@Component({
    selector: 'evv-home',
    template: ''
})
/* eslint-disable */
export class HomeComponent {
    domain: string;
    customerNr: string;
    documentCategory: string;
    mailpieceNr: string;
    glsCustomerNr: string;
    constructor(private userService: UserService,
                private router: Router,
                private route: ActivatedRoute,
                @Inject(CLIENT_CONFIG) private clientConfig: ClientConfig) {
        const user = this.userService.getCurrentUser();
        if (!user) {
            this.userService.getCurrentUserFromServer().subscribe(u => {
                this.handleRedirect(u.roles);
            }, () => {
                if(clientConfig.instance === 'admin') {
                    this.router.navigate(['ui', 'cca']);
                } else {
                    this.router.navigate(['ui', 'my-evd', 'documents']);
                }
            });
            return;
        }

        this.handleRedirect(user.roles);
    }

    handleRedirect(roles: Role[]) {
        if (roles.includes(Role.EVV_CCA) || roles.includes(Role.EVV_ADMIN)) {
            this.router.navigate(['ui','cca']);
            return;
        } else if (roles.includes(Role.EVV_CUSTOMER) || roles.includes(Role.IDP_KLP)) {
            this.router.navigate(['ui','my-evd', 'documents']);
            return;
        } else {
            this.router.navigate(['ui','document-search']);
            return;
        }
    }
}
