import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {saveAs} from "file-saver";

import {DocumentAnonymousSearchCriteria} from '../models/document-anonymous-search-criteria.interface';
import {DocumentSearchCriteria} from '../models/document-search-criteria.interface';
import {ReceiptDetails} from "../models/receipt-details.interface";
import {Order, Search} from "../../common/search.model";
import {UserService} from "../../security/user.service";
import {DocumentDetail} from "../models/document-detail.interface";
import {Document} from "../models/document.interface";
import {BillDocument} from "../models/bill-document.interface";

/* eslint-disable */
const DOCUMENT_API = 'api/documents';
const ANONYM_RECEIPT_SEARCH_API = 'api/anonym/receiptSearch';
const RECEIPT_CUSTOMER_SEARCH_API = "api/receiptCustomerSearch";

@Injectable()
export class DocumentService {
    private _lastGlobalSearchCriteria: Search<DocumentSearchCriteria> = null;
    private _lastCustomerSearchCriteria: Search<DocumentSearchCriteria> = null;

    constructor(private http: HttpClient, private userService: UserService) {
    }

    private static formatDate(date: Date) {
        return date && !isNaN(date.getTime()) ? date.toISOString() : null;
    }

    get lastGlobalSearchCriteria() {
        return this._lastGlobalSearchCriteria;
    }

    get lastCustomerSearchCriteria() {
        return this._lastCustomerSearchCriteria;
    }

    getDocumentsForTable(index: number,
                         rows: number,
                         sort: string = null,
                         order: Order = null,
                         criteria: DocumentSearchCriteria): Observable<PagedDocuments> {
        this.saveLastSearch(criteria, index, rows, sort, order);

        const searchCriteriaTemp = {
            ...criteria,
            documentTsFrom: DocumentService.formatDate(criteria.documentTsFrom),
            documentTsTo: DocumentService.formatDate(criteria.documentTsTo)
        };

        return this.internalGetDocumenents(index, rows, sort, order, searchCriteriaTemp);
    }

    getDocumentsForTableByDocNumber(index: number,
                                    rows: number,
                                    sort: string = null,
                                    order: Order = null,
                                    criteria: DocumentSearchCriteria): Observable<PagedDocuments> {
        this.saveLastSearch(criteria, index, rows, sort, order);

        const {searchNumber, ...searchCriteriaTemp} = {
            ...criteria,
            documentTsFrom: DocumentService.formatDate(criteria.documentTsFrom),
            documentTsTo: DocumentService.formatDate(criteria.documentTsTo)
        };

        return this.internalGetDocumenents(index, rows, sort, order, searchCriteriaTemp);
    }


    private internalGetDocumenents(index: number, rows: number, sort: string, order: Order, searchCriteria) {
       const params = this.configureAnonymousSearchParams(index, rows, sort, order);
        return this.http.post<PagedDocuments>(RECEIPT_CUSTOMER_SEARCH_API, searchCriteria, {params});
    }

    private saveLastSearch(criteria: DocumentSearchCriteria, index: number, rows: number, sort: string, order: Order) {
        if (criteria.customerId != null) {
            this._lastCustomerSearchCriteria = {criteria, index, rows, sort, order};
        } else {
            this._lastGlobalSearchCriteria = {criteria, index, rows, sort, order};
        }
    }

    getAnonymousDocumentsForTable(index: number,
                                  rows: number,
                                  searchCriteria: DocumentAnonymousSearchCriteria,
                                  sort: string = null,
                                  order: Order = null): Observable<PagedDocuments> {
        const params = this.configureAnonymousSearchParams(index, rows, sort, order);
        const {documentNumber, accessCode, ...tmpSearchCriteria} = searchCriteria;
        return this.http.post<PagedDocuments>(ANONYM_RECEIPT_SEARCH_API, tmpSearchCriteria, {params});
    }

    getAnonymousDocumentsForTableByDocNumber(index: number,
                                  rows: number,
                                  searchCriteria: DocumentAnonymousSearchCriteria,
                                  sort: string = null,
                                  order: Order = null): Observable<PagedDocuments> {
        const params = this.configureAnonymousSearchParams(index, rows, sort, order);
        const {foreignParcelNumber, accessCode, ...tmpSearchCriteria} = searchCriteria;
        return this.http.post<PagedDocuments>(ANONYM_RECEIPT_SEARCH_API, tmpSearchCriteria, {params});
    }

    getAnonymousDocumentsForTableByRandomCode(index: number,
                                rows: number,
                                searchCriteria: DocumentAnonymousSearchCriteria,
                                sort: string = null,
                                order: Order = null): Observable<PagedDocuments> {
        const params = this.configureAnonymousSearchParams(index, rows, sort, order);
        const {consignorPostCode, documentNumber, ...tmpSearchCriteria} = searchCriteria;
        return this.http.post<PagedDocuments>(ANONYM_RECEIPT_SEARCH_API, tmpSearchCriteria, {params});
    }

    getAnonymousDocumentsForTableByDocNrRanCd(index: number,
                                              rows: number,
                                              searchCriteria: DocumentAnonymousSearchCriteria,
                                              sort: string = null,
                                              order: Order = null): Observable<PagedDocuments> {
        const params = this.configureAnonymousSearchParams(index, rows, sort, order);
        const {consignorPostCode, foreignParcelNumber, ...tmpSearchCriteria} = searchCriteria;
        return this.http.post<PagedDocuments>(ANONYM_RECEIPT_SEARCH_API, tmpSearchCriteria, {params});
    }

    getAvailableDocuments(receiptIds: number[],
                          customerId: number = null,
                          searchCriteria: DocumentAnonymousSearchCriteria = null): Observable<{[receiptId: number]: DocumentDetail[]}> {
        if(!receiptIds || receiptIds.length === 0) {
            return of([]);
        }

        let params = receiptIds.reduce((p, value) => p.append("receiptId", value.toString()), new HttpParams());
        let endpoint: string;
        if (searchCriteria) {
            params = DocumentService.addAnonymousParams(params, searchCriteria);
            endpoint = `${DOCUMENT_API}/available`;
        } else if (this.userService.isUserCustomer() || customerId) {
            endpoint = `api/${customerId}/documents/available`;
        } else {
            endpoint = 'api/cca/documents/available';
        }


        return this.http.get<{[receiptId: number]: DocumentDetail[]}>(endpoint, {params});
    }

    private static addAnonymousParams(params: HttpParams, searchCriteria: DocumentAnonymousSearchCriteria) {
        if (searchCriteria.consignorPostCode) {
            params = params.append('zip', searchCriteria.consignorPostCode);
        }
        if (searchCriteria.accessCode) {
            params =params.append('code', searchCriteria.accessCode);
        }
        if (searchCriteria.documentNumber) {
            params = params.append('documentNr', searchCriteria.documentNumber);
        } else {
            params = params.append('sendingNr', searchCriteria.foreignParcelNumber);
        }
        return params;
    }

    getReceiptDetails(receiptId: number, customerId: number, searchCriteria: DocumentAnonymousSearchCriteria): Observable<ReceiptDetails> {
        let params = new HttpParams();
        let endpoint: string;

        if (searchCriteria) {
            params = DocumentService.addAnonymousParams(params, searchCriteria);
            endpoint = `api/documents/${receiptId}`;
        } else if (this.userService.isUserCustomer() || customerId) {
            endpoint = `api/${customerId}/documents/${receiptId}`;
        } else {
            endpoint = `api/cca/documents/${receiptId}`;
        }
        return this.http.get<ReceiptDetails>(endpoint, {params});
    }

    downloadDocumentsZip(receiptIds: number[], customerId: number = null, searchCriteria: DocumentAnonymousSearchCriteria = null): Observable<HttpResponse<Blob>> {
        let params = receiptIds.reduce((p, value) => p.append('receiptId', value.toString()), new HttpParams());
        let endpoint: string;

        if (searchCriteria) {
            params = DocumentService.addAnonymousParams(params, searchCriteria);
            endpoint = 'api/documents/download';
        } else if (this.userService.isUserCustomer() || customerId) {
            endpoint = `api/${customerId}/documents/download`;
        } else {
            endpoint = 'api/cca/documents/download';
        }

        return this.http.get(endpoint, {params, responseType: 'blob', observe: 'response'});
    }


    downloadSingleDocumentById(receiptId: number, documentId: number, customerId: number, searchCriteria: DocumentAnonymousSearchCriteria = null): Observable<HttpResponse<Blob>> {
        let params: HttpParams = new HttpParams();
        let endpoint: string;
        if (searchCriteria) {
            params = DocumentService.addAnonymousParams(params, searchCriteria);
            endpoint =`api/documents/${receiptId}/id/${documentId}/download`;

        } else if (this.userService.isUserCustomer() || customerId) {
            endpoint = `api/${customerId}/documents/${receiptId}/id/${documentId}/download`;
        } else {
            endpoint = `api/cca/documents/${receiptId}/id/${documentId}/download`;
        }

        return this.http.get(endpoint, {
            params,
            responseType: 'blob',
            observe: 'response'
        });
    }

    static saveFile(data: HttpResponse<Blob>) {
        const fileName = data.headers.get('content-disposition').replace('attachment; filename=', '');
        saveAs(data.body, fileName);
    }

    getMonthlyBillDocuments(customerId: number) {
        return this.http.get<BillDocument[]>(`api/customers/${customerId}/bill/documents`);
    }

    loadMonthlyBillContent(monthlyBillId: number, customerId: number) : Observable<HttpResponse<Blob>>  {
        const params: HttpParams = new HttpParams();
        let endpoint: string;
        if (customerId != null) {
            endpoint = `api/customers/${customerId}/bill/${monthlyBillId}/download`;
        } else {
            endpoint = `api/customers/cca/bill/${monthlyBillId}/download`;
        }
        return this.http.get(endpoint, {
            params,
            responseType: 'blob',
            observe: 'response'
        });
    }
    getBillByInvoiceKey(invoiceKey: string, customerId: number) {
        if (customerId != null) {
            return this.http.get<BillDocument[]>(`api/customers/${customerId}/bill/${invoiceKey}`);
        } else {
            return this.http.get<BillDocument[]>(`api/customers/cca/bill/${invoiceKey}`);
        }
    }

    configureAnonymousSearchParams(index: number,
                             rows: number,
                             sort: string = null,
                             order: Order = null) {
        let params = new HttpParams().append("page", String(index)).append("size", String(rows));

        if (sort && order) {
            params = params.append("sort", sort + ',' + order);
        }
        return params;
    }
}

export interface PagedDocuments {
    content: Document[],
    pageable: { pageNumber: number, pageSize: number },
    totalElements: number
}
