import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Component, Input} from "@angular/core";
import {LoadingSpinnerDialogService} from "../../../common/loading-spinner-dialog/loading-spinner-dialog.service";
import {TranslateService} from "@ngx-translate/core";
import {NotificationsService} from "../../../notifications/services/notifications.service";
import {Customer} from "../../models/customer.interface";
import {UserService} from "../../../security/user.service";

/* eslint-disable */
@Component({
    selector: 'evv-change-password-modal',
    templateUrl: 'change-password-modal.component.html',
    styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent {
    @Input()
    customer: Customer;

    constructor(public modal: NgbActiveModal,
                private loadingDialog: LoadingSpinnerDialogService,
                private userService: UserService,
                private translateService: TranslateService,
                private notificationsService:  NotificationsService) {
    }

    get isCustomerCare(): boolean {
        return this.userService.isUserCustomerCare();
    }

    handleSave(event: any) {
        this.updatePassword(event).subscribe(() => {
            this.modal.dismiss();
            this.notificationsService.showSuccessNotification(this.translateService.instant('customer.change-password.success') as string);
        }, () => {
            this.notificationsService.showErrorNotification(this.translateService.instant('customer.change-password.error') as string);
        });
    }
    /* eslint-disable */
    private updatePassword(event: any) {
        if (this.isCustomerCare) { // is no more valid
            return this.userService.updatePasswordCCA(this.customer.customerId, event.password);
        } else {
            return this.userService.updatePassword(this.customer.number, event.passwordOld, event.password);
        }
    }
}
