import {PaginatorComponent} from "./paginator.component";
import {NgModule} from "@angular/core";
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {TableSortIconComponent} from "./table-sort-icon.component";
import {TableModule} from "primeng/table";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        NgbModule,
        TableModule
    ],
    declarations: [
        PaginatorComponent,
        TableSortIconComponent
    ],
    exports: [
        PaginatorComponent,
        TableSortIconComponent
    ]
})
export class TableUtilModule {

}
