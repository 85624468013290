import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {DocumentService} from "../../services/document.service";
import {BillDocument} from "../../models/bill-document.interface";
import {HttpResponse} from "@angular/common/http";
import {LoadingSpinnerDialogService} from "../../../common/loading-spinner-dialog/loading-spinner-dialog.service";
import {NotificationsService} from "../../../notifications/services/notifications.service";
import {CustomerSummary} from "../../../security/user.interface";

@Component({
    selector: 'evv-bill-document-list',
    styleUrls: ['bill-document-list.component.css'],
    templateUrl: 'bill-document-list.component.html'
})
export class BillDocumentListComponent implements OnInit, OnChanges {
    @Input()
    customers?: CustomerSummary[];
    @Input()
    billDocuments?: BillDocument[];
    @Input()
    selectedCustomer: CustomerSummary;
    displayMonthly = true;
    billDocList: BillDocument[];
    displayMore: boolean;
    index = 0;
    itemCountDisplay = 3;
    origin: BillDocument[];

    constructor(private documentService: DocumentService,
                private translateService: TranslateService,
                private loadingDialog: LoadingSpinnerDialogService,
                private notificationsService: NotificationsService) {
    }

    ngOnInit() {
        if (this.billDocuments != null ) {
            this.billDocList = this.billDocuments;
            this.constructDisplayMode(this.billDocList);
        } else {
           this.displayMonthly = false;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.billDocuments && changes.billDocuments.currentValue) {
            /* eslint-disable */
            this.billDocList = changes.billDocuments.currentValue;
            this.constructDisplayMode(this.billDocList);

        }
    }

    showMore() {
        this.billDocList = this.origin;
        this.displayMore = false;
    }

    showLess() {
        this.billDocList = this.origin.slice(0, this.itemCountDisplay);
        this.displayMore = true;
    }

    downloadMonthlyBill(monthlyBillId: number) {
        this.loadingDialog.show();
        //const customer = this.customers != null ?  this.customers[0].id: null;
        const customerId = this.selectedCustomer != null ? this.selectedCustomer.id : this.customers != null ?  this.customers[0].id: null;
        this.documentService.loadMonthlyBillContent(monthlyBillId, customerId)
            .subscribe(content => this.saveFile(content), () => this.showDownloadError(), () => this.loadingDialog.hide());
    }

    private saveFile(file: HttpResponse<Blob>) {
        DocumentService.saveFile(file);
        this.translateService.get('document.download.success').subscribe(t => {
            this.notificationsService.showSuccessNotification(t);
        });
    }

    private showDownloadError() {
        this.translateService.get('document.download.error').subscribe(t => {
            this.notificationsService.showErrorNotification(t);
        });
    }

    private constructDisplayMode(list: BillDocument[]) {
        this.origin = list;
        const sizeOfList = this.origin.length;
        if (sizeOfList  > 0) {
            this.displayMonthly = true;
            if (sizeOfList > this.itemCountDisplay) {
                this.displayMore = true;
                this.billDocList = this.origin.slice(0, this.itemCountDisplay);
            }  else {
                this.displayMore = null;
                this.billDocList = this.origin.slice(0, this.origin.length);
            }
        } else {
            this.displayMonthly = false;
        }
    }

}
