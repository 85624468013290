export enum CustomerDeliveryType {
    MAIL = "MAIL",
    FTP = "FTP"
}

export enum DeliveryPeriod {
    DAILY = "DAILY",
    HOURLY = "HOURLY",
    DISABLED = "DISABLED",
    INCLUDE_INVOICE = "INCLUDE_INVOICE"
}

export enum DeliveryMode {
    ONE_RCPT_PER_EMAIL = "ONE_RCPT_PER_EMAIL",
    COLLECTION_EMAIL = "COLLECTION_EMAIL",
    ONE_RCPT_PER_FTP = "ONE_RCPT_PER_FTP",
    COLLECTION_FTP = "COLLECTION_FTP",
    ONE_RCPT_PER_FTP_ZIP = "ONE_RCPT_PER_FTP_ZIP",
    COLLECTION_FTP_ZIP = "COLLECTION_FTP_ZIP"
}

export interface FTPDeliveryType {
    isDeliverTypeFtp: boolean;
    automaticDeliveryPeriod: DeliveryPeriod;
    automaticDeliveryMode: DeliveryMode;
}
