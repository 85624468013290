import {FormGroup} from "@angular/forms";

export function requiredAtLeastCheckOneValidator(controlNameA: string, controlNameB: string) {

    return (formGroup: FormGroup) => {
        const controlA = formGroup.controls[controlNameA];
        const controlB = formGroup.controls[controlNameB];
        if (!controlA.value &&  (!controlB.value)) {
            return {requiredAtLeastCheckOne: true};
        }
        return null;
    };
}
