export enum EveuDocType {
    PDF_EVEU = "pdf_eveu"
}

export enum ImportDocType {
    CUSTOMS_pdf = "customs_pdf",
    CUSTOMS_VLD = "customs_vld",
    CUSTOMS_XML = "customs_xml",
    VAT_PDF = "vat_pdf",
    VAT_VLD = "vat_vld",
    VAT_XML = "vat_xml",
    HR_PDF = "hr_pdf",
    PR_PDF = "pr_pdf"
}


export enum ExportDocType {
    XML = "xml",
    PDF = "pdf",
    VLD = "vld",
}
