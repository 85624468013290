/*
 * Copyright 2023 by Swiss Post, Information Technology
 */
import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

/* eslint-disable */
@Component({
    selector: 'evd-step-support-gls',
    templateUrl: './step-support-gls.component.html',
    styleUrls: ['./step-support-gls.component.scss']
})
export class StepSupportGlsComponent {


    constructor( private router: Router, private translateService: TranslateService) {
    }

    onButtonYes() {
        this.router.navigate(['ui', 'login', 'customer'], { queryParams:{customerNr: '', domain: 'gls'}});
    }

    onButtonNo() {
        this.router.navigate(['ui', 'login', 'step-support-urgent']);
    }

    /*gotoQrScanner() {
        this.router.navigate(['ui', 'login', 'qr-code-scanner']);
    }*/

    get billGlsSenderImg() {
        const lang = this.translateService.currentLang;
        return 'bill-gls-sender-img-' + lang.toLowerCase();
    }
}



