import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {
    DeliveryMode,
    DeliveryPeriod,
    FTPDeliveryType
} from "../../models/customer-shipment-options.interface";
import {Carrier} from "../../models/carrier";
import {PRICE_LIMIT_ARRAY} from "../../models/customer.interface";
import {requiredAtLeastCheckOneValidator} from "../../../common/required-at-least-check-one.validator";
import {TranslateService} from "@ngx-translate/core";
/* eslint-disable */
@Component({
    selector: 'evv-document-delivery-settings',
    templateUrl: 'document-delivery-settings.component.html',
    styleUrls: ['document-delivery-settings.component.css']
})
export class DocumentDeliverySettingsComponent implements OnInit, OnChanges {

    languages: any;
    automaticDeliveryPeriods: any;
    deliveryModesMail: any;
    priceLimits: any;
    @Input()
    parentForm: FormGroup;
    @Input()
    currentCarrier?: Carrier;
    @Input()
    ftpInfo?: FTPDeliveryType;
    hasOnlyExport: boolean;
    hasCollectivelyDeliMail: boolean;

    constructor(private translateService: TranslateService) {
        this.automaticDeliveryPeriods = Object.keys(DeliveryPeriod).filter(k => k.toUpperCase() !== 'DISABLED').map(k => DeliveryPeriod[k as any] as DeliveryPeriod);
        this.deliveryModesMail = Object.keys(DeliveryMode).filter(k => !k.includes('FTP')).map(k => DeliveryMode[k as any] as DeliveryMode);
        this.priceLimits = PRICE_LIMIT_ARRAY;
    }

    ngOnInit(): void {
        this.parentForm.get("automaticDeliveryEnabled").valueChanges.subscribe(
            value => {
                this.deliveryEmailAddresses.updateValueAndValidity();
            }
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.currentCarrier && changes.currentCarrier.currentValue) {
            this.getHasOnlyExport(changes.currentCarrier.currentValue);
            if (!this.includeInvoice){
                this.automaticDeliveryPeriods = Object.keys(DeliveryPeriod).filter(k => !['INCLUDE_INVOICE', 'DISABLED'].includes( k.toUpperCase())).map(k => DeliveryPeriod[k as any] as DeliveryPeriod);
            }
            if (!this.getHasCollectivelyDeliMail(changes.currentCarrier.currentValue))  {
                this.deliveryModesMail =   this.deliveryModesMail.filter(deli => !deli.includes('COLLECTION_EMAIL'));
            } else {
                this.deliveryModesMail = Object.keys(DeliveryMode).filter(k => !k.includes('FTP')).map(k => DeliveryMode[k as any] as DeliveryMode);
            }
        }
        if (changes && changes.ftpInfo && changes.ftpInfo.currentValue) {
            this.automaticDeliveryEnabled.patchValue(false);
        }
    }

    get deliveryModes() {
        return this.deliveryModesMail;
    }

    toggleAutomaticDelivery(event) {
        const fcDeliveryPeriod = this.parentForm.get('automaticDeliveryPeriod')as FormControl;
        const fcDeliveyMode = this.parentForm.get('automaticDeliveryMode') as FormControl;
        const deliveryPeriod = fcDeliveryPeriod.value;
        if (event.target.checked) {
            if (deliveryPeriod != null && deliveryPeriod.length > 0) {
                if (this.automaticDeliveryPeriods.filter(adp => adp === deliveryPeriod).length > 0) {
                    fcDeliveryPeriod.setValue(deliveryPeriod);
                }
            } else {
                fcDeliveryPeriod.setValue(this.automaticDeliveryPeriods[0]);
            }
        } else {
            while (this.deliveryEmailAddresses.length > 0) {
                this.deliveryEmailAddresses.removeAt(0);
            }
            fcDeliveryPeriod.clearValidators();
            fcDeliveryPeriod.updateValueAndValidity();
            fcDeliveyMode.clearValidators();
            fcDeliveyMode.updateValueAndValidity();
        }
    }

    get automaticDeliveryEnabled () {
       return this.parentForm.get('automaticDeliveryEnabled') as FormControl;
    }
    get label4Switch () {
        if (this.automaticDeliveryEnabled.value === true) {
            return this.translateService.instant('document-delivery-setting.automatic.delivery.enabled');
        } else {
            return this.translateService.instant('document-delivery-setting.automatic.delivery.disabled');
        }
    }

    get deliveryOptionVisible() {
        return !!this.automaticDeliveryEnabled.value;
    }

    getHasOnlyExport(currentCarrier) {
        return this.hasOnlyExport = !!currentCarrier && currentCarrier.serviceType === "EXPORT";
    }

    getHasCollectivelyDeliMail(currentCarrier) {
        return this.hasCollectivelyDeliMail = !!currentCarrier && currentCarrier.maxReceiptPerEmail > 1;
    }


    get deliveryEmailAddresses(): FormArray {
        return this.parentForm.get("deliveryEmailAddresses") as FormArray;
    }

    removeEmailByPosition(index: number) {
        this.deliveryEmailAddresses.removeAt(index);
    }

    addEmailAddress() {
        this.deliveryEmailAddresses.push(new FormGroup({
            email: new FormControl(null, [Validators.required, Validators.email]),
            typeImport: new FormControl(false),
            typeExport: new FormControl(false)}, [requiredAtLeastCheckOneValidator('typeImport', 'typeExport')]
        ));
    }

    get includeInvoice(): boolean {
        return !!this.currentCarrier && this.currentCarrier.name.toUpperCase() === 'GLS';
    }
}
