// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doc-list-text
{
    font-size: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/document/components/bill-document-list/bill-document-list.component.css"],"names":[],"mappings":"AAAA;;IAEI,eAAe;AACnB","sourcesContent":[".doc-list-text\n{\n    font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
