import {NgModule} from "@angular/core";
import {EvvFooterComponent} from "./evv-footer.component";


@NgModule({
    declarations: [
        EvvFooterComponent
    ],
    exports: [
        EvvFooterComponent
    ]
})
export class EvvFooterModule {

}
