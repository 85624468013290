import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Customer, CustomerRole, CustomerState, EmailAddress} from '../../models/customer.interface';
import {
    CustomerDeliveryType,
    DeliveryMode,
    DeliveryPeriod,
    FTPDeliveryType
} from '../../models/customer-shipment-options.interface';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    FormGroupDirective,
    Validators
} from '@angular/forms';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserService} from "../../../security/user.service";
import {Carrier} from "../../models/carrier";
import {getCountryKeyValueArray} from "../../../document/models/address.interface";
import {TranslateService} from "@ngx-translate/core";
import {requiredAtLeastCheckOneValidator} from "../../../common/required-at-least-check-one.validator";
import {requiredIfValidator} from "../../../common/required-If.validator";
/* eslint-disable */
@Component({
    selector: 'evv-customer-form',
    styleUrls: ['customer-form.component.css'],
    templateUrl: 'customer-form.component.html'
})
export class CustomerFormComponent implements OnInit, OnChanges {
    @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

    @Input()
    errors: Error[];
    @Input()
    carriers?: Carrier[];
    @Input()
    klpLoggedIn: boolean;
    @Output()
    save: EventEmitter<Customer> = new EventEmitter<Customer>();
    @Output()
    changePassword: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    changeUserState: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    sendPasswordEmail: EventEmitter<any> = new EventEmitter<any>();
    customerForm: FormGroup;
    isNewCustomer: boolean;
    private _customer: Customer;
    currentCurrId: number;
    roles: CustomerRole[];
    emailList: EmailAddress[];
    ftpDeliveryType: FTPDeliveryType;
    countryKeyValueArr: any[];
    customerId: number;


    constructor(private formBuilder: FormBuilder,
                private router: Router,
                private route: ActivatedRoute,
                private userService: UserService,
                private translateService: TranslateService) {

        this.customerForm = this.formBuilder.group({
            // TODO: temp for Unit Tests, the first two form controls must be set to disabled everytime
            number: new FormControl({value: null, disabled: !this.isNewCustomer
            }, Validators.required),
            additionalCustomerNumber: new FormControl({value: null, disabled: !this.isNewCustomer && !this.isUserCCAdmin
            }),
            roleType: new FormControl({value: '', disabled: !this.isNewCustomer && !this.isUserCCAdmin
            }, Validators.required),
            name: new FormControl('', Validators.required),
            street: new FormControl('', Validators.required),
            postCode: new FormControl('', Validators.required),
            city: new FormControl('', Validators.required),
            contactEmailAddress: new FormControl('', [Validators.email]),
            password: new FormControl(''),
            passwordRepeat: new FormControl(''),
            language: new FormControl('', Validators.required),
            valueVat: new FormControl('', Validators.required),
            valueDuty: new FormControl('', Validators.required),
            automaticDeliveryPeriod: new FormControl( '',
                requiredIfValidator(() => this.customerForm.get("automaticDeliveryEnabled").value)),
            automaticDeliveryMode: new FormControl('',
                requiredIfValidator(() => this.customerForm.get("automaticDeliveryEnabled").value)),
            state: new FormControl(true, Validators.required),
            country: new FormControl(null),
            automaticDeliveryEnabled: new FormControl(true),
            deliveryEmailAddresses: new FormArray([],
                requiredIfValidator(() => this.customerForm.get("automaticDeliveryEnabled").value))

        });
        this.customerForm.get('valueVat').setValue(0);
        this.customerForm.get('valueDuty').setValue(0);
        this.countryKeyValueArr = getCountryKeyValueArray(this.translateService);
    }



    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.customerId =  params['customerId'];
            this.isNewCustomer = params['customerId'] == null;
        });
        if (this.customerForm.controls['valueVat'] && this.customerForm.controls['valueVat'].value) {
            this.customerForm.controls['valueVat'].setValue(5, {onlySelf: true});
        }
        if (this.customerForm.controls['valueDuty'] && this.customerForm.controls['valueDuty'].value) {
            this.customerForm.controls['valueDuty'].setValue(5, {onlySelf: true});
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            if (!this.customer  && this.carriers && this.carriers.length > 0) {
                this.customerForm.addControl('carrier', new FormControl('', Validators.required));
                this.addRoles(this.carriers, true, null);
            } else if (!this.isNewCustomer) {
                this.addRoles(null, false, this.currentCarrier);
            }
            if (this.ftpDeliveryType) {
                this.customerForm.get('automaticDeliveryPeriod').clearValidators();
                this.customerForm.get('automaticDeliveryPeriod').updateValueAndValidity();
                this.customerForm.get('automaticDeliveryMode').clearValidators();
                this.customerForm.get('automaticDeliveryMode').updateValueAndValidity();
            }
        }
    }

    get customer(): Customer {
        return this._customer;
    }

    get deliveryType() {
        return this.customerForm.get("deliveryType");
    }

    @Input()
    set customer(customer: Customer) {
        if (!customer) {
            return;
        }

        this._customer = Object.assign({}, this.customer);

        const deliveryEmailAddressExport = customer.deliveryEmailAddressesExport;
        delete customer.deliveryEmailAddressesExport;
        const deliveryEmailAddressesImport = customer.deliveryEmailAddressesImport;
        delete customer.deliveryEmailAddressesImport;
        const customerState = customer.state;
        const automaticDeliveryMode = customer.automaticDeliveryMode;

        if (automaticDeliveryMode && automaticDeliveryMode.indexOf('FTP') > -1) {
            this.ftpDeliveryType = {"isDeliverTypeFtp": true,
                "automaticDeliveryPeriod": customer.automaticDeliveryPeriod,
                "automaticDeliveryMode": customer.automaticDeliveryMode};
            delete customer.automaticDeliveryMode;
        }
        const automaticDeliveryEnabled = (customer.automaticDeliveryPeriod !== DeliveryPeriod.DISABLED);
        if (!automaticDeliveryEnabled) {
            delete customer.automaticDeliveryPeriod;
        }

        this.customerForm.patchValue(customer, {onlySelf: true});
        const emailList = [];
        if (deliveryEmailAddressesImport && deliveryEmailAddressesImport.length > 0) {
            deliveryEmailAddressesImport.forEach(e => {
                const item = {'email': e, 'typeImport': true, 'typeExport': false} as EmailAddress;
                emailList.push(item);
            });
        }

        if (deliveryEmailAddressExport && deliveryEmailAddressExport.length > 0) {
            deliveryEmailAddressExport.forEach(item => {
               this.addExportAddresses(emailList, item) ;
            });
        }
        this.emailList = emailList;

        this.resetDeliveryEmailAddresses();
        if (this.emailList.length > 0) {
            this.emailList.forEach(emailWithType => {
                this.deliveryEmailAddresses.push(new FormGroup({
                        email: new FormControl(emailWithType.email, [Validators.required, Validators.email]),
                        typeImport: new FormControl(emailWithType.typeImport),
                        typeExport: new FormControl(emailWithType.typeExport)
                    }, [requiredAtLeastCheckOneValidator('typeImport', 'typeExport')]
                ));
            });
        }

        this.addCustomerStateSwitch(customerState);
        this.addAutomaticDeliveryEnabled(automaticDeliveryEnabled);
        this.currentCurrId = customer.carrId;
        const country = customer.country;
        if (country) {
            this.countryKeyValueArr.forEach(ckv => {
                if (ckv.key === country) {
                    const countryCtrl = this.customerForm.get('country') as FormControl;
                    countryCtrl.patchValue(ckv);
                }
            });
        }
    }

    get isCustomerCare(): boolean {
        return this.userService.isUserCustomerCare();
    }

    get isUserCCAdmin(): boolean {
        return this.userService.isUserCCAdmin();
    }

    handleSubmit() {
        if (!this.customerForm.valid) {
            this.validateForm(this.customerForm);
            return;
        }
        const customer = <Customer> this.customerForm.getRawValue();
        customer.number = customer.number.trim();
        if (this.customerForm.controls['carrier'] && this.customerForm.controls['carrier'].value) {
            customer.carrId = this.customerForm.controls['carrier'].value.id;
            delete customer.carrier;
        }
        if (!customer.automaticDeliveryEnabled) {
            if (this.ftpDeliveryType) {
                customer.deliveryType = CustomerDeliveryType.FTP;
                customer.automaticDeliveryPeriod = this.ftpDeliveryType.automaticDeliveryPeriod;
                customer.automaticDeliveryMode = this.ftpDeliveryType.automaticDeliveryMode;
            } else {
                customer.automaticDeliveryPeriod = DeliveryPeriod.DISABLED;
                customer.automaticDeliveryMode = DeliveryMode.ONE_RCPT_PER_EMAIL;
            }
        } else {
            if (customer.automaticDeliveryPeriod == null || customer.automaticDeliveryPeriod.length === 0) {
                customer.automaticDeliveryPeriod = DeliveryPeriod.DAILY;
            }
            if (customer.automaticDeliveryMode == null || customer.automaticDeliveryMode.length === 0) {
                customer.automaticDeliveryMode = DeliveryMode.ONE_RCPT_PER_EMAIL;
            }
            customer.deliveryType = CustomerDeliveryType.MAIL;
        }
        delete customer.automaticDeliveryEnabled;
        customer.deliveryEmailAddressesImport = [];
        customer.deliveryEmailAddressesExport = [];
        if (this.deliveryEmailAddresses) {
            this.deliveryEmailAddresses.controls.forEach(emailFgr  => {
                if (emailFgr.get("typeImport").value) {
                    customer.deliveryEmailAddressesImport.push(emailFgr.get("email").value);
                }
                if (emailFgr.get("typeExport").value) {
                    customer.deliveryEmailAddressesExport.push(emailFgr.get("email").value);
                }
            });
        }
        if (customer.country && customer.country.key) {
            customer.country = customer.country.key;
        }
        this.save.emit(customer);
    }

    handleReset() {
        this.formGroupDirective.resetForm(this._customer);
        if (this.klpLoggedIn && !this.isCustomerCare) {
            this.router.navigate(['ui', 'my-evd', 'accounts']);
        } else {
            this.router.navigate(['../../'], {relativeTo: this.route});
        }
    }

    addCustomerStateSwitch(customerState: string) {
      const fg = this.customerForm.get('state') as FormControl;
      if (customerState === CustomerState.ACTIVE) {
          fg.patchValue(true);
      } else if (customerState === CustomerState.BLOCKED) {
          fg.patchValue(false);
      }
    }

    addAutomaticDeliveryEnabled(automaticDeliveryEnabled: boolean) {
        const ade = this.customerForm.get('automaticDeliveryEnabled') as FormControl;
        ade.patchValue(automaticDeliveryEnabled);
    }


    get currentCarrier() {
        if (this.isNewCustomer && this.carriers && this.carriers.length > 0) {
            return this.customerForm.get('carrier').value;
        } else {
            if (this.currentCurrId) {
                return this.carriers.filter(c => c.id === this.currentCurrId)[0];
            } else {
                return null;
            }
        }
    }

    navigateToChangePassword() {
        this.changePassword.emit('changePassword');

    }

    toggleUserState(event) {
        this.changeUserState.emit(event);
    }

    sendResetPasswordEmail(event) {
        this.sendPasswordEmail.emit(event);
    }

    addRoles(carriers: Carrier[], isNew: boolean, currentCarr: Carrier) {
        const wholeRoles = Object.keys(CustomerRole).map(k =>  CustomerRole[k as any] as CustomerRole);
        const partlyRoles = Object.keys(CustomerRole).filter(k => k !== CustomerRole.PARTNER).map(k => CustomerRole[k as any] as CustomerRole);
        if (!isNew && currentCarr) {
            if (currentCarr.name === 'GLS' || currentCarr.name === 'PVZ') {
                this.roles = wholeRoles;
            } else {
                this.roles = partlyRoles;
            }
        } else if (isNew && carriers) {
            if (carriers.filter(c => (c.name === 'GLS' || c.name === 'PVZ')).length > 0) {
                this.roles = wholeRoles;
            } else {
                this.roles = partlyRoles;
            }
        }
    }

    addExportAddresses(emailList, item) {
        const newList = emailList;
        if (emailList.length === 0) {
            newList.push({'email': item, 'typeImport': false, 'typeExport': true});
            return newList;
        }
        if (emailList.filter (t => t.email === item).length > 0 ) {
            const index = newList.findIndex(b => b.email === item);
            if (index > -1) {
                newList.splice(index, 1, {'email': item, 'typeImport': true, 'typeExport': true});
            }
        }  else {
            newList.push({'email': item, 'typeImport': false, 'typeExport': true});
        }
        return newList;
    }


    validateForm(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
                control.markAsDirty({ onlySelf: true });
                return;
            }

            if (control instanceof FormArray) {
                this.validateControls(control.controls);
                return;
            }

            if (control instanceof FormGroup) {
                this.validateForm(control);
            }
        });
    }

    validateControls(controls: AbstractControl[]) {
        controls.forEach(control => {
            control.markAsTouched({ onlySelf: true });
            control.markAsDirty({ onlySelf: true });
        });
    }

    get deliveryEmailAddresses(): FormArray {
        return  this.customerForm.get("deliveryEmailAddresses") as FormArray;
    }

    resetDeliveryEmailAddresses() {
        while ( this.deliveryEmailAddresses.length > 0 ) {
            this.deliveryEmailAddresses.removeAt(0);
        }
    }

}
