/*
 * ------------------------------------------------------------------------------------------------
 * Copyright 2014 by Swiss Post, Information Technology Services
 * ------------------------------------------------------------------------------------------------
 */

import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from "@angular/core";
import {HttpClient, HttpErrorResponse, HttpParams} from "@angular/common/http";
import {PagedData} from "../../../common/spring-data/paged-data";
import {map, tap} from "rxjs/operators";
import {Page} from "../../../common/spring-data/page";
import {SortEvent} from "primeng/api";
import {FormBuilder, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {DateValidityValidator} from "../../../common/date-validity.validator";
import {Carrier} from "../../../customer/models/carrier";
import {LoadingSpinnerDialogService} from "../../../common/loading-spinner-dialog/loading-spinner-dialog.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NotificationsService} from "../../../notifications/services/notifications.service";
import {DocumentCategory, Metadata, ImportRequestStatus} from "../../model/metadata.interface";

/* eslint-disable */
function dateToStruck(date: Date) {
    if (date) {
        return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
        };
    } else {
        return null;
    }
}

export function struckToDate(dateStruck: { year: number, month: number, day: number }) {
    return dateStruck ? new Date(dateStruck.year, dateStruck.month - 1, dateStruck.day) : null;
}

@Component({
    selector: 'evd-assist-metadata',
    templateUrl: 'assist-metadata.component.html',
    styles: [
        'tr>td.description {white-space: normal}'
    ]
})
export class AssistMetadataComponent implements OnChanges {
    @Input() public carriers: Carrier[];
    @Input() currentCarrier: Carrier;
    @Output() currentCarrierChange = new EventEmitter<Carrier>();

    public result: { metadataList: Metadata[], page: Page } = null;
    private pageSize = 10;
    private pageNumber = 0;
    sortField: string;
    sortOrder: number;
    metadataSearchForm: FormGroup;
    documentCategories: DocumentCategory[];
    stati: ImportRequestStatus[];
    carriers4MetaData: Carrier[];
    @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
    @ViewChild('submitButton') submitButton: ElementRef<HTMLButtonElement>;

    private searchCriteria: {
        carrierId: number,
        beginTimestamp: Date,
        endTimestamp: Date,
        documentCategory: string,
        status: string,
        sendingNumber: string
    };
    private readonly INITIAL_VALUE;

    constructor(private formBuilder: FormBuilder,
                private loadingDialog: LoadingSpinnerDialogService,
                private httpClient: HttpClient,
                private modalService: NgbModal,
                private notificationsService: NotificationsService) {
        const beginTimestamp = new Date();
        beginTimestamp.setDate(beginTimestamp.getDate() - 7);
        this.metadataSearchForm = formBuilder.group({
            'carrier': [null],
            'documentCategory': [null],
            'status': [null],
            'beginTimestamp': [dateToStruck(beginTimestamp), [Validators.required]],
            'endTimestamp': [dateToStruck(new Date()), [Validators.required]],
            'sendingNumber': [null]
        }, {validators: [DateValidityValidator('beginTimestamp', 'endTimestamp')]});
        this.INITIAL_VALUE = this.metadataSearchForm.getRawValue();
        this.searchCriteria = formToCriteria(this.metadataSearchForm.getRawValue());
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.documentCategories = Object.keys(DocumentCategory).map(d => DocumentCategory[d as any] as DocumentCategory);
        this.stati = Object.keys(ImportRequestStatus).map(s=> ImportRequestStatus[s as any] as ImportRequestStatus);
        if(changes.currentCarrier && changes.currentCarrier.currentValue) {
            this.metadataSearchForm.patchValue({carrier: this.currentCarrier});
            this.carriers4MetaData = this.carriers.filter(carr => carr.name != 'TNT');
            if(changes.currentCarrier.firstChange) {
                this.searchCriteria = formToCriteria(this.metadataSearchForm.getRawValue());
                this.loadMetadataList();
            }
        } else if (changes.carriers && changes.carriers.currentValue) {
            const carrs = changes.carriers.currentValue as Carrier[];
            this.carriers4MetaData = carrs.filter(carr => carr.name != 'TNT');
            this.metadataSearchForm.patchValue({carrier: this.carriers4MetaData[0]});
            if(changes.carriers.firstChange) {
                this.searchCriteria = formToCriteria(this.metadataSearchForm.getRawValue());
                this.loadMetadataList();
            }
        }
    }

    private loadMetadataList() {
        let params = new HttpParams()
            .set("pageNumber", this.pageNumber.toString())
            .set("pageSize", this.pageSize.toString());

        if (this.sortField) {
            params = params.set("sortField", this.sortField);
        }
        if (this.sortOrder != null) {
            params = params.set("sortAscending", this.sortOrder > 0 ? "false" : "true");
        }


        this.loadingDialog.show();
        this.httpClient.post<PagedData>(`api/metadataList`, this.searchCriteria, {params})
            .pipe(
                map((paged: PagedData) => (paged && {
                    "metadataList": (paged._embedded && paged._embedded['metadataList'] || []) as Metadata[],
                    "page": paged.page
                })),
                tap(paged => {
                    this.pageSize = paged.page.size;
                    this.pageNumber = paged.page.number;
                })
            )
            .subscribe(r => {
                this.result = r;
                this.loadingDialog.hide();
            }, (response: HttpErrorResponse) => {
                this.loadingDialog.hide();
                this.notificationsService.handleHttpError(response);
            }, () => {
                this.loadingDialog.hide();
            });
    }

    changePage(page: number) {
        this.pageNumber = page - 1;
        this.loadMetadataList();
    }

    changeRows(rows: number) {
        this.pageSize = rows;
        this.loadMetadataList();
    }

    onSort($event: SortEvent) {
        if (this.sortField != $event.field || this.sortOrder != $event.order) {
            this.sortField = $event.field;
            this.sortOrder = $event.order;
            this.pageNumber = 0;
            this.loadMetadataList();
        }
    }

    onSubmit() {
        this.submitButton.nativeElement.blur();
        let formValue = this.metadataSearchForm.getRawValue();
        if(formValue.carrier != this.currentCarrier) {
            this.currentCarrier = formValue.carrier;
            this.currentCarrierChange.emit(this.currentCarrier);
        }
        this.searchCriteria = formToCriteria(formValue);
        this.loadMetadataList();
    }

    onReset(resetButton: HTMLButtonElement) {
        resetButton.blur();
        this.formGroupDirective.resetForm(this.INITIAL_VALUE);
        this.searchCriteria = formToCriteria(this.INITIAL_VALUE);
        this.loadMetadataList();
    }


    getCarrierName (carrId: number) {
        let carr = this.carriers.filter(c => c.id === carrId)[0];
        return carr == null ? '': carr.name;
    }


    get showCarrier() {
        return !this.currentCarrier;
    }

    getCarrierIcon(carrId: number){
        const carrName = this.getCarrierName(carrId);
        switch(carrName) {
            case 'GLS':
                return 'gls-icon';
            case 'PVZ':
                return 'pvz-icon';
            case 'TNT':
                return 'tnt-icon';
            default:
                return null;

        }
    }
}

function formToCriteria(formValue: any) {
    const {carrier, beginTimestamp: beginTS, endTimestamp: endTS, ...value} = formValue;
    return {
        ...value,
        carrierId: carrier && carrier.id,
        beginTimestamp: struckToDate(beginTS),
        endTimestamp: struckToDate(endTS)
    };
}
