import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Error} from '../../../notifications/models/error.interface';
import {NotificationsService} from "../../../notifications/services/notifications.service";
import {UserService} from '../../../security/user.service';
import {AdminService} from "../../service/admin.service";
import {CarrierEntity} from "../../../customer/models/carrier";
import {LoadingSpinnerDialogService} from "../../../common/loading-spinner-dialog/loading-spinner-dialog.service";
import {HttpErrorResponse} from "@angular/common/http";
import {map, switchMap} from "rxjs/operators";

@Component({
    selector: 'app-carrier-profile-detail',
    templateUrl: 'carrier-profile-detail.component.html'
})
/* eslint-disable */
export class CarrierProfileDetailComponent implements OnInit {
    carrier: CarrierEntity;
    errors: Error[];

    constructor(private activatedRoute: ActivatedRoute,
                private userService: UserService,
                private router: Router,
                private adminService: AdminService,
                private notificationsService: NotificationsService,
                private loadingDialog: LoadingSpinnerDialogService,
                private route: ActivatedRoute
    ) {
    }


    ngOnInit() {
        this.activatedRoute.params.pipe(
            map(params => +params['carrierId']),
            switchMap(id => this.adminService.getCarrierProfile(id)),
            map(ca => ca.name === 'PV' ? {...ca, name: 'PVZ'}: ca))
            .subscribe(r =>
                this.carrier = r);
    }

    handleSave(carrier: CarrierEntity) {
        this.loadingDialog.show();
        if (this.carrier) {
            this.adminService.updateCarrier(this.carrier.carrierId, carrier).subscribe(() => {
                    this.loadingDialog.hide();
                    this.router.navigate(['../'], {relativeTo: this.route});
                }, (response: HttpErrorResponse) => {
                    this.loadingDialog.hide();
                    this.errors = this.notificationsService.handleHttpError(response);
                }, () => {
                    this.loadingDialog.hide();
                });
        }
    }
}


