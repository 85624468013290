import {FormGroup} from "@angular/forms";

/* eslint-disable */
export function structToDate(dateStruct: {year: number, month: number, day:number}) {
    return dateStruct ? new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day) : null;
}

export function DateValidityValidator(dateFrom: string, dateTo: string) {
    return (formGroup: FormGroup) => {
        const dateFromControl = formGroup.controls[dateFrom];
        const dateToControl = formGroup.controls[dateTo];

        if (dateFromControl.errors && !dateFromControl.errors.invalidDate || dateToControl.errors && !dateToControl.errors.invalidDate) {
            return;
        }

        // set error on matchingControl if validation fails
        if (structToDate(dateFromControl.value) > structToDate(dateToControl.value)) {
            dateToControl.setErrors({invalidDate: true});
        } else {
            dateToControl.setErrors(null);
        }
    };
}

