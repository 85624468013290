// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fancy-document-image {
  border-style: solid;
  border-color: grey;
}

.image-container {
  display: flex;
  align-items: center;
  align-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/entry-page/components/step-support/step-support.component.scss","webpack://./../../../EHR-nightly-deploy%20manual/ehr/ehr-web-client/src/app/entry-page/components/step-support/step-support.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,kBAAA;ACCJ;;ADEA;EACI,aAAA;EACA,mBAAA;EACA,qBAAA;ACCJ","sourcesContent":[".fancy-document-image {\n    border-style: solid;\n    border-color: grey;\n}\n\n.image-container{\n    display:flex;\n    align-items: center;\n    align-content: center;\n}\n\n",".fancy-document-image {\n  border-style: solid;\n  border-color: grey;\n}\n\n.image-container {\n  display: flex;\n  align-items: center;\n  align-content: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
