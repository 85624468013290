/*
 * Copyright 2023 by Swiss Post, Information Technology
 */
import {Component, Input} from "@angular/core";

@Component({
    selector: 'evd-urgent-login-form',
    templateUrl: './urgent-login-form.component.html'
})
/* eslint-disable */
export class UrgentLoginFormComponent {
    @Input()
    inTab: boolean;
}

