/*
 * ------------------------------------------------------------------------------------------------
 * Copyright 2014 by Swiss Post, Information Technology Services
 * ------------------------------------------------------------------------------------------------
 */

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

import {Observable, of, Subscription} from "rxjs";

import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {TealiumUtagService} from "../../common/tealium-utag.service";
import {TranslateService} from "@ngx-translate/core";
import {LanguageSelectionService} from "../../security/language.selection.service";
import {HttpErrorResponse} from "@angular/common/http";
import {AuthenticationFailedError} from "../../security/models/authentication-failed-error";
import {WrongLoginDialogComponent} from "../../common/wrong-login-dialog/wrong-login-dialog.component";
import {UserService} from "../../security/user.service";
import {map, switchMap} from "rxjs/operators";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {step} from "../entry-page.model";
import {getQueryParamFromMalformedURL} from "../../common/urlParameter";


const USERNAME_PATTERN="^[A-Za-z0-9]*$";
const GLS_DOCUMENT_CATEGORY_ARRAY = ['GLS_PR_PDF', 'HR', ' PDF_EVEU'];
const PVZ_DOCUMENT_CATEGORY_ARRAY = ['PR_PDF', 'OS_JPG', 'OS_GIF', 'OS_PNG', 'OS_PDF', 'PVZ_HR_PDF', 'DP_PDF'];

@Component({
    selector: 'evd-customer-login-process',
    templateUrl: 'customer-login-process.component.html',
    styleUrls: ['./customer-login-process.component.scss']
})
/* eslint-disable */
export class CustomerLoginProcessComponent implements OnInit, OnDestroy {
    @Input()
    domainFromTab: string;
    @Input()
    inTab: boolean;
    customerloginForm: FormGroup;
    targetUrl: string;
    isSetNewPasswordFormShown = false;
    customerData: any = null;
    private languageSelSub: Subscription;
    targetUrl$: Observable<string>;
    private loggedInSubscription: Subscription;
    customerNr: string;
    domain: string
    documentCategory: string;
    lang$: Observable<string>;
    glsCustomerNr: string;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private formBuilder: FormBuilder,
                private tealiumService: TealiumUtagService,
                private translateService: TranslateService,
                private languageSelectService: LanguageSelectionService,
                private userService: UserService,
                private modalService: NgbModal) {
        this.customerloginForm = this.formBuilder.group({
            username: new FormControl(null, [Validators.required, Validators.pattern(USERNAME_PATTERN)] ),
            password: new FormControl(null, Validators.required)
        }, {updateOn: 'blur'} );
    }
    ngOnInit(): void {
        this.languageSelSub = this.languageSelectService.getSelectedLanguage().subscribe(selLang =>{
            this.lang$ = selLang != null ? of(selLang) : of(this.translateService.currentLang);
        });

        this.route.queryParams.subscribe(
            params => {
                let domainFromNeedHelp  = params['domain'];
                this.customerNr=params['customerNr'] || getQueryParamFromMalformedURL('customerNr', this.router.url);
                this.glsCustomerNr=params['glsCustomerNr'] || getQueryParamFromMalformedURL('glsCustomerNr', this.router.url);

                /**
                 * todo hotfix for assist.Delete it if assist using new url.
                 */
                this.documentCategory=params['documentCategory'] || getQueryParamFromMalformedURL('documentCategory', this.router.url);

                if (this.customerNr != null) {
                    const customerNr = this.customerNr.replace(/:/, '');
                    this.username.setValue(customerNr.trim());
                } else if (this.glsCustomerNr != null) {
                    const glsCustomerNr = this.glsCustomerNr.replace(/:/, '');
                    this.username.setValue(glsCustomerNr.trim());
                }

                if (domainFromNeedHelp != null) {
                    this.domain = domainFromNeedHelp.trim();
                    return;
                }
                let domainFromCategory =  this.getDomainFromDocumentCategory(this.documentCategory);

                if(this.glsCustomerNr != null) {
                    this.domain = 'GLS';
                } else {
                    this.domain = this.domainFromTab || domainFromCategory || null;
                    if (this.customerNr != null) {
                        this.domain = 'PVZ';
                    }
                }
            }
        );

        this.targetUrl$ = this.route.queryParams.pipe(
            map(params => params['targetUrl'])
        );

        this.loggedInSubscription = this.userService.isLoggedIn$().pipe(
            switchMap(loggedIn => this.targetUrl$.pipe(map(targetUrl => ({loggedIn, targetUrl}))))
        ).subscribe(({loggedIn, targetUrl}) =>{
            if(loggedIn) {
                // the user is already logged in
                if (!targetUrl) {
                    if (this.userService.isUserCustomer()) {
                        targetUrl = '/ui/my-evd';
                    } else if (this.userService.isUserCustomerCare()) {
                        targetUrl = '/ui/cca';
                    } else if (this.userService.isUserKlp()) {
                        targetUrl = '/ui/my-evd/accounts/link-account';
                    } else {
                        targetUrl = '/';
                    }
                }
                this.router.navigate([targetUrl]);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.languageSelSub) {
            this.languageSelSub.unsubscribe();
        }
    }

    handleSubmit() {
        if (!this.customerloginForm.valid) {
            if (this.username.hasError('pattern')) {
                const normalizedUsername =this.username.value.trim().replace(/[^A-Za-z0-9]/g, '');
                this.username.setValue(normalizedUsername);
            } else {
                return;
            }
        }
        const userAuth = this.customerloginForm.value;
        this.handleCustomerLogin(userAuth);
    }

    private handleCustomerLogin(userAuth) {
        this.userService.login(userAuth.username, userAuth.password.trim()).subscribe(
            () => {
                this.router.navigate([this.targetUrl ? this.targetUrl : '/']);
            },
            (response: HttpErrorResponse) => {
                const authenticationFailedError: AuthenticationFailedError = JSON.parse(response.error) as AuthenticationFailedError;
                if (authenticationFailedError && authenticationFailedError.errorCode === 'EXPIRED') {
                    this.isSetNewPasswordFormShown = true;
                    this.customerData = {username: userAuth.username.trim(), password: userAuth.password.trim()};
                    return;
                }

                const modalRef = this.modalService.open(WrongLoginDialogComponent, {size: 'lg'});
                modalRef.componentInstance.domain = this.domain;
            }
        );
    }
    goToPasswordForgot() {
        this.router.navigate(['ui', 'forgot-password']);
    }


    switchPasswordVisibility(password: HTMLInputElement) {
        password.type = password.type === 'password' ? 'text' : 'password'
    }

    showPasswordIcon(password: HTMLInputElement) {
        return password.type === 'password' ? 'pi-2114' : 'pi-2115';
    }

    reset() {
        this.customerloginForm.reset({
            username: null,
            password: null
        });
    }

    get labelForCustomerNummer(): string {
        if (this.domainGLS) {
            return this.translateService.instant("customer-login-form.customer-number.label-gls");
        } else if (this.domainPVZ) {
            return this.translateService.instant("customer-login-form.customer-number.label-pvz");
        } else if ("urgent".toLowerCase() == this.domain) {
            return this.translateService.instant("customer-login-form.customer-number.label-urgent");
        }
    }

    get domainGLS () : boolean {
        return this.domain && ("gls" === this.domain.toLowerCase());
    }

    get domainPVZ () : boolean {
        return this.domain && ("pvz" === this.domain.toLowerCase());
    }

    get username() : FormControl {
        return this.customerloginForm.get("username") as FormControl;
    }

    get formName() : string {
        if (this.domainGLS) {
            return step.GLS_LOGIN;
        }
        if (this.domainPVZ) {
            return step.PVZ_LOGIN;
        }
        return step.URGENT_LOGIN;
    }

    getImageClass(imgSuffix: string) {
        const lang = this.translateService.currentLang;
        return imgSuffix + lang.toLowerCase();
    }

    getDomainFromDocumentCategory(documentCategory: string) {
        if (documentCategory == null) {
            return null;
        }
        if(PVZ_DOCUMENT_CATEGORY_ARRAY.filter(d => d.toUpperCase() === documentCategory.trim().toUpperCase()).length > 0) {
            return 'PVZ';
        } else if (GLS_DOCUMENT_CATEGORY_ARRAY.filter(d => d.toUpperCase() === documentCategory.trim().toUpperCase()).length > 0) {
            return 'GLS';
        }
    }
}
