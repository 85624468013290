import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject, Observable, of} from 'rxjs';

import {WrongLoginDialogComponent} from '../common/wrong-login-dialog/wrong-login-dialog.component';
import {UserCustomerInfo} from '../customer/models/user-customer-info';
import {CustomerLoginResult} from './models/customer-login-result';
import {User} from './user.interface';
import {catchError, flatMap, map, tap} from "rxjs/operators";
import {Role} from './role';

@Injectable()
/* eslint-disable */
export class UserService {

    private user: User;
    private _onUserChange: BehaviorSubject<User> = new BehaviorSubject(null);
    public onUserChange(): Observable<User> {
        return this._onUserChange.asObservable();
    }

    constructor(private httpClient: HttpClient,
                private router: Router,
                private modalService: NgbModal) {
    }

    private _loginUrl: string;

    get loginUrl(): string {
        return this._loginUrl;
    }

    set loginUrl(loginUrl: string) {
        this._loginUrl = loginUrl;
    }

    /*simulateUserLoggedInKLP() {
        const user: User = {customers: null, carriers: null, email: 'igor.giovannini@post.ch', username: 'klpidblabla', firstname: 'Igor', lastname: 'Giovannini', roles: [Role.IDP, Role.IDP_KLP]};
        this.user = user;
        this._onUserChange.next(user);
    }*/

    logoutFromServer() {
        this.httpClient.post(`auth/logout`, null).subscribe(() => {
            this.router.navigate(['.']);
            this.user = null;
            this._onUserChange.next(null);
        });
    }

    getUserCustomers(): Observable<UserCustomerInfo[]> {
        return this.httpClient.get<UserCustomerInfo[]>(`api/user/customers`);
    }

    getCurrentUserFromServer(): Observable<User> {
        return this.httpClient.get<User>('api/user').pipe(tap(user => {
            this.user = user;
            this._onUserChange.next(user);
        }));
    }

    isUserCustomerCare(): boolean {
        return this.userHasAnyRole([Role.EVV_ADMIN, Role.EVV_CCA]);
    }

    private userHasAnyRole(neededRoles: Role[]) {
        return this.user && this.user.roles && this.hasOneOfNeededRoles(this.user.roles, neededRoles);
    }

    isUserCCAdmin() {
        return this.userHasAnyRole([Role.EVV_ADMIN, Role.EVV_CCA_ADMIN]);
    }

    isUserCustomer(): boolean {
        return this.userHasAnyRole([Role.EVV_CUSTOMER]);
    }

    // refactor, see isKLPLoggedIn...
    isUserIdpLoggedIn(): boolean {
        return this.user && this.user.roles && this.user.roles.includes(Role.IDP_KLP);
    }

    /**
     * Add a customer to KLP session
     * @param customerNumber
     * @param password
     */
    loginWithKLP(customerNumber: string, password: string): Observable<CustomerLoginResult> {
        const headers = new HttpHeaders({'Content-Type': 'application/json'});
        const body = {
            username: customerNumber,
            password
        };

        return this.httpClient.post<CustomerLoginResult>('api/user/customers', body, {headers})
            .pipe(
                flatMap((result) => this.refeshUser()
                    .pipe(map(() => result)))
            );
    }

    unlinkCustomer(customerId: number) {
        return this.httpClient.delete(`api/user/customers/${customerId}`)
            .pipe(
                flatMap((result) => this.refeshUser()
                    .pipe(map(() => result)))
            );
    }

    private refeshUser() {
        this.logout();
        return this.getCurrentUserFromServer();
    }

    linkCustomer(customerId: number) {
        return this.httpClient.put(`api/user/customers/${customerId}/permanent`, null);
    }

    neverAskLink(customerId: number, targetUrl: string) {
        return this.httpClient.put(`api/user/customers/${customerId}/never-ask`, null).subscribe(
            () => {
                this.router.navigate([targetUrl ? targetUrl : '/']);
            },
            () => {
                this.modalService.open(WrongLoginDialogComponent, {size: 'lg'});
            }
        );
    }

    login(username: string, password: string): Observable<any> {
        // logout first
        this.logout();
        return this.callLogin(username, password);
    }

    updatePassword(username: string, oldPassword: string, newPassword: string): Observable<any> {
        return this.httpClient.post('auth/update-password', {
            customerNumber: username,
            oldPassword: oldPassword,
            newPassword: newPassword
        });
    }

    updatePasswordCCA(customerId: number, password: string): Observable<any> {
        return this.httpClient.put(`api/customers/${customerId}/password`, {password});
    }

    logout() {
        this.user = null;
        this._onUserChange.next(null);
    }

    isLoggedIn(): boolean {
        return !!this.user;
    }

    /**
     * like isLoggedIn but check with server if needed
     * @see isLoggedIn
     */
    isLoggedIn$(): Observable<boolean> {
        return this.getCurrentUser$().pipe(map(u => !!u));
    }

    getCurrentUser(): User {
        return this.user;
    }

    /**
     * if already logged in return the current user, otherwise check with the server
     * @see getCurrentUserFromServer
     */
    getCurrentUser$(): Observable<User> {
        return this.isLoggedIn() ? of(this.user) : this.getCurrentUserFromServer().pipe(
            catchError(() => of<User>(null))
        );
    }

    canActivate(neededRoles: string[]): Observable<boolean> {
        return this.getCurrentUser$().pipe(
            map(user => user && this.hasOneOfNeededRoles(user.roles, neededRoles))
        );
    }

    sendForgotPassword(username: string, email: string) {
        const forgotPasswordRequest = {customerNumber: username, email: email};
        return this.httpClient.post('auth/forgot-password', forgotPasswordRequest);
    }

    resetPassword (token: string, password: string) {
        const resetPasswordRequest = {token: token, password: password};
        return this.httpClient.post('auth/reset-password', resetPasswordRequest);
    }


    private callLogin(username, password) {
        const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
        const body = new HttpParams()
            .set('username', username)
            .set('password', password);

        return this.httpClient.post('auth/login', body, {headers, responseType: 'text'});
    }

    private hasOneOfNeededRoles(userRoles: string[], neededRoles: string[]) {
        return neededRoles.findIndex(role => userRoles.includes(role)) !== -1;
    }

    isUserKlp() {
        return this.userHasAnyRole([Role.IDP_KLP]);
    }

    isForgotPasswordCustomerExisted(username: string) {
        const forgotPasswordRequest = {customerNumber: username};
        return this.httpClient.post('auth/verify-customer', forgotPasswordRequest);
    }

    isForgotPasswordEmailValid(username: string, email: string) {
        return this.httpClient.post('auth/verify-email', {customerNumber: username});
    }

    addTntCcUser(klpProfileId: number) {
        return this.httpClient.put<void>(`api/user/tntCCUser/${klpProfileId}`,{headers: new HttpHeaders().set('Content-Type', 'application/json')});
    }
}
