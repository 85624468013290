// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-info {
    padding: 1rem;
    border: 1px solid #e5e5e5;
}

.bold_text {
    font-weight: bold;
    display: inline-block;
    margin-bottom: 0.5rem;
    font-size: 1.0625rem;
}
.customer-name {
    font-size: 1.3rem;
    font-weight: 700;
    padding-bottom: 1rem;
}

.customer-address {
    line-height: 1.5;
}
`, "",{"version":3,"sources":["webpack://./src/app/customer/components/customer-info/customer-info.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,qBAAqB;IACrB,qBAAqB;IACrB,oBAAoB;AACxB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".customer-info {\n    padding: 1rem;\n    border: 1px solid #e5e5e5;\n}\n\n.bold_text {\n    font-weight: bold;\n    display: inline-block;\n    margin-bottom: 0.5rem;\n    font-size: 1.0625rem;\n}\n.customer-name {\n    font-size: 1.3rem;\n    font-weight: 700;\n    padding-bottom: 1rem;\n}\n\n.customer-address {\n    line-height: 1.5;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
