// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-checkbox {
    margin-bottom: 3rem;
}
.ftp-info {
    background-color: rgba(245, 245, 245, 1);
}

.switch-checkbox > .invalid-feedback {
    display: block !important;
}

.email-missing-error {
    display: block;
    background-color: #A51728;
    color:#fff;
    width: 100%;
    padding: .5625rem 1.125rem;
    overflow: visible !important;
    word-wrap: break-word !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/customer/components/document-delivery-settings/document-delivery-settings.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,wCAAwC;AAC5C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,UAAU;IACV,WAAW;IACX,0BAA0B;IAC1B,4BAA4B;IAC5B,gCAAgC;AACpC","sourcesContent":[".switch-checkbox {\n    margin-bottom: 3rem;\n}\n.ftp-info {\n    background-color: rgba(245, 245, 245, 1);\n}\n\n.switch-checkbox > .invalid-feedback {\n    display: block !important;\n}\n\n.email-missing-error {\n    display: block;\n    background-color: #A51728;\n    color:#fff;\n    width: 100%;\n    padding: .5625rem 1.125rem;\n    overflow: visible !important;\n    word-wrap: break-word !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
