// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-body {
  color: black;
  background-color: #ffcc00;
  padding: 2rem;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.modal-body .es {
  background-color: black;
}

.modal-body > h4 {
  text-align: center;
  font-weight: 300;
}

.modal-footer {
  padding: 1.5em;
}`, "",{"version":3,"sources":["webpack://./src/app/customer/components/cca-upload-file-confirmation/cca-upload-file-confirmation.component.scss","webpack://./../../../EHR-nightly-deploy%20manual/ehr/ehr-web-client/src/app/customer/components/cca-upload-file-confirmation/cca-upload-file-confirmation.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,yBAAA;EACA,aAAA;EACA,2BAAA;EACA,4BAAA;ACCJ;;ADEA;EACI,uBAAA;ACCJ;;ADEA;EACI,kBAAA;EACA,gBAAA;ACCJ;;ADEA;EACI,cAAA;ACCJ","sourcesContent":[".modal-body {\n    color: black;\n    background-color: #ffcc00;\n    padding: 2rem;\n    border-top-left-radius: 3px;\n    border-top-right-radius: 3px;\n}\n\n.modal-body .es {\n    background-color: black;\n}\n\n.modal-body > h4 {\n    text-align: center;\n    font-weight: 300;\n}\n\n.modal-footer {\n    padding: 1.5em;\n}\n",".modal-body {\n  color: black;\n  background-color: #ffcc00;\n  padding: 2rem;\n  border-top-left-radius: 3px;\n  border-top-right-radius: 3px;\n}\n\n.modal-body .es {\n  background-color: black;\n}\n\n.modal-body > h4 {\n  text-align: center;\n  font-weight: 300;\n}\n\n.modal-footer {\n  padding: 1.5em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
