/*
 * Copyright 2024 by Swiss Post, Information Technology
 */

import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

const CONF_API = 'api/conf';

@Injectable()
export class ConfService {
    constructor(private http: HttpClient) {
    }

    getConfByKey(key: string): Observable<boolean> {
        return this.http.get<boolean>(`${CONF_API}/${key}`);
    }
}
