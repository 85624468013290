import {CustomerDeliveryType, DeliveryMode, DeliveryPeriod} from "./customer-shipment-options.interface";
import {CustomerDownloadOptions} from "./customer-download-options.interface";
import {CustomerSummary} from "../../security/user.interface";
import {Carrier} from "./carrier";
import {PriceVatDutyLimitType} from "../../document/models/document-search-criteria.interface";

export interface Customer extends CustomerSummary {
    customerId: number;
    number: string;
    additionalCustomerNumber: string;
    name: string;
    street: string;
    postCode: string;
    city: string;
    contactEmailAddress: string;
    language: CustomerLanguage;
    valueVat: number;
    valueDuty: number;
    deliveryType: CustomerDeliveryType;
    automaticDeliveryPeriod: DeliveryPeriod;
    automaticDeliveryMode: DeliveryMode;
    deliveryEmailAddressesExport: string[];
    deliveryEmailAddressesImport: string[];
    carrId: number;
    password: string;
    downloadOptions: CustomerDownloadOptions;
    carrier?: Carrier;
    state: CustomerState;
    country: any;
    automaticDeliveryEnabled: boolean;
}

export enum CustomerLanguage {
    DE = "DE",
    FR = "FR",
    IT = "IT",
    EN = "EN"
}

export enum CustomerState {
    ACTIVE = "ACTIVE",
    BLOCKED = "BLOCKED"
}

export enum CustomerRole {
    PARTNER = "PARTNER",
    REG = "REG"
}

export enum EmailAddressType {
    IMPORT = "IMPORT",
    EXPORT = "EXPORT"

}

export interface EmailAddress {
    email: string;
    typeImport: boolean;
    typeExport: boolean;
}

export const PRICE_LIMIT_ARRAY = [
    {key: PriceVatDutyLimitType.ALL, value: 0},
    {key: PriceVatDutyLimitType.MORE_THAN_FIVE, value: 5}];

