import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import * as _ from 'lodash';

@Pipe({name: 'countryOrderBy', pure: false})
export class CountryOrderByPipe implements PipeTransform {
    constructor (private translateService: TranslateService) {
    }
    /* eslint-disable */
    transform(countries: any[]): any {
        if (!countries) { return countries; } // no array
        if (countries.length <= 1) { return countries; } // array with only one item
        const myCountries = countries.map(c => {
            const countryIsoI18nPair: KeyValuePair = {key: c, value: this.translateService.instant('country.' + c)};
            return countryIsoI18nPair;
        });

        return _.orderBy(myCountries, ['value'], ['asc']);
    }
}

interface KeyValuePair {
    key: string;
    value: string;
}
