import {Component, HostBinding, HostListener, Input} from '@angular/core';
import {DocumentService} from '../../services/document.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationDialogComponent} from '../../../common/notification-dialog/notification-dialog.component';
import {TealiumUtagService} from "../../../common/tealium-utag.service";
import {TranslateService} from "@ngx-translate/core";
import {DocumentAnonymousSearchCriteria} from "../../models/document-anonymous-search-criteria.interface";
import {LoadingSpinnerDialogService} from "../../../common/loading-spinner-dialog/loading-spinner-dialog.service";
import {NotificationsService} from "../../../notifications/services/notifications.service";
import {DocumentDetail} from "../../models/document-detail.interface";
import {HttpResponse} from "@angular/common/http";

@Component({
    selector: 'evv-document-download-dropdown',
    templateUrl: 'document-download-dropdown.component.html'
})
export class DocumentDownloadDropdownComponent {
    @HostBinding('class.documentsShown') documentsShown = false;

    constructor(private documentService: DocumentService,
                private modalService: NgbModal,
                private tealiumService: TealiumUtagService,
                private translateService: TranslateService,
                private loadingDialog: LoadingSpinnerDialogService,
                private notificationsService: NotificationsService) {
    }

    @Input()
    documentId: number;
    @Input()
    availableDocuments: DocumentDetail[];
    @Input()
    customerId: number;
    @Input()
    anonymousCriteria: DocumentAnonymousSearchCriteria;
    /* eslint-disable */
    activate() {
        if (this.availableDocuments && this.availableDocuments.length > 0) {
            this.documentsShown = true;
        } else {
            this.documentsShown = false;
            const modal = this.modalService.open(NotificationDialogComponent, {size: 'lg'});
            modal.componentInstance.title = "documents.no-files.title";
            modal.componentInstance.description = "documents.no-files.description";
        }
    }

    downloadSingleDocument(id: number) {
        /*this.tealiumService.sendEventWithLocation('click', 'download ', 'single document with the type: ' + type,
            this.translateService.instant("app.title") + '-' + ' single document with the type' + type);*/
        this.loadingDialog.show();

        this.documentService.downloadSingleDocumentById(this.documentId, id, this.customerId, this.anonymousCriteria)
            .subscribe(file => this.saveFile(file), () => this.showDownloadError(), () => this.loadingDialog.hide());
    }


    downloadAllDocuments() {
        /*this.tealiumService.sendEventWithLocation('click', 'download ', 'single document with all types: ',
            this.translateService.instant("app.title") + '-'  + ' single document with all types');*/
        this.loadingDialog.show();

        this.documentService.downloadDocumentsZip([ this.documentId ], this.customerId, this.anonymousCriteria)
            .subscribe(zip => this.saveFile(zip), () => this.showDownloadError(), () => this.loadingDialog.hide());
    }

    private saveFile(file: HttpResponse<Blob>) {
        DocumentService.saveFile(file);
        this.translateService.get('document.download.success').subscribe(t => {
            this.notificationsService.showSuccessNotification(t);
        });
    }

    private showDownloadError() {
        this.translateService.get('document.download.error').subscribe(t => {
            this.notificationsService.showErrorNotification(t);
        });
    }

    @HostListener("click", ["$event"])
    public onListenerTriggered(event: any): void {
        if (event.target.id === 'all') {
            this.tealiumService.link({
                event_category_primaryCategory: 'download',
                event_attributes_loc: 'main',
                event_eventInfo_cause: 'click',
                event_eventInfo_effect: 'download',
                event_attributes_tgtURL : 'unknown',
                event_eventInfo_eventLabel: this.translateService.instant("document.dropdown.all")
            });
        } else {
            this.availableDocuments.forEach(adDetail => {
                const type = adDetail.type;
                if (type.toLocaleLowerCase() === event.target.id) {
                    this.tealiumService.link({
                        event_category_primaryCategory: 'download',
                        event_attributes_loc: 'main',
                        event_eventInfo_cause: 'click',
                        event_eventInfo_effect: 'download',
                        event_attributes_tgtURL : 'unknown',
                        event_eventInfo_eventLabel: this.translateService.instant("document.dropdown." + event.target.id)
                    });
                }
            });
        }

    }
}
