/*
 * Copyright 2023 by Swiss Post, Information Technology
 */
import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

/* eslint-disable */
@Component({
    selector: 'evd-step-support-urgent',
    templateUrl: './step-support-urgent.component.html',
    styleUrls: ['./step-support-urgent.component.scss']
})
export class StepSupportUrgentComponent {


    constructor( private router: Router, private translateService: TranslateService) {
    }

    onButtonYes() {
        this.router.navigate(['ui', 'login', 'customer'], { queryParams:{customerNr: '', domain: 'urgent'}});
    }

    onButtonNo() {
        this.router.navigate(['ui', 'login', 'customer'], { queryParams:{customerNr: '', domain: 'pvz'}});
    }

    get billUrgentImg() {
        const lang = this.translateService.currentLang;
        return 'bill-urgent-img-' + lang.toLowerCase();
    }
}



