export interface DocumentSearchCriteria {
    serviceType: DocumentSearchServiceType;
    customerId: number;
    carrierId: number;
    documentTsFrom: Date;
    documentTsTo: Date;
    searchNumber: string;
    documentNumber?: string;
    customerState: ReceiptState;
    consignorName: string;
    consignorPostCode: string;
    consignorCity: string;
    consigneeName: string;
    consigneePostCode: string;
    consigneeCity: string;
    consigneeCountry: string; // values of Country
    withoutCustomer: boolean;
    vatAmount: number;
    dutyAmount: number;
    invoiceKey?: string;
}

export enum DocumentSearchServiceType {
    IMPORT = "IMPORT",
    EXPORT = "EXPORT",
    EVEU = "EVEU",
    ALL = "ALL"
}

export enum ReceiptState {
    DOWNLOADED = "DOWNLOADED",
    NEW = "NEW",
    DELIVERED = "DELIVERED",
    DELIVERY_ERROR = "DELIVERY_ERROR",
    OLD_VERSION = "OLD_VERSION"
}

export enum PriceVatDutyLimitType {
    ALL = "ALL",
    LESS_THAN_FIVE = "LESS_THAN_FIVE",
    MORE_THAN_FIVE = "MORE_THAN_FIVE"
}

export const PRICE_LIMIT_ARRAY_DROPDOWN = [
        {key: PriceVatDutyLimitType.ALL, value: null},
        {key: PriceVatDutyLimitType.MORE_THAN_FIVE, value: 5}];
