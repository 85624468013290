// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bold_text {
    font-weight: bold;
    display: inline-block;
    margin-bottom: 0.5rem;
}
.switch-checkbox {
    margin-bottom: 3rem;
}

`, "",{"version":3,"sources":["webpack://./src/app/customer/components/cca-customer-info/cca-customer-info.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,qBAAqB;IACrB,qBAAqB;AACzB;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".bold_text {\n    font-weight: bold;\n    display: inline-block;\n    margin-bottom: 0.5rem;\n}\n.switch-checkbox {\n    margin-bottom: 3rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
