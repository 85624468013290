import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NotificationsService} from "../../../notifications/services/notifications.service";
import {HttpErrorResponse} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {UserService} from "../../../security/user.service";

@Component({
    selector: 'evd-tnt-cc-user',
    templateUrl: 'tnt-cc-user.component.html'
})
/* eslint-disable */
export class TntCcUserComponent implements OnInit {

    tntCcUserForm: FormGroup;

    constructor(private formBuilder: FormBuilder,
                private userService: UserService,
                private notificationsService: NotificationsService,
                private translateService: TranslateService) {

    }

    ngOnInit() {
        this.tntCcUserForm = this.formBuilder.group({
            klpProfileId: new FormControl('', Validators.required)
        });
    }

    handleReset() {
        this.tntCcUserForm.reset({
            klpProfileId: ''
        });
    }

    handleSubmit() {
        if (!this.tntCcUserForm.valid) {
            return;
        }
        this.userService.addTntCcUser(this.tntCcUserForm.get('klpProfileId').value).subscribe(() => {
            this.notificationsService.showSuccessNotification(this.translateService.instant('add-tnt-cc-user.success') as string);
        }, (response: HttpErrorResponse) => {
            this.notificationsService.handleHttpError(response);
        });
    }
}

