import {Injectable} from "@angular/core";
import {
    HttpClient,
    HttpErrorResponse
} from "@angular/common/http";
import {tap} from "rxjs/operators";
import {BehaviorSubject, throwError} from "rxjs";
import {ServiceTypeUpload} from "../models/carrier";

/* eslint-disable */
const CUSTOMER_API = 'api/customers';

@Injectable()

export class CcaFileUploaderService {
    public progressSource = new BehaviorSubject<number>(0);

    constructor(private http: HttpClient) {}

    upload(id: number, file: File, serviceType: ServiceTypeUpload, docType: any, shipmentNr: string) {
        const uploadUrl = `${CUSTOMER_API}/${id}/upload`;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('serviceType', serviceType);
        formData.append('docType', docType);
        formData.append('shipmentNr', shipmentNr);

        return this.http.post(uploadUrl, formData, {reportProgress: true, observe: 'response', responseType: 'text'})
            .pipe(tap({
                next: (data) => data.body,
                error: (error) => this.handleError(error)
            }));

    }

    handleError(error: HttpErrorResponse) {
        let errorMessage: string;
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        //console.log(errorMessage);
        return throwError(errorMessage);
    }

    processProgress(envelope: any): void {
        if (typeof envelope === "number") {
            this.progressSource.next(envelope);
        }
    }
}
