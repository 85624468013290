// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-buttons {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.bold_text {
    font-weight: bold;
    display: inline-block;
    margin-bottom: 0.5rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

.action-button-row {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/app/customer/components/customer-form/customer-form.component.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,qBAAqB;IACrB,qBAAqB;AACzB;;AAEA;;;;IAII,qDAAqD;IACrD,yDAAiD;IAAjD,iDAAiD;AACrD;;AAEA;IACI,wCAAwC;AAC5C","sourcesContent":[".form-buttons {\n    margin-right: 0 !important;\n    margin-left: 0 !important;\n}\n\n.bold_text {\n    font-weight: bold;\n    display: inline-block;\n    margin-bottom: 0.5rem;\n}\n\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ninput:-webkit-autofill:active {\n    -webkit-box-shadow: 0 0 0 30px white inset !important;\n    transition: background-color 5000s ease-in-out 0s;\n}\n\n.action-button-row {\n    border-top: 2px solid rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
