import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {UserService} from '../../../user.service';
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {WrongLoginDialogComponent} from "../../../../common/wrong-login-dialog/wrong-login-dialog.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-login',
    templateUrl: 'customer-care-login.component.html'
})
/* eslint-disable */
export class CustomerCareLoginComponent implements OnInit, OnDestroy {

    targetUrl: string;

    private readonly subscription: Subscription;

    constructor(private userService: UserService,
                private route: ActivatedRoute,
                private translateService: TranslateService,
                private router: Router,
                private modalService: NgbModal) {
        this.subscription = this.translateService.stream('app.customer-care').subscribe(t => {
            /*window.document.querySelector('swisspost-internet-breadcrumbs')['customItems'] = [{text: t, url: "/ui/cca"}, {
                text: this.translateService.instant('app.customer-care.login'),
                url: `ui/cca/login`
            }];*/
        });
    }

    get isKLPLoggedIn(): boolean {
        return this.userService.isUserIdpLoggedIn();
    }

    ngOnInit() {
        this.route
            .queryParams
            .subscribe(params => {
                this.targetUrl = params['targetUrl'];
            });
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    handleSubmit(loginForm: NgForm) {
        this.userService.login(loginForm.value.username, loginForm.value.password).subscribe(
            () => {
                this.router.navigate([this.targetUrl ? this.targetUrl : '/']);
            },
            () => {
                this.modalService.open(WrongLoginDialogComponent, {size: 'lg'});
            }
        );
    }
}
