import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {HttpErrorResponse} from "@angular/common/http";

import {TranslateService} from "@ngx-translate/core";

import {SortEvent} from "primeng/api";
import {flatMap} from "rxjs/operators";
import {of} from "rxjs";

import {LoadingSpinnerDialogService} from '../../../common/loading-spinner-dialog/loading-spinner-dialog.service';
import {Carrier} from '../../models/carrier';
import {CustomerSearch} from '../../models/customer-search.interface';
import {Customer} from '../../models/customer.interface';
import {CustomerService} from '../../services/customer.service';
import {PagedData} from "../../../common/spring-data/paged-data";
import {Order} from "../../../common/search.model";
import {UserService} from "../../../security/user.service";
import {TealiumUtagService} from "../../../common/tealium-utag.service";
import {NotificationsService} from "../../../notifications/services/notifications.service";

@Component({
    selector: 'evv-customer-list',
    styleUrls: ['customer-list.component.css'],
    templateUrl: 'customer-list.component.html'
})
export class CustomerListComponent implements OnInit {
    customers: Customer[] = [];
    @Input() carriers: Carrier[];
    @Input() currentCarrier: Carrier;
    @Output() currentCarrierChange = new EventEmitter<Carrier>();
    private searchCriteria: CustomerSearch;

    // need to use a different field from searchCriteria to avoid cycles in change detection
    savedSearchCriteria: CustomerSearch;
    initialSearchCriteria: CustomerSearch;

    totalRecords: number;

    index = 0;
    rows = 10;
    sortField = "number";
    sortOrder: Order = Order.ASC;

    constructor(private customerService: CustomerService,
                private loadingDialog: LoadingSpinnerDialogService,
                private router: Router,
                private route: ActivatedRoute,
                private userService: UserService,
                private tealiumService: TealiumUtagService,
                private translateService: TranslateService,
                private notificationsService: NotificationsService) {
    }

    ngOnInit(): void {
        const carr = this.currentCarrier
            || (this.carriers && this.carriers.length  === 1 ? this.carriers[0] : null);
        this.initialSearchCriteria = {
            carrier: carr,
            customerName: null,
            customerNumber: null,
            sendingNumber: null
        };
        const search = this.customerService.lastCustomerSearch;
        // merge the current carrier with the saved search
        if (search && (!search.criteria.carrier || this.carriers.find(c => c.id === search.criteria.carrier.id))) {
            this.savedSearchCriteria = search.criteria;
            this.index = search.index;
            this.rows = search.rows;
            this.sortField = search.sort;
            this.sortOrder = search.order;
        } else {
            this.savedSearchCriteria = this.initialSearchCriteria;
        }

        this.searchCriteria = this.savedSearchCriteria;
        this.getCustomers();
    }
    /* eslint-disable */
    navigateToCreateCustomer() {
        this.tealiumService.sendEventWithLocation('click', 'go-to', 'Neuer Kunde ',
            'main', 'unknown', this.translateService.instant("app.title") + '-' + 'neuer Kunde anlegen');
        this.router.navigate(['customers', 'create'],  {relativeTo: this.route});
    }

    navigateToCustomerProfile(id: number) {
        this.tealiumService.sendEventWithLocation('click', 'go-to', 'Kundeprofil bearbeiten',
            'main', 'unknown', this.translateService.instant("app.title") + '-' + 'Kundeprofil bearbeiten');
        this.router.navigate(['customers', id], {relativeTo: this.route});
    }

    navigateToCustomerDocuments(id: number) {
        this.tealiumService.sendEventWithLocation('click', 'go-to', 'elektronische Verzollungsdocument suchen',
            'main', 'unknown', this.translateService.instant("app.title") + '-' + 'elektronische Verzollungsdocument suchen');
        const queryParams: Params = this.searchCriteria && this.searchCriteria.sendingNumber ?
            {sendingNumber: this.searchCriteria.sendingNumber}
            : null;
        this.router.navigate(['customers', id, 'documents'], {relativeTo: this.route, queryParams});
    }

    changeRows(rows: number) {
        this.rows = rows;
        this.index = 0;
        this.getCustomers();
    }

    changePage(pageNumber: number) {
        this.index = pageNumber - 1;
        this.getCustomers();
    }

    handleSearch(searchCriteria: CustomerSearch) {
        if (!searchCriteria) {
            return;
        }
        this.tealiumService.sendSingleStep(" custoemr search", 'Single step - Formaularangaben', searchCriteria);
        this.searchCriteria = {...searchCriteria}; // create copy to avoid changes to the form
        this.index = 0;
        this.sortField = "number";
        this.sortOrder = Order.ASC;
        if ((searchCriteria.carrier && searchCriteria.carrier.id) != (this.currentCarrier && this.currentCarrier.id)) {
            this.currentCarrier = searchCriteria.carrier;
            this.currentCarrierChange.emit(this.currentCarrier);
        }

        this.getCustomers();
    }

    onSort($event: SortEvent) {
        const order = $event.order > 0 ? Order.ASC : Order.DESC;
        if (this.sortField !== $event.field || this.sortOrder !== order) {
            this.sortField = $event.field;
            this.sortOrder = order;
            this.index = 0;
            this.getCustomers();
        }
    }

    navigateToUploadDocument(id: number) {
        this.router.navigate(['customers', id, 'upload'], {relativeTo: this.route});
    }

    private getCustomers() {
        if (this.searchCriteria) {
            this.loadingDialog.show();
            this.customerService
                .getCustomersCCAForTable(this.index, this.rows, this.sortField, this.sortOrder, this.searchCriteria)
                .pipe(
                    flatMap(data => {
                        if (data.page.totalElements === 0 && data.page.number === 0 && !!this.searchCriteria.sendingNumber && !this.searchCriteria.documentNumber) {
                            this.searchCriteria.documentNumber = this.searchCriteria.sendingNumber;
                            return this.customerService.getCustomersCCAForTable(this.index, this.rows, this.sortField, this.sortOrder, this.searchCriteria);
                        }
                        return of(data);
                    })
                )
                .subscribe((data: PagedData) => {
                    this.customers = data._embedded['customers'];
                    this.index = data.page.number;
                    this.rows = data.page.size;
                    this.totalRecords = data.page.totalElements;
                    this.loadingDialog.hide();
                }, (response: HttpErrorResponse) => {
                        this.loadingDialog.hide();
                        this.notificationsService.handleHttpError(response);
                }, () =>  {
                    this.loadingDialog.hide();
                });
        }
    }

    getCarrierName(carrId: number) {
        const carr = this.userService.getCurrentUser().carriers.filter(c => c.id === carrId)[0];
        return carr == null ? '' : carr.name;
    }

    get showCarrier() {
        return !this.searchCriteria.carrier;
    }

    getCarrierIcon(carrId: number) {
       const carrName = this.getCarrierName(carrId);
       switch (carrName) {
           case 'GLS':
               return 'gls-icon';
           case 'PVZ':
               return 'pvz-icon';
           case 'TNT':
               return 'tnt-icon';
           default:
               return null;

       }
    }

    uploadIconShown(carrId: number) {
        return this.getCarrierName(carrId) !== 'TNT';

    }

    get isUserCCAdmin(): boolean {
        return this.userService.isUserCCAdmin();
    }

}
