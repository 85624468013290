import {Component, Input} from "@angular/core";
import {Customer} from "../../models/customer.interface";
import {Carrier} from "../../models/carrier";

@Component({
    selector: 'evv-customer-info',
    styleUrls: ['customer-info.component.css'],
    templateUrl: 'customer-info.component.html'
})
export class CustomerInfoComponent {

    @Input()
    customer: Customer;
    @Input()
    carriers:  Carrier[];

    get ccaEmailAddress() {
        if (this.isGls) {
            return 'customer-info.cca-email-address.gls';
        } else if (!this.isGls) {
            return 'customer-info.cca-email-address.pvz';
        }
        return null;
    }

    get isGls () {
        const carrierName = this.carriers.filter(c => c.id === this.customer.carrId).map(d => d.name);
        if(carrierName[0] === 'GLS') {
           return true;
        } else if (carrierName[0] === 'PVZ') {
           return false;
        }
        return null;
    }
}
