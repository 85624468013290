import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../user.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {WrongLoginDialogComponent} from '../../../../common/wrong-login-dialog/wrong-login-dialog.component';
import {CLIENT_CONFIG} from "../../../../common/constants";
import {ClientConfig} from "../../../../common/client-config.model";
import {Subscription} from "rxjs";
import {concatMap, map} from "rxjs/operators";
import {TealiumUtagService} from "../../../../common/tealium-utag.service";

const USERNAME_PATTERN="^[A-Za-z0-9]*$";
const CUSTOM_ITEMS = 'customItems';
@Component({
    selector: 'app-customer-link',
    templateUrl: 'customer-link.component.html',
    styleUrls: ['customer-link.component.scss']
})
/* eslint-disable */
export class CustomerLinkComponent implements OnInit, OnDestroy {

    customerData: any = null;

    targetUrl: string;
    isSetNewPasswordFormShown = false;
    loginForm: FormGroup;
    initialLinked = true;
    @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
    readonly carriers: [{id: 1, name: "TNT"}, {id: 2, name: "GLS"}, {id: 3, name: "PVZ"}];

    private  subscription: Subscription;

    constructor(private userService: UserService,
                private route: ActivatedRoute,
                private router: Router,
                private translateService: TranslateService,
                private formBuilder: FormBuilder,
                private modalService: NgbModal,
                @Inject(CLIENT_CONFIG) public clientConfig: ClientConfig,
                private tealiumService: TealiumUtagService
    ) {
        this.loginForm = this.formBuilder.group({
            username: new FormControl(null, [Validators.required, Validators.pattern(USERNAME_PATTERN)]),
            password: new FormControl(null, Validators.required)
        });
        /*if (!this.initialLinked) {
            this.subscription = this.translateService.stream('customer.my-customers').subscribe(t => {
                window.document.querySelector('swisspost-internet-breadcrumbs')[CUSTOM_ITEMS] = [{text: t, url: 'ui/my-evd/accounts'}, {
                    text: this.translateService.instant('customer.klp.link'),
                    url: `ui/my-evd/accounts/link-account`
                }];
            });
        } else {
           // window.document.querySelector('swisspost-internet-breadcrumbs')[CUSTOM_ITEMS] = null;
        }*/
    }

    ngOnInit() {
        this.route
            .queryParams
            .subscribe(params => {
                this.targetUrl = params['targetUrl'];
                if (this.targetUrl != null) {
                    this.initialLinked = false;
                } else {
                    //window.document.querySelector('swisspost-internet-breadcrumbs')[CUSTOM_ITEMS] = null;
                }
            });
    }

    ngOnDestroy(): void {
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    handleCancel() {
        this.formGroupDirective.resetForm();
    }

    goToPasswordForgot() {
        this.router.navigate(['ui', 'forgot-password']);
    }

    switchPasswordVisibility(password: HTMLInputElement) {
        password.type = password.type === 'password' ? 'text' : 'password';
    }

    showPasswordIcon(password: HTMLInputElement) {
        return password.type === 'password' ? 'pi-2114' : 'pi-2115';
    }

    handleLinkAccount() {
        if (!this.loginForm.valid) {
            return;
        }
        this.tealiumService.sendEventWithLocation('click', 'go-to ', 'KLP Konto verknuepfe mit eVD Konto',
            'main', 'unknown', this.translateService.instant("app.title") + '-'  + 'KLP Konto verknuepfe mit eVD Konto');
        const userAuth = this.loginForm.value;
        this.handleKlpLogin(userAuth);

    }

    private handleKlpLogin(userAuth) {
        this.userService.loginWithKLP(userAuth.username, userAuth.password)
            .pipe(
                concatMap(customerLoginResult => this.userService.linkCustomer(customerLoginResult.customerId).pipe(map(() => customerLoginResult)))
            )
            .subscribe(customerLoginResult => {
                this.targetUrl = `/ui/my-evd/accounts/${customerLoginResult.customerId}/documents`;
                this.router.navigate([this.targetUrl ? this.targetUrl : '/']);
            }, () => {
                const modalInstance = this.modalService.open(WrongLoginDialogComponent, {size: 'lg'});
                modalInstance.componentInstance.isFromKlp = true;
            });
    }

    get evvLoginUrl() {
        return this.clientConfig.evvLoginUrl;
    }
}
