import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HttpErrorResponse} from "@angular/common/http";
import {Error} from '../models/error.interface';
import {ToastrService} from "ngx-toastr";

/**
 * Provides a way to handle notifications and http errors globally.
 */
@Injectable()
/* eslint-disable */
export class NotificationsService {

    unexpectedErrorEvent: Subject<HttpErrorResponse> = new Subject<HttpErrorResponse>();

    constructor(private toastr: ToastrService) {
    }

    public showErrorNotification(title: string = null, message: string = null) {
        this.toastr.error(message, title, {
            timeOut: 3000
        });
    }

    public showSuccessNotification(title: string = null, message: string = null) {
        this.toastr.success(message, title, {
            timeOut: 3000
        });
    }

    /**
     * Expected errors have status 400, and have an Error[] in the json response: they are handled by the caller.
     * Unexpected errors: everything else: handled by subscribers.
     */
    public handleHttpError(response: HttpErrorResponse): Error[] {
        let errors = [];
        if (400 === response.status && response.error && response.error.errors) {
            // Let the caller handle the expected error
            errors = response.error.errors as Error[];
        } else {
            this.handleUnexpectedHttpError(response);
        }
        return errors;
    }

    handleUnexpectedHttpError(response: HttpErrorResponse) {
        // Let subscribers handle the unexpected error
        this.unexpectedErrorEvent.next(response);
    }
}
