import {FormGroup} from "@angular/forms";

export function checkDocTypeSameValidator(controlNameA: string, controlNameB: string) {

    return (formGroup: FormGroup) => {
        const controlFileName = formGroup.controls[controlNameA];
        const controlDocType = formGroup.controls[controlNameB];
        if (controlDocType.errors && !controlDocType.errors.docTypeIfSame) {
            // return if another validator has already found an error on the matchingControl
            return;
        }
        if (controlFileName.value == null || controlDocType == null) {
            return;
        }
        const fileExtension = getFileExtension(controlFileName.value as string);
        /* eslint-disable */
        let docType = controlDocType.value;
        if (docType != null && docType.lastIndexOf("_") > 0) {
            docType = docType.substring(docType.lastIndexOf("_") + 1);
        }
        /* eslint-disable */
        if (docType === "vld" ) {
            docType = "xml";
        }
        if (docType != null && docType !== fileExtension) {
            controlDocType.setErrors({docTypeIfSame: true});
        } else {
            controlDocType.setErrors(null);
        }
    };
}

function getFileExtension(fileName: string): string {
    if (fileName != null && fileName.length > 0) {
        if (fileName.lastIndexOf(".") > 0) {
            return fileName.substring(fileName.lastIndexOf(".") + 1);
        }
    }
    return null;
}
