import {Component, Input, OnInit} from '@angular/core';

const SHOW_WITHOUT_HEADER_HOSTS=['www.post.ch', 'int.post.ch'];
@Component({
    selector: 'evd-login-with-klp',
    templateUrl: './login-with-klp.component.html',
    styleUrls: ['./login-with-klp.component.scss']
})
export class LoginWithKlpComponent implements OnInit {
    @Input()
    klpEvdLoginUrl: string;
    @Input()
    inTab: boolean;
    openInSheet = false;

    ngOnInit(): void {
        const host = window.location.host;
        if(SHOW_WITHOUT_HEADER_HOSTS.findIndex(h => h === host.toLowerCase()) > -1) {
            this.openInSheet = true;
        }
    }
}


