/*
 * Copyright 2023 by Swiss Post, Information Technology
 */
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {TealiumUtagService} from "../../../common/tealium-utag.service";
import {TranslateService} from "@ngx-translate/core";
import {Observable, of, Subscription} from "rxjs";
import {LanguageSelectionService} from "../../../security/language.selection.service";
import {getQueryParamFromMalformedURL} from "../../../common/urlParameter";

const MAILPIECE_NUMBER_PATTERN="^[A-Za-z0-9]*$";
@Component({
    selector: 'evd-anonymous-login-form',
    templateUrl: './anonymous-login-form.component.html',
    styleUrls: ['./anonymous-login-form.component.scss']
})
/* eslint-disable */
export class AnonymousLoginFormComponent implements OnInit, OnDestroy {
    @Input()
    inTab: boolean;

    anonymousSearchForm: FormGroup;
    private languageSelSub: Subscription;
    mailpieceNr:string;
    lang$: Observable<string>;
    constructor(private formBuilder: FormBuilder, private router: Router,
                private route: ActivatedRoute,
                private tealiumService: TealiumUtagService, private translateService: TranslateService,
                private languageSelectService: LanguageSelectionService) {
        this.anonymousSearchForm = this.formBuilder.group({
            foreignParcelNumber: new FormControl(null, [Validators.required, Validators.pattern(MAILPIECE_NUMBER_PATTERN)]),
            consignorPostCode: new FormControl(null, {validators: Validators.required})
        }, {updateOn: 'blur'});
    }
    ngOnInit(): void {
        this.route.queryParams.subscribe(
            params => {
                this.mailpieceNr = params['mailpieceNr'] || getQueryParamFromMalformedURL('mailpieceNr', this.router.url) || null;
                if (this.mailpieceNr) {
                    const mailpiece = this.mailpieceNr.replace(/:/, '');
                    this.foreignParcelNumberCtrl.setValue(mailpiece.trim());
                }
            }
        );
        this.languageSelSub = this.languageSelectService.getSelectedLanguage().subscribe(selLang =>{
            this.lang$ = selLang != null ? of(selLang) : of(this.translateService.currentLang);
        });
    }

    ngOnDestroy(): void {
        if (this.languageSelSub) {
            this.languageSelSub.unsubscribe();
        }
    }

    forwardToAnonymous() {
        let parcelNr;
        if (!this.anonymousSearchForm.valid) {
            if (this.foreignParcelNumberCtrl.hasError('pattern')) {
                const normalizedForeignParcelNr = this.foreignParcelNumberCtrl.value.trim().replace(/[^A-Za-z0-9]/g, '');
                this.foreignParcelNumberCtrl.setValue(normalizedForeignParcelNr);
            } else {
               return;
            }
        }
        parcelNr = this.foreignParcelNumberCtrl.value as string;
        let plz = this.anonymousSearchForm.get('consignorPostCode').value as string;
        if (plz != null) {
            plz = plz.trim();
        }
        this.router.navigate(['ui', 'document-search'], {queryParams: {parcelNr: parcelNr, zip: plz}});
    }


    reset() {
        this.anonymousSearchForm.reset({
            foreignParcelNumber: null,
            consignorPostCode: null
        });
    }

    /*gotoQrScanner() {
        this.router.navigate(['ui', 'login', 'qr-code-scanner']);
    }*/

    get foreignParcelNumberCtrl(): FormControl {
        return this.anonymousSearchForm.get('foreignParcelNumber') as FormControl;
    }
}

