/*
 * Copyright 2023 by Swiss Post, Information Technology
 */
import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {CLIENT_CONFIG} from "../../../common/constants";
import {ClientConfig} from "../../../common/client-config.model";
import {Observable, Subscription} from "rxjs";
import {ConfService} from "../../conf.service";
import {TranslateService} from "@ngx-translate/core";
import {map, switchMap} from "rxjs/operators";
import {UserService} from "../../../security/user.service";

/* eslint-disable */
const SHOW_WITHOUT_HEADER_HOSTS=['www.post.ch', 'int.post.ch'];
const KEY_ALERT_SWITCH_ON = 'alert_switch_on'
@Component({
    selector: 'evd-welcome-page',
    templateUrl: './welcome-page.component.html',
    styleUrls: ['welcome-page.component.scss']
})
export class WelcomePageComponent implements OnInit, OnDestroy {
    openInSheet = false;
    lang='de' || 'DE';
    klpLoginRequired: boolean;
    private loggedInSubscription: Subscription;
    @ViewChild("dismissible") dismissible: ElementRef;
    alertDisplayed: boolean;
    displayNbsp: boolean;
    targetUrl$: Observable<string>;

    constructor( private router: Router,
                 private route: ActivatedRoute,
                 @Inject(CLIENT_CONFIG) public clientConfig: ClientConfig,
                 private confService: ConfService,
                 private translateService: TranslateService,
                 private userService: UserService) {
        this.klpLoginRequired = clientConfig.klpLoginRequired;
    }

    ngOnInit(): void {
        const host = window.location.host;
        if (SHOW_WITHOUT_HEADER_HOSTS.findIndex(h => h === host.toLowerCase()) > -1) {
            this.openInSheet = true;
        }
        this.lang = this.translateService.currentLang;
        this.displayNbsp = ['de', 'DE'].findIndex(h => h === this.lang) > -1;
        this.getAlertDisplayed();

        this.targetUrl$ = this.route.queryParams.pipe(
            map(params => params['targetUrl'])
        );
        this.loggedInSubscription = this.userService.isLoggedIn$().pipe(
            switchMap(loggedIn => this.targetUrl$.pipe(map(targetUrl => ({loggedIn, targetUrl}))))
        ).subscribe(({loggedIn, targetUrl}) =>{
            if(loggedIn) {
                // the user is already logged in
                if (!targetUrl) {
                    if (this.userService.isUserCustomer()) {
                        targetUrl = '/ui/my-evd';
                    } else if (this.userService.isUserCustomerCare()) {
                        targetUrl = '/ui/cca';
                    } else if (this.userService.isUserKlp()) {
                        targetUrl = '/ui/my-evd/accounts/link-account';
                    } else {
                        targetUrl = '/';
                    }
                }
                this.router.navigate([targetUrl]);
            }
        });
    }

    ngOnDestroy(): void {
        if(this.loggedInSubscription) {
            this.loggedInSubscription.unsubscribe();
        }
    }

    onStepWithSupport() {
        this.router.navigate(['ui', 'login', 'step-support']);
    }

    onStepWithoutSupport() {
        this.router.navigate(['ui', 'login', 'step-without-support']);
    }

    /*gotoQrScanner() {
        this.router.navigate(['ui', 'login', 'qr-code-scanner']);
    }*/

    get klpEvdLoginUrl() {
        return this.getUrl(this.clientConfig.klpEvdLoginUrl, this.lang).toString();
    }

    get evvLoginUrl() {
        return this.getUrl(this.clientConfig.evvLoginUrl, this.lang).toString();
    }

    getUrl(baseUrl: string, lang: string): URL {
        const url = new URL(baseUrl);
        url.searchParams.set('lang', lang);
        return url;
    }

    goToStepSupport() {
        this.router.navigate(['ui', 'login', 'step-support']);
    }

    getAlertDisplayed()  {
        return this.confService.getConfByKey(KEY_ALERT_SWITCH_ON).subscribe(a => {
            this.alertDisplayed = a.valueOf();
        });
    }

    toggleFixedAlert() {
        if (this.dismissible != undefined) {
            let displayStyle = this.dismissible.nativeElement.style.display;
            if (displayStyle != "none") {
                this.dismissible.nativeElement.style.display = "none";
            } else {
                this.dismissible.nativeElement.style.display = "";
            }
        }
    }
}



