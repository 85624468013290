// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-icon {
    position: absolute !important;
    right: 1.5rem !important;
    top: 1.5rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/customer/components/password-form/password-form.component.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,wBAAwB;IACxB,sBAAsB;AAC1B","sourcesContent":[".right-icon {\n    position: absolute !important;\n    right: 1.5rem !important;\n    top: 1.5rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
