import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {UserService} from './user.service';
import {Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {CLIENT_CONFIG} from "../common/constants";
import {ClientConfig} from "../common/client-config.model";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private userService: UserService, private router: Router,
                @Inject(CLIENT_CONFIG) private clientConfig: ClientConfig) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
        const neededRoles = route.data['roles'] as Array<string>;
        return this.userService.canActivate(neededRoles).pipe(
            map(result => {
                if (!result) {
                    const isCustomerCare = this.clientConfig.instance === 'admin'
                        || this.userService.isUserCustomerCare();
                    const loginUrl = isCustomerCare ? '/ui/cca/login' : '/ui/login';
                    // If the user is already logged in, don't pass the targetUrl since this may cause a loop.
                    // Probably the url is not accessible to this user.
                    const targetUrl = this.userService.isLoggedIn() ? undefined : state.url;
                    return this.router.createUrlTree([loginUrl], {queryParams: {targetUrl}});
                }
                return true;
            })
        );
    }
}
