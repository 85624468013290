import {BrowserModule} from '@angular/platform-browser';
import {
    CommonModule,
    Location,
    LocationStrategy,
    PathLocationStrategy,
    registerLocaleData
} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {SecurityModule} from './security/security.module';
import {CustomerModule} from './customer/customer.module';
import {DocumentModule} from './document/document.module';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import localeDECH from '@angular/common/locales/de-CH';
import localeITCH from '@angular/common/locales/it-CH';
import localeFRCH from '@angular/common/locales/fr-CH';
import localeENGB from '@angular/common/locales/en-GB';
import {NotificationsService} from './notifications/services/notifications.service';
import {AppRoutingModule} from "./app.routing.module";
import {I18n, SwissPostDateParserFormatter, SwissPostDatepickerI18n} from "./common/datepicker-configuration";
import {NgbDateParserFormatter, NgbDatepickerI18n, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TableModule} from 'primeng/table';
import {SwissPostIntranetHeaderModule} from '@swisspost/design-system-intranet-header';
import {HttpErrorInterceptorProvider} from './common/http-error-interceptor/http-error-interceptor';
import {CLIENT_CONFIG} from "./common/constants";
import {WidgetDirective} from "./evd-widget/evd-widget.directive";
import {CustomerCareModule} from './customer-care/customer-care.module';
import {AdminModule} from "./admin/admin.module";
import {TealiumUtagService} from './common/tealium-utag.service';
import {EVVHeaderModule} from "./common/evv-header/evv-header.module";
import {EvvFooterModule} from "./common/evv-footer/evv-footer.module";
import {RouteDataService} from "./common/route-data.service";
import {LanguageSelectionService} from "./security/language.selection.service";
import {EntryPageModule} from "./entry-page/entry-page.module";
import {NgxScannerQrcodeModule, LOAD_WASM} from 'ngx-scanner-qrcode';
import {UrlSerializer} from "@angular/router";
import {CustomUrlSerializer} from "./common/urlSerializer";

registerLocaleData(localeDECH, 'de');
registerLocaleData(localeITCH, 'it');
registerLocaleData(localeFRCH, 'fr');
registerLocaleData(localeENGB, 'en');

LOAD_WASM().subscribe();

const iconClasses = {
    error: 'alert alert-danger',
    info: 'alert alert-primary',
    success: 'alert alert-success',
    warning: 'alert alert-warning'
};

/* eslint-disable */
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'api/core/translations/messages/', '/messages.json');
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        WidgetDirective
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgbModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            extendedTimeOut: 1000,
            iconClasses,
            closeButton: true,
            preventDuplicates: true,
            toastClass: "toast"
        }),
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CustomerModule,
        DocumentModule,
        CustomerCareModule,
        SecurityModule,
        AppRoutingModule,
        TableModule,
        SwissPostIntranetHeaderModule,
        AdminModule,
        EVVHeaderModule,
        EvvFooterModule,
        EntryPageModule,
        NgxScannerQrcodeModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        RouteDataService,
        NotificationsService,
        HttpErrorInterceptorProvider,
        TealiumUtagService,
        LanguageSelectionService,
        Location,
        I18n,
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        {provide: NgbDatepickerI18n, useClass: SwissPostDatepickerI18n},
        {provide: NgbDateParserFormatter, useClass: SwissPostDateParserFormatter},
        {provide: CLIENT_CONFIG, useValue: window['evdClientConfig']},
        {provide: UrlSerializer, useClass: CustomUrlSerializer}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
