import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {LoadingSpinnerDialogService} from '../../../common/loading-spinner-dialog/loading-spinner-dialog.service';
import {UserService} from '../../../security/user.service';
import {Customer} from '../../models/customer.interface';
import {UserCustomerInfo} from '../../models/user-customer-info';
import {CustomerService} from '../../services/customer.service';
import {Observable, Subscription, throwError} from "rxjs";
import {concatMap, map, switchMap} from "rxjs/operators";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LinkCustomerModalComponent} from "../../components/link-customer-modal/link-customer-modal.component";
import {WrongLoginDialogComponent} from "../../../common/wrong-login-dialog/wrong-login-dialog.component";
import {TealiumUtagService} from "../../../common/tealium-utag.service";

const NO_CUSTOMERS_ERR = "no customers";

@Component({
    selector: 'evv-user-customer-list',
    styleUrls: ['user-customer-list.component.css'],
    templateUrl: 'user-customer-list.component.html'
})
/* eslint-disable */
export class UserCustomerListComponent implements OnDestroy {
    customers: Customer[] = [];
    customersSession: Customer[] = [];
    userCustomers: UserCustomerInfo[];

    private readonly subscription: Subscription;

    constructor(private customerService: CustomerService,
                private loadingDialog: LoadingSpinnerDialogService,
                private router: Router,
                private route: ActivatedRoute,
                private translateService: TranslateService,
                private userService: UserService,
                private modalService: NgbModal,
                private tealiumService: TealiumUtagService
    ) {
        /*this.subscription = this.translateService.stream('customer.my-customers').subscribe(t => {
            window.document.querySelector('swisspost-internet-breadcrumbs')['customItems'] = [{
                text: t,
                url: `ui/my-evd/accounts`
            }];
        });*/
        this.getCustomers();
    }

    ngOnDestroy(): void {
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    isCustomerLinked(customerId: number): boolean {
        if (!this.userCustomers) {
            return false;
        }

        const customer = this.userCustomers.find(c => c.customerId === customerId);
        return !customer ? false : customer.permanentLink;
    }

    navigateToCustomerProfile(id: number) {
        this.tealiumService.sendEventWithLocation('click', 'go-to', 'Mein eVD Profil',
            'main', 'unknown', this.translateService.instant("app.title") + '-' + 'eVD Account Einstellungen');
        this.router.navigate([id], {relativeTo: this.route});
    }

    navigateToCustomerDocuments(id: number) {
        this.router.navigate([id, 'documents'], {relativeTo: this.route});
    }

    navigateToLinkPage() {
        const targetUrl = `/ui/my-evd/accounts`;
        this.router.navigate(['link-account'], {
            queryParams: {
                targetUrl
            },
            relativeTo: this.route
        });
    }

    linkCustomer(id: number) {
        this.userService.linkCustomer(id).subscribe(() => {
            this.getCustomers();
        });
    }

    openLinkCustomerModal() {
        const modalInstance = this.modalService.open(LinkCustomerModalComponent, { size: 'lg' });
        modalInstance.componentInstance.linkAccount.subscribe((userAuth) => {
            //this.getCustomers();
            this.handleKlpLogin(userAuth);
        });
    }

    unlinkCustomer(id: number) {
        this.tealiumService.sendEventWithLocation('click', 'delete', 'Verknuepfung aufheben',
            'main','unknown', this.translateService.instant("app.title") + '-' + 'eVD Account verwalten or verknuepfen');
        this.userService.unlinkCustomer(id).subscribe(() => {
            this.getCustomers();
        });
    }

    backToUserCustomerList() {
        this.router.navigate(['ui', 'my-evd', 'documents']);
    }

    private handleKlpLogin(userAuth) {
       this.userService.loginWithKLP(userAuth.username, userAuth.password)
           .pipe(
               concatMap(customerLoginResult => this.userService.linkCustomer(customerLoginResult.customerId).pipe(map(() => customerLoginResult)))
           )
           .subscribe(customerLoginResult => {
               const targetUrl = `/ui/my-evd/accounts/${customerLoginResult.customerId}/documents`;
               this.router.navigate([targetUrl ? targetUrl : '/']);
           }, () => {
               const modalInstance = this.modalService.open(WrongLoginDialogComponent, {size: 'lg'});
               modalInstance.componentInstance.isFromKlp = true;
           });
   }

    private getCustomers() {
        this.customers = [];
        this.customersSession = [];
        this.loadingDialog.show();
        this.userService.getUserCustomers()
            .pipe(
                map(c => c || []),
                switchMap((customerInfos: UserCustomerInfo[]) => {
                    this.userCustomers = customerInfos;
                    if (customerInfos.length > 0) {
                        return this.getUserCustomers().pipe(
                            map(({linked, nonLinked}) => ({customerInfos, linked, nonLinked}))
                        );
                    } else { // no customers linked
                        return throwError(NO_CUSTOMERS_ERR);
                    }
                })
            )
            .subscribe(({customerInfos, linked, nonLinked}) => {
                    this.userCustomers = customerInfos;
                    this.customers = linked;
                    this.customersSession = nonLinked;
                    this.loadingDialog.hide();
            }, error => {
                if (error === NO_CUSTOMERS_ERR || error.status === 403) {
                    this.loadingDialog.hide();
                    this.router.navigate(['/ui/login']);
                }
            });
    }

    /**
     * Return both list of linked and nonLinked customers
     */
    private getUserCustomers(): Observable<{linked: Customer[], nonLinked: Customer[]}> {
        return this.customerService.getUserCustomers().pipe(
            map((res: any) => {
                return res._embedded.customers.reduce((acc, cu) => {
                    if (this.isCustomerLinked(cu.customerId)) {
                        return {...acc, linked: [...acc.linked, cu]};
                    } else {
                        return {...acc, nonLinked: [...acc.nonLinked, cu]};
                    }
                }, {linked: [], nonLinked: []});
            })
        );
    }
}
