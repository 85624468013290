import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LoadingSpinnerDialogService} from '../../../common/loading-spinner-dialog/loading-spinner-dialog.service';
import {Carrier} from "../../../customer/models/carrier";
import {UserService} from "../../../security/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Component({
    selector: 'evv-customer-care-home',
    templateUrl: 'customer-care-home.component.html',
    styleUrls: ['customer-care-home.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class CustomerCareHomeComponent implements OnInit {
    carriers: Carrier[];
    currentCarrier: Carrier = null;
    isAdmin: boolean;
    isTnt: boolean;

    activeTab$:  Observable<string>;

    onLoadStop() {
        this.loadingDialog.hide();
    }

    onLoadStart() {
        this.loadingDialog.show();
    }

    constructor(
                private loadingDialog: LoadingSpinnerDialogService,
                private userService: UserService,
                private translateService: TranslateService,
                private route: ActivatedRoute,
                private router: Router) {
    }
    /* eslint-disable */
    ngOnInit(): void {
        this.activeTab$ = this.route.url.pipe(map(url => {
            if (url.length > 0) {
                switch (url[url.length-1].path) {
                    case 'customers-list':
                        return 'tab-search-customers';
                    case 'documents-list':
                        return 'tab-search-documents';
                    case 'event-log':
                        return 'tab-event-log';
                    case 'carrier-profile':
                        return 'tab-carrier-profile';
                    case 'assist-metadata':
                        return 'tab-assist-metadata'
                    case 'add-tnt-cc-user':
                        return 'tab-add-tnt-cc-user'
                    default:
                        return "tab-search-customers";
                }
            } else {
                return 'tab-search-customers';
            }
        }));
        this.carriers = this.userService.getCurrentUser().carriers.map(c => c.name === 'PV' ? {...c, name: 'PVZ'}: c);
        if (this.carriers.length > 1) {
            this.currentCarrier = null;
        } else if(this.carriers.length === 1 && this.carriers[0].name.toUpperCase() === "TNT") {
            this.isTnt = true;
        }

        this.isAdmin = this.userService.isUserCCAdmin();
    }

    tabChanged(nextId) {
        // let tabPath;
        switch (nextId) {
            case 'tab-search-customers':
                this.router.navigate(['ui', 'cca', 'customers', 'customers-list']);
                break;
            case 'tab-search-documents':
                this.router.navigate(['ui', 'cca', 'documents', 'documents-list']);
                break;
            case 'tab-event-log':
                this.router.navigate(['ui', 'cca', 'admin', 'event-log']);
                break;
            case 'tab-carrier-profile':
                this.router.navigate(['ui', 'cca', 'admin', 'carrier-profile']);
                break;
            case 'tab-assist-metadata':
                this.router.navigate(['ui', 'cca', 'admin', 'assist-metadata']);
                break;
            case 'tab-add-tnt-cc-user':
                this.router.navigate(['ui', 'cca', 'admin', 'tnt-cc-user']);
                break;
        }
    }
}
