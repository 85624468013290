/*
 * ------------------------------------------------------------------------------------------------
 * Copyright 2014 by Swiss Post, Information Technology Services
 * ------------------------------------------------------------------------------------------------
 */

export interface Search<C> {
    criteria: C,
    index: number,
    rows: number,
    sort: string,
    order: Order
}

export enum Order {
    ASC = "asc",
    DESC = "desc"
}
