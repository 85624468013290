import {Component} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'evv-help-contact',
    templateUrl: './evv-help-contact.component.html',
    styleUrls: ['./evv-help-contact.component.scss']
})
export class EvvHelpContactComponent {
    constructor(private translateService: TranslateService) {
    }
    /* eslint-disable */
    sendTo(messageKey) {
        const emailAddress = this.translateService.instant(messageKey);
        window.open("mailto:" + this.translateService.instant(emailAddress));
    }

}
