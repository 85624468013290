import {Component} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'evd-anonymous-document-image-carousel',
    templateUrl: './anonymous-document-image-carousel.component.html'
})
export class AnonymousDocumentImageCarouselComponent  {

    constructor(private translateService: TranslateService) {
    }

    getImageClass(imgSuffix: string) {
        const lang = this.translateService.currentLang;
        return imgSuffix + lang.toLowerCase();
    }
}

