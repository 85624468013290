// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}

.klp-text {
  margin-top: 2px;
  display: inline-block;
}

legend {
  font-size: 1.3rem !important;
  font-weight: bold !important;
  padding-bottom: 1rem !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 1.5rem !important;
}

.btn-evd-blue {
  background-color: #0076a8 !important;
  border-color: transparent;
  color: #fff;
  font-weight: bold;
}

.alert-url-text {
  text-decoration: underline !important;
}

.alert-warning-background {
  background-color: #fce2b2;
}`, "",{"version":3,"sources":["webpack://./src/app/entry-page/components/welcome-page/welcome-page.component.scss","webpack://./../../../EHR-nightly-deploy%20manual/ehr/ehr-web-client/src/app/entry-page/components/welcome-page/welcome-page.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,uBAAA;EAAA,kBAAA;EACA,yBAAA;EACA,qBAAA;ACCJ;;ADEA;EACI,eAAA;EACA,qBAAA;ACCJ;;ADEA;EACI,4BAAA;EACA,4BAAA;EACA,+BAAA;EACA,sDAAA;EACA,gCAAA;ACCJ;;ADEA;EACI,oCAAA;EACA,yBAAA;EACA,WAAA;EACA,iBAAA;ACCJ;;ADCA;EACI,qCAAA;ACEJ;;ADAA;EACI,yBAAA;ACGJ","sourcesContent":["a {\n    cursor: pointer;\n    width: fit-content;\n    color: rgba(0, 0, 0, 0.6);\n    text-decoration: none;\n}\n\n.klp-text {\n    margin-top: 2px;\n    display: inline-block;\n}\n\nlegend {\n    font-size: 1.3rem !important;\n    font-weight: bold !important;\n    padding-bottom: 1rem !important;\n    border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;\n    margin-bottom: 1.5rem !important;\n}\n\n.btn-evd-blue {\n    background-color: #0076a8 !important;\n    border-color: transparent;\n    color: #fff;\n    font-weight: bold;\n}\n.alert-url-text {\n    text-decoration: underline !important;\n}\n.alert-warning-background {\n    background-color: #fce2b2\n}\n","a {\n  cursor: pointer;\n  width: fit-content;\n  color: rgba(0, 0, 0, 0.6);\n  text-decoration: none;\n}\n\n.klp-text {\n  margin-top: 2px;\n  display: inline-block;\n}\n\nlegend {\n  font-size: 1.3rem !important;\n  font-weight: bold !important;\n  padding-bottom: 1rem !important;\n  border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;\n  margin-bottom: 1.5rem !important;\n}\n\n.btn-evd-blue {\n  background-color: #0076a8 !important;\n  border-color: transparent;\n  color: #fff;\n  font-weight: bold;\n}\n\n.alert-url-text {\n  text-decoration: underline !important;\n}\n\n.alert-warning-background {\n  background-color: #fce2b2;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
