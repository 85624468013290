import {Component, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {EveuDocType, ExportDocType, ImportDocType} from "../../../document/models/document-file-type";
import {Carrier, ServiceTypeUpload} from "../../models/carrier";
import {ActivatedRoute, Router} from "@angular/router";
import {CcaFileUploaderService} from "../../services/cca-file-uploader.service";
import {HttpEvent, HttpEventType} from "@angular/common/http";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CcaUploadFileConfirmationComponent} from "../cca-upload-file-confirmation/cca-upload-file-confirmation.component";
import {checkDocTypeSameValidator} from "../../../common/check-doc-type-same.validator";
import {map, switchMap} from "rxjs/operators";
import {CustomerService} from "../../services/customer.service";
import {Customer} from "../../models/customer.interface";
import {Observable} from "rxjs";
import {UserService} from "../../../security/user.service";

@Component({
    selector: 'evv-cca-upload-file',
    templateUrl: 'cca-upload-file.component.html',
    styleUrls: ['cca-upload-file.component.css']
})
/* eslint-disable */
export class CcaUploadFileComponent implements OnInit {

    customerId: number;
    ccaUploadFileForm: FormGroup;
    serviceTypes: ServiceTypeUpload[];
    importDocTypes: ImportDocType[];
    exportDocTypes: ExportDocType[];
    eveuDocTypes: EveuDocType[];
    docTypes: any;
    fileName: string;
    progress: number = 0;
    infoMessage: any;
    isUploading: boolean = false;
    docTypeDropDownShown: boolean = true;
    customer$:  Observable<Customer>;
    currentCarr: Carrier;
    constructor(private formBuilder: FormBuilder, private translateService: TranslateService,
                private router: Router,
                private route: ActivatedRoute,
                private ccaFileUploaderService: CcaFileUploaderService,
                private modalService: NgbModal,
                private activatedRoute: ActivatedRoute,
                private customerService: CustomerService,
                private userService: UserService) {
        this.ccaUploadFileForm = this.formBuilder.group({
            shipmentNumber: new FormControl(null, Validators.required),
            serviceType: new FormControl(null, Validators.required),
            docType: new FormControl(null, Validators.required),
            docFile: new FormControl(null, Validators.required),
            fileSource: new FormControl('', [Validators.required]),
            fileName: new FormControl(null)
        }, {validators: checkDocTypeSameValidator('fileName', 'docType')});
    }

    ngOnInit() {
        this.customer$ = this.activatedRoute.params.pipe(
            map(params => {
                this.customerId = params['customerId'];
                return this.customerId;
            }),
            switchMap(id => this.customerService.getCustomer(id))
        ).pipe(map((cust: Customer) =>  {
            this.currentCarr = this.userService.getCurrentUser().carriers.filter(c => c.id === cust.carrId)[0];
            const isExportMode = !!this.currentCarr && this.currentCarr.serviceType === "EXPORT";
            if (isExportMode) {
                this.serviceTypes = [ServiceTypeUpload.EXPORT];
                this.ccaUploadFileForm.patchValue({'serviceType': ServiceTypeUpload.EXPORT});
            }
            return cust;
        }));
        this.serviceTypes = Object.keys(ServiceTypeUpload).map(k => ServiceTypeUpload[k as any] as ServiceTypeUpload);
        this.importDocTypes = Object.keys(ImportDocType).map( k => ImportDocType[k as any] as ImportDocType);
        this.eveuDocTypes = Object.keys(EveuDocType).map( k => EveuDocType[k as any] as EveuDocType);
        this.exportDocTypes = Object.keys(ExportDocType).map (k => ExportDocType[k as any] as ExportDocType);
        this.fileName = this.translateService.instant("customer.upload-file-label.default");
    }

    onServiceTypeChanged() {
        if (this.serviceType === ServiceTypeUpload.EVEU) {
            this.docTypeDropDownShown = false;
            this.docTypes = this.eveuDocTypes;
            this.setShipmentNumber4EVEU(true);

        } else if (this.serviceType === ServiceTypeUpload.EXPORT) {
            this.docTypeDropDownShown = true;
            this.docTypes = this.exportDocTypes;
            this.setShipmentNumber4EVEU(false);

        } else if (this.serviceType === ServiceTypeUpload.IMPORT) {
            this.docTypeDropDownShown = true;
            if (this.currentCarr.name === "GLS") {
                this.docTypes = this.importDocTypes.filter(t => t.toLowerCase() !== ImportDocType.PR_PDF);
            } else if (this.currentCarr.name === "PVZ") {
                this.docTypes = this.importDocTypes.filter(t => t.toLowerCase() !== ImportDocType.HR_PDF);
            }
            this.setShipmentNumber4EVEU(false);
        }
    }

    get serviceType(): ServiceTypeUpload {
        return this.ccaUploadFileForm.get("serviceType").value;
    }

    onDocFileChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.fileName = file.name;
            if (this.serviceType === ServiceTypeUpload.EVEU) {
               this.setShipmentNumber4EVEU(true);
            } else {
                this.setShipmentNumber4EVEU(false);
            }
            this.ccaUploadFileForm.patchValue({
                fileSource: file,
                fileName: file.name
            });
           this.ccaUploadFileForm.get('fileSource').updateValueAndValidity();
        }
    }

    handleSubmit() {
        this.infoMessage = null;
        this.progress = 0;
        this.isUploading = true;
        if (this.docType == null && this.serviceType === ServiceTypeUpload.EVEU) {
            this.ccaUploadFileForm.get('docType').patchValue(EveuDocType.PDF_EVEU);
        }

        const modalInstance = this.modalService.open(CcaUploadFileConfirmationComponent, { size: 'lg' });
        modalInstance.componentInstance.title = "cca-upload-file.section.cca-upload-file.title";
        modalInstance.componentInstance.description = "cca-upload-file.section.cca-upload-file.description";
        modalInstance.componentInstance.serviceType = this.translateService.instant("document.service-types." + this.serviceType.toLocaleLowerCase());
        modalInstance.componentInstance.docType = this.translateService.instant("document.dropdown." + this.docType);
        modalInstance.componentInstance.fileName = this.fileName;
        modalInstance.componentInstance.approve.subscribe(isApproved => {
            if (isApproved) {
                this.ccaFileUploaderService.upload(this.customerId, this.fileSource, this.serviceType, this.docType, this.shipmentNumber.trim())
                    .subscribe ((event: HttpEvent<any>) => {
                            let message;
                            switch (event.type) {
                                case HttpEventType.Sent:
                                    message = `Uploading file.`;
                                    break;
                                case HttpEventType.UploadProgress:
                                    this.progress = Math.round( event.loaded / event.total * 100);
                                    message = `Uploaded! ${this.progress}%`;
                                    break;
                                case HttpEventType.Response:
                                    message = `File was completely uploaded!`;
                                    setTimeout(() => {
                                        this.progress = 0;
                                    }, 1500);
                            }
                            this.isUploading = false;
                            this.infoMessage = message;
                        }
                    );
            }
        });

    }

    handleReset() {
        this.router.navigate(['ui', 'cca']);
    }

    get docFile(): FormControl {
        return this.ccaUploadFileForm.get("docFile") as FormControl;
    }

    get fileSource(): File {
        return this.ccaUploadFileForm.get("fileSource").value;
    }

    get docType(): any {
        return this.ccaUploadFileForm.get("docType").value;
    }

    get shipmentNumber(): string {
        return this.ccaUploadFileForm.get("shipmentNumber").value;
    }

    get shipmentNumberCtrl(): FormControl {
        return this.ccaUploadFileForm.get("shipmentNumber") as FormControl;
    }

    getSendingNumber(fileName: string): string {
        if (fileName != null && fileName.length > 0) {
            if (fileName.lastIndexOf("_50498_") > 0) {
                const theSecondPart = fileName.substring(fileName.lastIndexOf("_50498_") + 7);
                if (theSecondPart.indexOf("_") > 0) {
                    return theSecondPart.substring(0, theSecondPart.indexOf("_"));
                } else if (theSecondPart.indexOf(".") > 0) {
                    return theSecondPart.substring(0, theSecondPart.indexOf("."));
                }
            }
        }
        return null;
    }

    setShipmentNumber4EVEU(isServiceTypeEveu: boolean) {
        const fileName = this.fileName;
        if (isServiceTypeEveu && fileName != null) {
            const nrFromFileName = this.getSendingNumber(this.fileName);
            if (nrFromFileName != null) {
                this.shipmentNumberCtrl.patchValue(nrFromFileName);
            }
        } else {
            this.shipmentNumberCtrl.patchValue(null);
        }
    }
}
