/*
 * ------------------------------------------------------------------------------------------------
 * Copyright 2014 by Swiss Post, Information Technology Services
 * ------------------------------------------------------------------------------------------------
 */

import {Component, Input} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: '[evd-table-sort-label]',
    template: `{{labelKey | translate}}
    <p-sortIcon [field]="field"></p-sortIcon>`,
    styles: []
})
export class TableSortIconComponent {
    @Input()
    field: string;
    @Input()
    labelKey: string;
    /* eslint-disable */
    get fieldLabel() {
        return this.translate.instant(this.labelKey);
    }

    constructor(private translate: TranslateService) {
    }
}
