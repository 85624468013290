/*
 * Copyright 2023 by Swiss Post, Information Technology
 */
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {LanguageSelectionService} from "../../../security/language.selection.service";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

/* eslint-disable */

@Component({
    selector: 'evd-step-support',
    templateUrl: './step-support.component.html',
    styleUrls: ['./step-support.component.scss']
})
export class StepSupportComponent implements OnInit, OnDestroy {

    private languageSelSub: Subscription;
    lang: string;

    constructor( private router: Router, private languageSelectService: LanguageSelectionService,
                 private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.languageSelSub = this.languageSelectService.getSelectedLanguage().subscribe(selLang =>{
            this.lang = selLang != null ? selLang : this.translateService.currentLang;
        });
    }
    ngOnDestroy(): void {
        if (this.languageSelSub) {
            this.languageSelSub.unsubscribe();
        }
    }

    onButtonYes() {
        this.router.navigate(['ui', 'login', 'mailpiece']);
    }

    onButtonNo(): void {
        this.router.navigate(['ui', 'login', 'step-support-gls']);
    }

    gotoQrScanner():void  {
        this.router.navigate(['ui', 'login', 'qr-code-scanner']);
    }

    glsLogin():void  {
        this.router.navigate(['ui', 'login', 'customer'], {queryParams:{customerNr: '', domain: 'gls'}});
    }

    pvzLogin():void  {
        this.router.navigate(['ui', 'login', 'customer'], {queryParams:{customerNr: '', domain: 'pvz'}});
    }

    urgentLogin():void  {
        this.router.navigate(['ui', 'login', 'customer'], {queryParams:{customerNr: '', domain: 'urgent'}});
    }

    anonymoousLogin():void  {
        this.router.navigate(['ui', 'login', 'mailpiece']);
    }
}



