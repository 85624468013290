import {FieldFilter} from "./pipes/field-filter.pipe";
import {NgModule} from "@angular/core";

@NgModule({
    declarations: [
        FieldFilter
    ],
    exports: [
        FieldFilter
    ]
})
export class NotificationsModule {
}
