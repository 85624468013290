// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centered-checkbox {
    margin-left: 0.5rem;
    margin-top: -14px;
}

.es {
    width: 31px;
    zoom: 0.7;
    min-height: 32px;
    background-color: black;
    background-size: 32px 32px;
}

.btn-link {
    padding: 0.5rem !important;
}

.alert .icon {
    display: table-cell;
    margin-left: -8px;
    margin-right: 10px;
    margin-top: 1px;
    vertical-align: middle;
    padding-right: 10px;
}

.alert .message {
    display: table-cell;
}

.alert .message p {
    margin-bottom: 0.4rem;
}

.tooltip-info {
    margin-left: 0.5rem;
}

@media (max-width: 576px) {
    th.ui-p-3,
    td.ui-p-3,
    th.ui-p-2,
    td.ui-p-2,
    th.ui-p-1,
    td.ui-p-1 {
        display: table-cell;
    }
}
`, "",{"version":3,"sources":["webpack://./src/app/document/containers/document-anonymous-list/document-anonymous-list.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,SAAS;IACT,gBAAgB;IAChB,uBAAuB;IACvB,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI;;;;;;QAMI,mBAAmB;IACvB;AACJ","sourcesContent":[".centered-checkbox {\n    margin-left: 0.5rem;\n    margin-top: -14px;\n}\n\n.es {\n    width: 31px;\n    zoom: 0.7;\n    min-height: 32px;\n    background-color: black;\n    background-size: 32px 32px;\n}\n\n.btn-link {\n    padding: 0.5rem !important;\n}\n\n.alert .icon {\n    display: table-cell;\n    margin-left: -8px;\n    margin-right: 10px;\n    margin-top: 1px;\n    vertical-align: middle;\n    padding-right: 10px;\n}\n\n.alert .message {\n    display: table-cell;\n}\n\n.alert .message p {\n    margin-bottom: 0.4rem;\n}\n\n.tooltip-info {\n    margin-left: 0.5rem;\n}\n\n@media (max-width: 576px) {\n    th.ui-p-3,\n    td.ui-p-3,\n    th.ui-p-2,\n    td.ui-p-2,\n    th.ui-p-1,\n    td.ui-p-1 {\n        display: table-cell;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
