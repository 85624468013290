import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable()
/* eslint-disable */
export class LanguageSelectionService {

    private selectedLanguage$: BehaviorSubject<string> = new BehaviorSubject(null);

    getSelectedLanguage(): Observable<string> {
        return this.selectedLanguage$.asObservable();
    }

    setSelectedLanguage(lang: string) {
        this.selectedLanguage$.next(lang);
    }
}
