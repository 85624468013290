/* eslint-disable */
export function setActiveLang() {

    const supportedLangs = ['de', 'fr', 'it', 'en'];

    function getUrlParameterByName(name) {
        const url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`), results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    const storage = window.sessionStorage;
    let lang = getUrlParameterByName('lang');
    if (!lang) {
        lang = storage.getItem('evv.app.language');
        if (!lang) {
            lang = navigator.language;
        }

    }

    lang = supportedLangs.filter(function (value) {
        return value === lang;
    })[0] || "de";

    storage.setItem('evv.app.language', lang);
    document.documentElement.lang = lang;
}

