import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Event} from "../../model/event.interface";
import {Carrier} from "../../../customer/models/carrier";

@Component({
    selector: 'app-event-detail-modal',
    styleUrls: ['event-detail-modal.component.scss'],
    templateUrl: './event-detail-modal.component.html'
})
export class EventDetailModalComponent {
    @Input() event: Event;
    @Input() carriers: Carrier[];

    constructor(public modal: NgbActiveModal) {
    }

    getCarrier(carrierId: number) {
        return carrierId && this.carriers.find(c => c.id === carrierId);
    }
}
