/**
 * ------------------------------------------------------------------------------------------------
 * Copyright 2014 by Swiss Post, Information Technology Services
 * ------------------------------------------------------------------------------------------------
 */

import {NgModule} from "@angular/core";
import {EventLogComponent} from "./containers/event-log/event-log.component";
import {CarrierProfileComponent} from "./containers/carrier-profil/carrier-profile.component";
import {CommonModule} from "@angular/common";
import {TableModule} from "primeng/table";
import {TableUtilModule} from "../common/table/table-util.module";
import {TranslateModule} from "@ngx-translate/core";
import {ReactiveFormsModule} from "@angular/forms";
import {NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonDirectivesModule} from "../common/directives/common-directives.module";
import {EventDetailModalComponent} from './components/event-detail/event-detail-modal.component';
import {CarrierProfileFormComponent} from "./components/carrier-profile-form/carrier-profile-form.component";
import {AdminService} from "./service/admin.service";
import {CarrierProfileDetailComponent} from "./containers/carrier-profile-detail/carrier-profile-detail.component";
import {AssistMetadataComponent} from "./containers/assist-metadata/assist-metadata.component";
import {TntCcUserComponent} from "./containers/tnt-cc-user/tnt-cc-user.component";

@NgModule({
    declarations: [
        EventLogComponent,
        CarrierProfileComponent,
        EventDetailModalComponent,
        CarrierProfileFormComponent,
        CarrierProfileDetailComponent,
        AssistMetadataComponent,
        TntCcUserComponent
    ],
    imports: [
        CommonModule,
        TableModule,
        TableUtilModule,
        TranslateModule,
        ReactiveFormsModule,
        NgbDatepickerModule,
        CommonDirectivesModule
    ],
    exports: [
        EventLogComponent,
        CarrierProfileComponent,
        CarrierProfileDetailComponent,
        AssistMetadataComponent,
        TntCcUserComponent
    ],
    providers: [
        AdminService
    ]
})
export class AdminModule {

}
