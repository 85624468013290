import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Component, EventEmitter, Output, ViewChild} from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormGroupDirective,
    Validators
} from "@angular/forms";
import {TealiumUtagService} from "../../../common/tealium-utag.service";
import {TranslateService} from "@ngx-translate/core";


const USERNAME_PATTERN="^[A-Za-z0-9]*$";
/* eslint-disable */
@Component({
    selector: 'evv-link-customer-modal',
    templateUrl: 'link-customer-modal.component.html',
    styleUrls: ['./link-customer-modal.component.scss']
})
export class LinkCustomerModalComponent {
    loginForm: FormGroup;
    @Output()
    linkAccount: EventEmitter<any> = new EventEmitter();

    @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
    constructor(public modal: NgbActiveModal,
                private formBuilder: FormBuilder,
                private tealiumService: TealiumUtagService,
                private translateService: TranslateService) {
        this.loginForm = this.formBuilder.group({
            username: new FormControl(null, [Validators.required, Validators.pattern(USERNAME_PATTERN)]),
            password: new FormControl(null, Validators.required)
        });

    }

    handleCancel() {
        this.linkAccount.complete();
        this.modal.dismiss();
        //this.formGroupDirective.resetForm();
    }

    switchPasswordVisibility(password: HTMLInputElement) {
        password.type = password.type === 'password' ? 'text' : 'password'
    }

    showPasswordIcon(password: HTMLInputElement) {
        return password.type === 'password' ? 'pi-2114' : 'pi-2115';
    }

    handleLinkAccount() {
        if (!this.loginForm.valid) {
            return;
        }
        this.tealiumService.link({
            event_category_primaryCategory: 'evd verknuepfrn',
            event_attributes_loc: 'overlay',
            event_eventInfo_cause: 'click',
            event_eventInfo_effect: 'connect',
            event_attributes_tgtURL : 'unknown',
            event_eventInfo_eventLabel: this.translateService.instant('customer.login.buttons.link')
        });
        const userAuth = this.loginForm.value;
        this.linkAccount.emit(userAuth);
        this.linkAccount.complete();
        this.modal.dismiss();
    }

}
