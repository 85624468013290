/*
 * Copyright 2023 by Swiss Post, Information Technology
 */
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from '@angular/common';
import {
    NgxScannerQrcodeComponent,
    NgxScannerQrcodeService,
    ScannerQRCodeConfig,
    ScannerQRCodeResult, ScannerQRCodeSelectedFiles
} from "ngx-scanner-qrcode";

@Component({
    selector: 'evd-qr-code-scanner',
    templateUrl: './qr-code-scanner.component.html',
    styleUrls: ['qr-code-scanner.component.scss']
})
/* eslint-disable */
export class QrCodeScannerComponent implements AfterViewInit{
    public config: ScannerQRCodeConfig = {
        constraints: {
            video: {
                width: window.innerWidth
            },
        },
    };
    public qrCodeResult: ScannerQRCodeSelectedFiles[] = [];

    @ViewChild('action') action!: NgxScannerQrcodeComponent;

    public percentage = 80;
    public quality = 100;

    output: string;

    constructor( private router: Router, private activatedRoute: ActivatedRoute, private translateService: TranslateService,
                 private location: Location, private qrcodeService: NgxScannerQrcodeService) {
    }

    ngAfterViewInit(): void {
        this.action.isReady.subscribe((res: any) => {
            // this.handle(this.action, 'start');
        });
    }

    public onEvent(e: ScannerQRCodeResult[], action?: any): void {
        // e && action && action.pause();
        // console.log(e);
        this.output = e!== null ? e[0].value : '';
    }

    public handle(action: any, fn: string): void {
        const playDeviceFacingBack = (devices: any[]) => {
            // front camera or back camera check here!
            const device = devices.find(f => (/back|rear|environment/gi.test(f.label))); // Default Back Facing Camera
            action.playDevice(device ? device.deviceId : devices[0].deviceId);
        }

        if (fn === 'start') {
            action[fn](playDeviceFacingBack).subscribe((r: any) => console.log(fn, r), alert);
        } else {
            action[fn]().subscribe((r: any) => console.log(fn, r), alert);
        }
    }

    public onSelects(files: any) {
        this.qrcodeService.loadFiles(files, this.percentage, this.quality).subscribe((res: ScannerQRCodeSelectedFiles[]) => {
            this.qrCodeResult = res;
        });
    }

    back() {
        this.location.back();
    }

    toWelcomePage() {
        this.router.navigate(['ui', 'login']);
    }
}



