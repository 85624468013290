/*
 * ------------------------------------------------------------------------------------------------
 * Copyright 2014 by Swiss Post, Information Technology Services
 * ------------------------------------------------------------------------------------------------
 */

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {UserService} from './user.service';
import {Observable, of} from 'rxjs';
import {Role} from "./role";
import {map, mergeMap} from "rxjs/operators";

/**
 * Check if the user has IDP_KLP role.
 * If yes, allow navigating to the route, otherwise, if the user is a customer, navigate to ./:customerId
 */
@Injectable()
export class MyAccountGuard implements CanActivate {

    constructor(private userService: UserService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
        return this.userService.canActivate([Role.IDP_KLP]).pipe(
            mergeMap(result => {
                if (result) {
                    return of(result);
                } else {
                    return this.userService.canActivate([Role.EVV_CUSTOMER]).pipe(
                        map(isCustomer => {
                                const customers = this.userService.getCurrentUser().customers;
                                if (isCustomer && customers && customers.length == 1) {
                                    return this.router.createUrlTree([state.url, customers[0].id]);
                                } else {
                                    return false;
                                }
                            }
                        )
                    );
                }
            })
        );
    }
}
