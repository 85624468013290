/*
 * Copyright 2023 by Swiss Post, Information Technology
 */
import {Component, Input} from "@angular/core";

@Component({
    selector: 'evd-pvz-login-form',
    templateUrl: './pvz-login-form.component.html',
    styleUrls: ['pvz-login-form.component.scss']
})
/* eslint-disable */
export class PvzLoginFormComponent {
    @Input()
    inTab: boolean;
}

