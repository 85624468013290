// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `legend {
  font-size: 1.3rem !important;
  font-weight: bold !important;
  padding-bottom: 1rem !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 1.5rem !important;
}

.tooltip-info-evd-login {
  align-self: self-end;
}

.tooltip-flex-end {
  display: inline-flex;
  float: right;
}

.btn-change-password {
  padding-left: 0 !important;
  color: #666 !important;
}

.btn-change-password :hover {
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/app/entry-page/containers/customer-login-process.component.scss","webpack://./../../../EHR-nightly-deploy%20manual/ehr/ehr-web-client/src/app/entry-page/containers/customer-login-process.component.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,4BAAA;EACA,+BAAA;EACA,sDAAA;EACA,gCAAA;ACCJ;;ADEA;EACI,oBAAA;ACCJ;;ADEA;EACI,oBAAA;EACA,YAAA;ACCJ;;ADCA;EACI,0BAAA;EACA,sBAAA;ACEJ;;ADAA;EACI,WAAA;ACGJ","sourcesContent":["legend {\n    font-size: 1.3rem !important;\n    font-weight: bold !important;\n    padding-bottom: 1rem !important;\n    border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;\n    margin-bottom: 1.5rem !important;\n}\n\n.tooltip-info-evd-login {\n    align-self: self-end;\n}\n\n.tooltip-flex-end {\n    display: inline-flex;\n    float: right;\n}\n.btn-change-password {\n    padding-left: 0 !important;\n    color: #666 !important;\n}\n.btn-change-password :hover {\n    color:#000;\n}\n","legend {\n  font-size: 1.3rem !important;\n  font-weight: bold !important;\n  padding-bottom: 1rem !important;\n  border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;\n  margin-bottom: 1.5rem !important;\n}\n\n.tooltip-info-evd-login {\n  align-self: self-end;\n}\n\n.tooltip-flex-end {\n  display: inline-flex;\n  float: right;\n}\n\n.btn-change-password {\n  padding-left: 0 !important;\n  color: #666 !important;\n}\n\n.btn-change-password :hover {\n  color: #000;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
