// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}

.klp-text {
  margin-top: 2px;
  display: inline-block;
}

legend {
  font-size: 1.3rem !important;
  font-weight: bold !important;
  padding-bottom: 1rem !important;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 1.5rem !important;
}`, "",{"version":3,"sources":["webpack://./src/app/entry-page/components/login-with-klp/login-with-klp.component.scss","webpack://./../../../EHR-nightly-deploy%20manual/ehr/ehr-web-client/src/app/entry-page/components/login-with-klp/login-with-klp.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,uBAAA;EAAA,kBAAA;EACA,yBAAA;EACA,qBAAA;ACCJ;;ADEA;EACI,eAAA;EACA,qBAAA;ACCJ;;ADEA;EACI,4BAAA;EACA,4BAAA;EACA,+BAAA;EACA,sDAAA;EACA,gCAAA;ACCJ","sourcesContent":["a {\n    cursor: pointer;\n    width: fit-content;\n    color: rgba(0, 0, 0, 0.6);\n    text-decoration: none;\n}\n\n.klp-text {\n    margin-top: 2px;\n    display: inline-block;\n}\n\nlegend {\n    font-size: 1.3rem !important;\n    font-weight: bold !important;\n    padding-bottom: 1rem !important;\n    border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;\n    margin-bottom: 1.5rem !important;\n}\n\n\n\n","a {\n  cursor: pointer;\n  width: fit-content;\n  color: rgba(0, 0, 0, 0.6);\n  text-decoration: none;\n}\n\n.klp-text {\n  margin-top: 2px;\n  display: inline-block;\n}\n\nlegend {\n  font-size: 1.3rem !important;\n  font-weight: bold !important;\n  padding-bottom: 1rem !important;\n  border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;\n  margin-bottom: 1.5rem !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
