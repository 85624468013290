import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Component, Input} from "@angular/core";
import {ReceiptDetails} from "../../models/receipt-details.interface";
import {DocumentAnonymousSearchCriteria} from "../../models/document-anonymous-search-criteria.interface";
import {LoadingSpinnerDialogService} from "../../../common/loading-spinner-dialog/loading-spinner-dialog.service";
import {DocumentService} from "../../services/document.service";
import {TealiumUtagService} from "../../../common/tealium-utag.service";
import {TranslateService} from "@ngx-translate/core";
import {NotificationsService} from "../../../notifications/services/notifications.service";
import {DocumentSearchServiceType} from "../../models/document-search-criteria.interface";
import {Observable} from "rxjs";
import {HttpResponse} from "@angular/common/http";

@Component({
    selector: 'evv-receipt-details-modal',
    templateUrl: 'receipt-details-modal.component.html',
    styleUrls: ['./receipt-details-modal.component.scss']
})
/* eslint-disable */
export class ReceiptDetailsModalComponent {
    @Input()
    receiptDetails: ReceiptDetails;

    @Input()
    documentId: number;
    @Input()
    customerId: number;
    @Input()
    anonymousCriteria: DocumentAnonymousSearchCriteria;

    constructor(public modal: NgbActiveModal,
                private loadingDialog: LoadingSpinnerDialogService,
                private documentService: DocumentService,
                private tealiumService: TealiumUtagService,
                private translateService: TranslateService,
                private notificationsService:  NotificationsService) {
    }

    get isInImportMode(): boolean {
        return this.receiptDetails.receipt.serviceType === DocumentSearchServiceType.IMPORT;
    }

    downloadSingleDocumentById(id: number) {
        this.tealiumService.sendEventWithLocation('click', 'download ', 'single document with the document id: ' + id,
            'overlay', 'unknown',this.translateService.instant("app.title") + '-' + ' single document with the document id' + id);
        this.loadingDialog.show();
        //receiptId: number, documentId: string, customerId: number
        this.saveDownloadedFile(this.documentService.downloadSingleDocumentById(this.documentId, id, this.customerId, this.anonymousCriteria));
    }
    downloadAllDocuments() {
        this.tealiumService.sendEventWithLocation('click', 'download ', 'single document with all types: ',
            'overlay','unknown', this.translateService.instant("app.title") + '-'  + ' single document with all types');
        this.loadingDialog.show();

        this.saveDownloadedFile(this.documentService.downloadDocumentsZip([ this.documentId ], this.customerId, this.anonymousCriteria));
    }


    private saveDownloadedFile(downloadResult: Observable<HttpResponse<Blob>>) {
        downloadResult
            .subscribe(file => {
                DocumentService.saveFile(file);
                this.translateService.get('document.download.success').subscribe(t => {
                    this.notificationsService.showSuccessNotification(t);
                });
            }, () => {
                this.translateService.get('document.download.error').subscribe(t => {
                    this.notificationsService.showErrorNotification(t);
                });
            }, () => this.loadingDialog.hide());
    }
}
