// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.centered-checkbox {
    margin-left: 0.5rem;
    margin-top: -14px;
}

.tooltip-info {
    margin-left: 0.5rem;
}

.alert-container {
    z-index: -1 !important;
}
/*.frozen-col {
    position: sticky;
    position: -webkit-sticky;
}*/
@media (max-width: 576px) {
    th.ui-p-3,
    td.ui-p-3,
    th.ui-p-2,
    td.ui-p-2,
    th.ui-p-1,
    td.ui-p-1 {
        display: table-cell;
    }
}
`, "",{"version":3,"sources":["webpack://./src/app/document/components/document-list/document-list.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;AAC1B;AACA;;;EAGE;AACF;IACI;;;;;;QAMI,mBAAmB;IACvB;AACJ","sourcesContent":[".centered-checkbox {\n    margin-left: 0.5rem;\n    margin-top: -14px;\n}\n\n.tooltip-info {\n    margin-left: 0.5rem;\n}\n\n.alert-container {\n    z-index: -1 !important;\n}\n/*.frozen-col {\n    position: sticky;\n    position: -webkit-sticky;\n}*/\n@media (max-width: 576px) {\n    th.ui-p-3,\n    td.ui-p-3,\n    th.ui-p-2,\n    td.ui-p-2,\n    th.ui-p-1,\n    td.ui-p-1 {\n        display: table-cell;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
