import {HttpErrorResponse} from '@angular/common/http';
import {Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {NotificationsService} from './notifications/services/notifications.service';
import {UserService} from './security/user.service';
import {User} from './security/user.interface';
import {ClientConfig} from "./common/client-config.model";
import {CLIENT_CONFIG} from "./common/constants";
import {TealiumUtagService} from "./common/tealium-utag.service";
import {Subscription} from "rxjs";
import {RouteDataService} from "./common/route-data.service";


const SHOW_WITHOUT_HEADER_HOSTS=['www.post.ch', 'int.post.ch'];
export interface PostUser {
    userId: string;
    roles: string;
}

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
    @ViewChild("dismissible") dismissible: ElementRef;
    initialLanguage = 'de';
    configuration: any;
    evvHeaderHidden: boolean;
    routeChange$: Subscription;

    constructor(@Inject(LOCALE_ID) public language: string,
                @Inject(CLIENT_CONFIG) public clientConfig: ClientConfig,
                private translateService: TranslateService,
                private notificationsService: NotificationsService,
                private toastr: ToastrService,
                private route: ActivatedRoute,
                private userService: UserService,
                private tealiumService: TealiumUtagService,
                private routeDataService:  RouteDataService) {
        this.tealiumService.setConfig(clientConfig);
        // Handle unexpected errors
        this.tealiumService.view({event_name: 'init'});

        this.notificationsService.unexpectedErrorEvent.subscribe((response: HttpErrorResponse) => {
            console && console.error && console.error('Unexpected error', response);
            /* eslint-disable */
            this.toastr.error(this.translateService.instant('template.error.generic') + ': ' + response.message,
                response.status + ' - '  + response.statusText);
        });
    }

    get hideKlpWidget(): boolean {
        return this.userService.isLoggedIn() && !this.userService.isUserIdpLoggedIn();
    }

    get postUser(): PostUser {
        return {
            userId: this.currentUserId,
            roles: this.roles
        };
    }

    get currentUser(): User {
        return this.userService.getCurrentUser();
    }

    get currentUserId(): string {
        if (!this.userService.isLoggedIn()) return null;

        const username = this.userService.getCurrentUser().username;
        if (!username.includes("@")) {
            return username;
        }

        return username.split("@")[0];
    }

    get roles(): string {
        if (!this.currentUser) return null;
        let roles = this.currentUser.roles;
        roles = roles.filter(r => r.includes("ROLE_EVV_"));
        const test = [];
        roles.forEach(r => {
            test.push(r.replace(/ROLE_EVV_/g, ""));
        });

        return test.join(', ');
    }

    get carriers(): string {
        if (!this.currentUser) return null;
        return this.currentUser.carriers.map(ca => ca.name).toString();
    }

    logout() {
        this.userService.logoutFromServer();
    }

    public ngOnInit() {
       this.route.queryParams.
             subscribe(params => {
                    const lang = params['lang'];
                    let usedLang = lang || null;
                    if (usedLang != null) {
                        this.handleLanguageChange(usedLang);
                    } else {
                        const langTmp = sessionStorage.getItem('evv.app.language') == null  ? this.initialLanguage : sessionStorage.getItem('evv.app.language');
                        this.handleLanguageChange(langTmp);
                    }
                });

        const host = window.location.host;
        if(SHOW_WITHOUT_HEADER_HOSTS.findIndex(h => h === host.toLowerCase()) > -1) {
            this.evvHeaderHidden = true;
        }
        if(this.clientConfig.instance === 'klp') {
            /* eslint-disable */
            this.headerSetup().then();
        }

    }

    public handleLanguageChange(lang: string) {
        sessionStorage.setItem('evv.app.language', lang);
        this.language = lang;
        this.translateService.use(lang);
    }

    public handleKlpLogin(isKLPLoggedIn: boolean) {
        if(this.userService.isUserIdpLoggedIn() !== isKLPLoggedIn) {
            // TODO: redirect to step up
        }
    }

    public ngOnDestroy() {
        if (this.routeChange$) {
            this.routeChange$.unsubscribe();
        }
    }


    get evdLoggedIn() {
        return this.userService.isLoggedIn() && !this.userService.isUserIdpLoggedIn();
    }
    /* eslint-disable */
    toggleFixedAlert() {
        if (this.dismissible != undefined) {
            let displayStyle = this.dismissible.nativeElement.style.display;
            if (displayStyle != "none") {
                this.dismissible.nativeElement.style.display = "none";
            } else {
                this.dismissible.nativeElement.style.display = "";
            }
        }
    }

    async headerSetup(): Promise<void> {
        try {
            //const header = await this.getHeader();
            const header: HTMLSwisspostInternetHeaderElement = document.querySelector('swisspost-internet-header');

            await customElements.whenDefined(header.tagName.toLocaleLowerCase());

            // Set language switch url relative to current route
            this.setLanguageSwitchOverrides(header, window.location.href);
            this.routeChange$ = this.routeDataService.routeChange.subscribe(() => {
                this.setLanguageSwitchOverrides(header, window.location.href);
            });

            header.addEventListener('languageChanged', (event: CustomEvent) => {
                const lang = event.detail;
               this.handleLanguageChange(lang);
            });
        } catch (e) {
            console.error(`Failed to set header config: ${e}`);
        }
    }

    setLanguageSwitchOverrides(header: HTMLSwisspostInternetHeaderElement, baseUrl: string) {
        const langs = ['de', 'fr', 'it', 'en'];
        header.languageSwitchOverrides = langs.map((lang) => {
            const url = new URL(baseUrl);
            url.searchParams.set('lang', lang);
            return {lang, url: url.toString()};
        });
    }

}

