/*
 * ------------------------------------------------------------------------------------------------
 * Copyright 2014 by Swiss Post, Information Technology Services
 * ------------------------------------------------------------------------------------------------
 */

import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Carrier, CarrierEntity} from "../../../customer/models/carrier";
import {FormBuilder} from "@angular/forms";
import {forkJoin} from 'rxjs';
import {ActivatedRoute, Router} from "@angular/router";
import {AdminService} from "../../service/admin.service";
import {map} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";
import {NotificationsService} from "../../../notifications/services/notifications.service";

@Component({
    selector: 'evd-admin-carrier-profile',
    templateUrl: 'carrier-profile.component.html'
})
/* eslint-disable */
export class CarrierProfileComponent implements OnInit {

    @Input() public carriers: Carrier[];
    @Output() currentCarrierChange = new EventEmitter<Carrier>();

    carrierList: CarrierEntity[];

    constructor(private formBuilder: FormBuilder,
                private router: Router,
                private route: ActivatedRoute,
                private adminService: AdminService,
                private notificationsService: NotificationsService) {

    }

    ngOnInit() {
        this.loadCarriers();
    }

    private loadCarriers() {
        const singleCall = this.carriers.map(c => this.adminService.getCarrierProfile(c.id));
        forkJoin(...singleCall).pipe(map (v =>
            v.map(item => item.name === 'PV' ? {...item, name: 'PVZ'}: item)
        )).subscribe(
            result => this.carrierList = result,
            (response: HttpErrorResponse) => {
              this.notificationsService.handleHttpError(response);
        });
    }

    getCarrierProfileDetails(carrierProfile: CarrierEntity) {
        this.router.navigate(['./' + carrierProfile.carrierId], {relativeTo: this.route});
    }

}
