/*
 * ------------------------------------------------------------------------------------------------
 * Copyright 2014 by Swiss Post, Information Technology Services
 * ------------------------------------------------------------------------------------------------
 */

export interface Event {
    id: number,
    code: EventCode,
    type: EventType,
    description: string,
    timestamp: string,
    customerName: string,
    customerNumber: string,
    carrierId: number
}

export enum EventType {
    INFO = "INFO",
    WARNING = "WARNING",
    ERROR = "ERROR"
}

export enum EventCode {
    RECEIPT_IMPORT = "RECEIPT_IMPORT",
    RECEIPT_AUTO_DELIVERY = "RECEIPT_AUTO_DELIVERY",
    RECEIPT_DOWNLOAD = "RECEIPT_DOWNLOAD",
    EMAIL_VALIDATION = "EMAIL_VALIDATION",
    FDP_NODIR = "FDP_NODIR",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    PASSWORD_UPDATE = "PASSWORD_UPDATE",
    PASSWORD_RESET_EMAIL = "PASSWORD_RESET_EMAIL",
    PASSWORD_RESET = "PASSWORD_RESET",
    LINK_CUSTOMER = "LINK_CUSTOMER",
    UNLINK_CUSTOMER = "UNLINK_CUSTOMER",
    ADD_CUSTOMER_TO_SESSION = "ADD_CUSTOMER_TO_SESSION",
    RECEIPT_AUTO_DELIVERY_FTP = "RECEIPT_AUTO_DELIVERY_FTP",
    NXT_RECEIPT_IMPORT = "NXT_RECEIPT_IMPORT"
}

