import {Component, EventEmitter, Input, Output} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NotificationDialogComponent} from "../../../common/notification-dialog/notification-dialog.component";

@Component({
    selector: 'evv-cca-reset-password',
    templateUrl: 'cca-reset-password.component.html'
})
export class CcaResetPasswordComponent {

    @Input()
    parentForm: FormGroup;
    @Output()
    sendResetPassword: EventEmitter<any> = new EventEmitter<any>();
    constructor(private modalService: NgbModal) {
    }
    /* eslint-disable */
    sendResetPasswordClicked() {
        const modalInstance = this.modalService.open(NotificationDialogComponent, {size: 'lg'});
        modalInstance.componentInstance.title = "cca-send-reset-password-email.title";
        modalInstance.componentInstance.description = "cca-send-reset-password-email.description";
        modalInstance.componentInstance.approve.subscribe(isApproved => {
            if (isApproved) {
                this.sendResetPassword.emit(isApproved);
            }
        });
    }
}

