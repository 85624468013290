import {Component, Input, EventEmitter, Output, NgZone, Optional} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {User} from "../../security/user.interface";
import {UserService} from "../../security/user.service";

@Component({
    selector: 'evv-header',
    templateUrl: './evv-header.component.html',
    styleUrls: ['./evv-header.component.scss']
})
export class EvvHeaderComponent {
    @Output()
    changeLanguage: EventEmitter<string> = new EventEmitter<string>();

    @Input()
    language  = 'de';

    @Input()
    enableDynamicLinkHighlightning = false;

    @Input()
    evdLoggedIn  = false;

    @Input()
    evdUser: User;
    _configuration?: Config | null;

    languageLinks = {de: '?lang=de', fr: '?lang=fr', it: '?lang=it', en: '?lang=en'};

    //
    address: any = undefined;
    //

    isDropdownOpenOnMobile = false;
    openedLangChooser = false;

    constructor(
        private httpClient: HttpClient,
        private zone: NgZone,
        @Optional() private router: Router,
        private userService: UserService) {
        this.openedLangChooser = false;
    }

    public isActive(url: NavLinkBase) {
        if(!this.isInternalUrl(url) || !this.enableDynamicLinkHighlightning) {
            return url.isActive;
        }

        if(!this.router)
            return url.isActive;

        return this.router.isActive(url.url, false);
    }

    @Input()
    set configuration(config: Config) {
        this._configuration = config;
    }

    get configuration(): Config {
        return this._configuration;
    }


    emitChangeLanguage(language: string, $event: Event) {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
        this.changeLanguage.emit(language);
        this.language = language;
    }

    // get the others language (except the current one)
    /* eslint-disable */
    get otherLanguagesLinks() : Array<LangLink> {
        return ['de','fr','it','en']
            .filter(l=>l !== this.language)
            .map(l => ({lang: l, url: this.languageLinks[l]}));
    }

    langTrack(index: number, obj: any) {
        return obj.lang;
    }

    isInternalUrl(url: NavLinkBase) {
        if(!url.isInternalUrl) {
            return false;
        }

        return !/^((http|https):\/\/)/.test(url.url);
    }

    showAngelUpOrDown() {
        return this.openedLangChooser === true ? 'pi-2051' : 'pi-2052';
    }

    navigateToUserProfile() {
        this.router.navigate(['/ui', 'my-evd', 'accounts', this.evdUser.customers[0].id]);
    }

    logout() {
        this.userService.logoutFromServer();
    }
}

function safeJSONParse(val : string) : any {
    try {
        return JSON.parse(val);
    } catch(e) {
        return null;
    }
}

export interface Config {
    config?: SiteCoreConfiguration;
    loginApiUrl?: string;
    loginUrl?: string;
    logoutUrl?: string;
}

export interface OsConfig {
    currentLang?:string;
    [propName: string]: any;
}

export interface LangLink {
    lang: string;
    url: string;
}

export interface MenuLink {
    de: Link;
    fr: Link;
    it: Link;
    en: Link;
    selected?: boolean;
    icon: string;
}

export interface Link {
    text: string;
    url?: string;
}

export interface SiteCoreConfiguration {
    de: Header;
    fr: Header;
    it: Header;
    en: Header;
}

export interface Header {
    header: {
        navMain: NavMainLink[];
        navMeta: NavMetaLink[];
    }
}

export interface NavLinkBase {
    text: string;
    url: string;
    isActive: boolean;
    isInternalUrl: boolean;
}

export interface NavMainLink extends NavLinkBase {
    flyout: any[];
    id: string;
    title: string;
}

export interface NavMetaLink extends NavLinkBase {
    isHomeLink: boolean;
    target: string;
}


