import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';

import {WrongLoginDialogComponent} from '../common/wrong-login-dialog/wrong-login-dialog.component';
import {AuthGuard} from './auth.guard';
import {AskToLinkDialogComponent} from './components/ask-to-link-dialog/ask-to-link-dialog.component';
import {CustomerCareLoginComponent} from './containers/login/customer-care/customer-care-login.component';
import {CustomerLoginComponent} from './containers/login/customer/customer-login.component';
import {UserService} from './user.service';
import {SetNewPasswordComponent} from "./containers/reset-password/set-new-password/set-new-password.component";
import {MyAccountGuard} from "./my-account.guard";
import {ForgotPasswordComponent} from "./containers/forgot-password/forgot-password.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {ResetPasswordByTokenComponent} from "./containers/reset-password/reset-password-by-token/reset-password-by-token.component";
import {CustomerModule} from "../customer/customer.module";
import {CommonDirectivesModule} from "../common/directives/common-directives.module";
import {CustomerLinkComponent} from "./containers/login/customer/customer-link.component";
import {LoginComponent} from "./containers/login/login.component";

@NgModule({
    declarations: [
        CustomerLoginComponent,
        CustomerLinkComponent,
        CustomerCareLoginComponent,
        WrongLoginDialogComponent,
        AskToLinkDialogComponent,
        SetNewPasswordComponent,
        ForgotPasswordComponent,
        ResetPasswordByTokenComponent,
        LoginComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        NgbModule,
        CustomerModule,
        CommonDirectivesModule
    ],
    exports: [
        SetNewPasswordComponent
    ],
    providers: [
        AuthGuard,
        UserService,
        MyAccountGuard
    ]
})
export class SecurityModule {
}
