import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {LoadingSpinnerDialogComponent} from './loading-spinner-dialog.component';

@Injectable()
export class LoadingSpinnerDialogService {
    private loadingModal: NgbModalRef = null;

    constructor(private modalService: NgbModal) {
    }

    public show() {
        /* Use setTimeout to fix for NgbModal bug causing ExpressionChangedAfterItHasBeenCheckedError.
         * See https://github.com/ng-bootstrap/ng-bootstrap/issues/1604
         * And https://github.com/angular/angular/issues/15634
         */
        setTimeout(() => {
            if (this.loadingModal) {
                return;
            }
            this.loadingModal = this.modalService.open(LoadingSpinnerDialogComponent, {
                size: 'lg',
                windowClass: 'swisspost-loading-modal',
                backdrop: 'static',
                keyboard: false
            });
        });
    }

    public hide() {
        /* Use setTimeout to fix for NgbModal bug causing ExpressionChangedAfterItHasBeenCheckedError.
         * See https://github.com/ng-bootstrap/ng-bootstrap/issues/1604
         * And https://github.com/angular/angular/issues/15634
         */
        setTimeout(() => {
            if (!this.loadingModal) {
                return;
            }
            this.loadingModal.close();
            this.loadingModal = null;
        });
    }
}
