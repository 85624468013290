import {Component, Input} from "@angular/core";
import {CustomerLanguage} from "../../models/customer.interface";
import {FormGroup} from "@angular/forms";

@Component({
    selector: 'evv-contact-info',
    templateUrl: 'contact-info.component.html'
})
export class ContactInfoComponent {

    languages: any;

    @Input()
    parentForm: FormGroup;
    /* eslint-disable */
    constructor() {
        this.languages = Object.keys(CustomerLanguage).map(k => CustomerLanguage[k as any] as CustomerLanguage);
    }
}

