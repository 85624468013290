/*
 * ------------------------------------------------------------------------------------------------
 * Copyright 2014 by Swiss Post, Information Technology Services
 * ------------------------------------------------------------------------------------------------
 */

import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

import {Observable, Subscription} from "rxjs";
import {map, switchMap} from "rxjs/operators";

import {CLIENT_CONFIG} from "../../../common/constants";
import {ClientConfig} from "../../../common/client-config.model";
import {UserService} from "../../user.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
/* eslint-disable */
export class LoginComponent implements OnInit, OnDestroy {
    targetUrl$: Observable<string>;
    private loggedInSubscription: Subscription;
    klpLoginRequired: boolean;
    lang = 'de';

    constructor(private userService: UserService,
                private router: Router,
                private route: ActivatedRoute,
                @Inject(CLIENT_CONFIG) public clientConfig: ClientConfig) {
        this.klpLoginRequired = clientConfig.klpLoginRequired;
    }

    ngOnInit() {
        this.targetUrl$ = this.route.queryParams.pipe(
                map(params => params['targetUrl'])
            );

        this.loggedInSubscription = this.userService.isLoggedIn$().pipe(
            switchMap(loggedIn => this.targetUrl$.pipe(map(targetUrl => ({loggedIn, targetUrl}))))
        ).subscribe(({loggedIn, targetUrl}) =>{
            if(loggedIn) {
                // the user is already logged in
                if (!targetUrl) {
                    if (this.userService.isUserCustomer()) {
                        targetUrl = '/ui/my-evd';
                    } else if (this.userService.isUserCustomerCare()) {
                        targetUrl = '/ui/cca';
                    } else if (this.userService.isUserKlp()) {
                        targetUrl = '/ui/my-evd/accounts/link-account';
                    } else {
                        targetUrl = '/';
                    }
                }
                this.router.navigate([targetUrl]);
            }
        });
        this.lang = sessionStorage.getItem('evv.app.language') == null  ? 'de' : sessionStorage.getItem('evv.app.language');
    }

    ngOnDestroy(): void {
        if(this.loggedInSubscription) {
            this.loggedInSubscription.unsubscribe();
        }
    }

    get loginUrl(): string {
        return this.userService.loginUrl;
    }

    get evvLoginUrl() {
        return this.getUrl(this.clientConfig.evvLoginUrl, this.lang).toString();
    }

    get klpEvdLoginUrl() {
        return this.getUrl(this.clientConfig.klpEvdLoginUrl, this.lang).toString();
    }

    getUrl(baseUrl: string, lang: string): URL {
        const url = new URL(baseUrl);
        url.searchParams.set('lang', lang);
        return url;
    }
}
